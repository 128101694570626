<template>
  <div class="browser-box">
    <div class="info-box">
      <div class="top-box">
        <div class="location-box" @click="addressModal=true">
          <i class="iconfont icon-ditu1"></i>
          <div style="cursor: pointer">
            <i class="el-icon-loading" style="margin-left: 12px;" v-if="getLocatLoading"></i>
            <span v-else>{{ searchForm.addressName?searchForm.addressName:'Choose Address' }}</span>
          </div>
        </div>
        <div class="chose-bar">
          <div
            :class="['type-box', { 'active-type': searchForm.type == ind+1 }]"
            v-for="(item, ind) in typeList"
            :key="item"
            @click="searchMerchant(ind)"
          >
            <i :class="['iconfont', iconList[ind], { font26: ind == 2 }]"></i>
            <span>{{ item }}</span>
          </div>
          <div :class="['input-box', {'focus-input':(focusInput || keywords) }]" style="background: #fff;">
            <input
              type="text"
              v-model="keywords"
              @focus="focusInput=true" @blur="delayBlur"
              @input="inputNow"
              @keyup="inputNow"
              :placeholder="$isUk?'Enter your postcode':'Enter your irecode'"
              style="height: 100%; width: 100%;font-size: 16px;"
            />

            <!-- 搜索结果内容 -->
            <div :class="['expand-box', { 'expand-box-active': focusInput }]">
              <div
                class="search-item"
                v-for="(item, ind) in resultList"
                :key="ind"
                @click="normalSearch(item)"
              >
                <span>{{ item.content }}</span>
                <i class="el-icon-loading" v-if="itemLoading == item.place_id && item.type=='location'"></i>
                <img v-else src="@/assets/images/right.png" alt="right-arrow" />
              </div>
              <div class="load-box" v-if="searchLoading && keywords.length > 2">
                <span class="loader"></span>
              </div>
              <div class="load-box" v-else-if="!searchLoading && !resultList.length">
                No data.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middle-box">
        <template>
            <div class="cuise-bar">
              <swiper :options="swiperOption" ref="mySwiper" id="cuise-swiper">
                <swiper-slide class="cuise-item" v-for="(item) in foodTypeList" :key="item.cuisineId">
                  <div class="img" @click="checkCuise(item)">
                    <img v-if="item.featuredImage" :src="item.featuredImage" alt="cuiseImg">
                    <img v-else src="https://nweb.alleatapp.com/food/img/20240606/619514E52ACD62802D5E25D496608600.png" alt="cuiseImg">
                  </div>
                  <!-- <div class="name" @click="checkCuise(item)" :style="{ backgroundColor: colorList[ind%5]}">{{ item.cuisineName }}</div> -->
                  <div class="name" @click="checkCuise(item)" >{{ item.cuisineName }}</div>
                </swiper-slide>
                <!-- 可选的控件 -->

                <!--前进后退按钮-->
                <div class="swiper-button-prev" style="margin-left: 0px;" slot="button-prev"></div>
                <div class="swiper-button-next" style="margin-right: 0px;" slot="button-next"></div>
              </swiper>
            </div>
          </template> 
      </div>
      <div class="bottom-box">
      <section class="left-box">
        <div class="sort" >
          <BrowseSortBox ref="BrowseSortBox" @optionChange="optionChange" ></BrowseSortBox>
        </div>
      </section>

      <section class="right-box">
        <div class="chose-bar">
          <div
            :class="['type-box', { 'active-type': searchForm.type == ind+1 }]"
            v-for="(item, ind) in typeList"
            :key="item"
            @click="searchMerchant(ind)"
          >
            <i :class="['iconfont', iconList[ind], { font26: ind == 2 }]"></i>
            <span>{{ item }}</span>
          </div>
        </div>

        <!-- 只有移动设备展示的内容 -->
        <div class="mobile-location-box">
          <div :class="['location-box', {'location-box-mini': focusInput || keywords}]" @click="addressModal=true">
            <i class="iconfont icon-ditu1"></i>
            <div style="cursor: pointer">
              <i class="el-icon-loading" style="margin-left: 12px;" v-if="getLocatLoading"></i>
              <span v-else>{{ searchForm.addressName?searchForm.addressName:'Choose Address' }}</span>
            </div>
          </div>

          <div :class="['input-box', {'focus-input':(focusInput || keywords) }]">
            <input
              type="text"
              v-model="keywords"
              @focus="focusInput=true" @blur="delayBlur"
              @input="inputNow"
              @keyup="inputNow"
              :placeholder="$isUk?'Enter your postcode':'Enter your irecode'"
              style="height: 100%; width: 100%;font-size: 16px;"
            />

            <!-- 搜索结果内容 -->
            <div :class="['expand-box', { 'expand-box-active': focusInput }]">
              <div
                class="search-item"
                v-for="(item, ind) in resultList"
                :key="ind"
                @click="normalSearch(item)"
              >
                <span>{{ item.content }}</span>
                <i class="el-icon-loading" v-if="itemLoading == item.place_id && item.type=='location'"></i>
                <img v-else src="@/assets/images/right.png" alt="right-arrow" />
              </div>
              <div class="load-box" v-if="searchLoading && keywords.length > 2">
                <span class="loader"></span>
              </div>
              <div class="load-box" v-else-if="!searchLoading && !resultList.length">
                No data.
              </div>
            </div>
          </div>

          <div class="search-btn" v-if="focusInput || searchForm.keyword || keywords">
            <button @click="getNearMerchant(true,'3')">Search</button>
          </div>
        </div>

        <div class="middle-box mobile-middle-box">
          <template>
              <div class="cuise-bar">
                <swiper :options="swiperOption" ref="mySwiper" id="cuise-swiper">
                  <swiper-slide class="cuise-item" v-for="(item) in foodTypeList" :key="item.cuisineId">
                    <div class="img" @click="checkCuise(item)">
                      <img v-if="item.featuredImage" :src="item.featuredImage" alt="cuiseImg">
                    <img v-else src="https://nweb.alleatapp.com/food/img/20240606/619514E52ACD62802D5E25D496608600.png" alt="cuiseImg">
                    </div>
                    <!-- <div class="name" @click="checkCuise(item)" :style="{ backgroundColor: colorList[ind%5]}">{{ item.cuisineName }}</div> -->
                    <div class="name" @click="checkCuise(item)" >{{ item.cuisineName }}</div>
                  </swiper-slide>
                  <!-- 可选的控件 -->
                  <!--前进后退按钮-->
                  <div class="swiper-button-prev" style="margin-left: 0px;" slot="button-prev"></div>
                  <div class="swiper-button-next" style="margin-right: 0px;" slot="button-next"></div>
                </swiper>
              </div>
            </template> 
        </div>



        <TagBar v-model="tagList" @clearTag="clearTag"></TagBar>

        <el-skeleton :loading="loading" animated>
          <!-- 移动端展示的内容 -->
          <template slot="template">
            <el-skeleton-item variant="rect" class="cuise-bar" />
          </template>
          <template>
            <div class="banner-box">
                <el-carousel height="170px" arrow="never">
                    <el-carousel-item v-for="item in bannerList" :key="item.id" >
                        <img :src="item.url" style="height: 100%;width: 100%;" @click="goShop(item)" :alt="item.urlIndex">
                    </el-carousel-item>
                </el-carousel>
            </div>
          </template>
          <template slot="template">
            <el-skeleton-item variant="rect" class="cuise-bar" />
          </template>
           <!-- <template>
            <div class="cuise-bar">
              <swiper :options="swiperOption" ref="mySwiper" id="cuise-swiper">
                <swiper-slide class="cuise-item" v-for="(item) in foodTypeList" :key="item.cuisineId">
                  <div class="img" @click="checkCuise(item)">
                    <img :src="item.featuredImage" alt="cuiseImg">
                  </div>-->
                  <!--这句是不要的 <div class="name" @click="checkCuise(item)" :style="{ backgroundColor: colorList[ind%5]}">{{ item.cuisineName }}</div> -->
                 <!-- <div class="name" @click="checkCuise(item)" >{{ item.cuisineName }}</div>
                </swiper-slide>-->
                <!-- 可选的控件 -->

                <!--前进后退按钮-->
                <!--<div class="swiper-button-prev" style="margin-left: 0px;" slot="button-prev"></div>
                <div class="swiper-button-next" style="margin-right: 0px;" slot="button-next"></div>
              </swiper>
            </div>
          
          </template>  -->
        </el-skeleton>
        <el-skeleton :loading="loading" animated >
            <template slot="template">
              <el-skeleton-item variant="rect" class="merchant-box" />
            </template>
            <template>
              <div class="merchant-box">
                <BrowseMerchantBox :merchantTotal="total" :showOffMerchant="!searchForm.keyword" :offmerchantList="offmerchantList" :merchantList="merchantList"></BrowseMerchantBox>   
                <div v-if="loadMore" class="loadmore-box">
                  <i class="el-icon-loading"></i>
                </div>
              </div>
            </template>
          </el-skeleton>
      </section>
      </div>
      <!-- 回到顶部按钮 -->
      <el-backtop :bottom='128' :right='60'>
        <i class="el-icon-caret-top"></i>
      </el-backtop>
      <!-- 选择地址弹窗 -->
      <ChooseAddressModalVue v-model="addressModal" @getAddress="getAddress"></ChooseAddressModalVue>
    </div>
  </div>
</template>

<script>
import BrowseSortBox from '@/components/BrowseComps/BrowseSortBox.vue';
import TagBar from '@/components/BrowseComps/TagBar.vue';
import BrowseMerchantBox from '@/components/BrowseComps/BrowseMerchantBox.vue';
import { postGateway } from '@/request/index.js';
import ChooseAddressModalVue from '@/components/BrowseComps/ChooseAddressModal.vue';
import debounce from '@/utils/debounce';
export default {
  name: "Browse",
  components: {
    BrowseSortBox,
    TagBar,
    BrowseMerchantBox,
    ChooseAddressModalVue
  },
  data() {
    return {
      focusInput: false,
      typeList: ["Delivery", "Pick-up", "Dine-in"],
      iconList: ["icon-waimai", "icon-01_zitidian", "icon-tangshi"],
      tagList: [{
        key: 'sort',
        name: 'Recommended'
      }],
      foodTypeList: [],
      colorList: ['#FF0000', '#FF5C00', '#00588A', '#0030AA', '#005125'],
      loading: true,
      addressModal: false,
      searchForm: {
        addressName: '',
        keyword: '', 
        sort: '', 
        tastRating: '', 
        serviceRating: '', 
        type: '', // 默认没有选择类型
        discount: '',
        lng: '',
        lat: '',
      },
      // searchInput 相关
      keywords: '',
      resultList: [],
      itemLoading: false,
      searchLoading: false,
      pages: {
        pageNum: 1,
        pageSize: 12
      },
      total: 0,
      merchantList: [],
      offmerchantList: [],
      loadMore: false,
      bannerList: [], // 轮播图数据
      swiperOption: {
        //幻灯片放映方向
        direction: "horizontal", //通常不与左右按钮一同使用
        slidesPerView: 3,
        spaceBetween: 15,
        //分页器配置项
        pagination: {
            el: ".swiper-pagination", //分页器的类名
            clickable: true, // 点击分页器跳切换到相应的幻灯片
            bottom: 0,
        },
        //前进后退按钮
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        //幻灯片播放配置项
        loop: false, //是否循环播放
        speed: 1000, // 发生页面切换动画时，动画的切换速度
        autoPlay: false,
        breakpoints: {
            1024: {
                slidesPerView: 7,
                spaceBetween: 15,
            },
            950: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
        },
        on: {
            slideChangeTransitionEnd: function () {
                // console.log(this.activeIndex); //每次切换结束时，在控制台打印现在是第几个slide
            },
        },
      },
      getLocatLoading: true, // 加载定位loading
    };
  },
  created() {
    if(this.$route.query.cuisineId) {
      this.tagList = [{
        key: 'sort',
        name: 'Recommended'
      }]
      this.tagList.push({
        name: this.$route.query.name,
        key: 'cuisineId-' + this.$route.query.cuisineId
      })
      this.nowCuiseId = this.$route.cuisineId;
      this.reloadPage();
    }
    this.reloadPage();

    // 如果没有搜索过地址就弹窗提示
    setTimeout(() => {
      let obj = localStorage.getItem('searchAddress');
      try {
        let isSsgRender = this.$route.query.preRender == 1
        console.log(window.injectProperty,'window.injectProperty')
        if(obj && !isSsgRender) {
          obj = JSON.parse(obj);
          setTimeout(()=>{
            this.addressModal = (obj.postcode == '' && obj.street=='');
          }, 500)
        } else if(!obj && !isSsgRender) {
          this.addressModal = true;
        }
      } catch (error) {
        console.log(error,'error')
      }
    }, 1000);

  },
  mounted() {
    this.spideWindowScroll();
    this.getHomeImgList();
  },
  watch: {
    '$route.query.searchName': function(val) {
      if(this.searchForm.keyword !== val && this.$route.path == '/browse' && !this.searchForm.keyword) {
        this.reloadPage();
      }
    },
    '$route.query.cuisineId': function(val) {
      console.log(val,'cuisineid 执行了这里')
      if(this.$route.query.cuisineId && this.$route.path == '/browse' && !this.searchForm.keyword) {
        this.tagList = [{
          key: 'sort',
          name: 'Recommended'
        }]
        this.tagList.push({
          name: this.$route.query.name,
          key: 'cuisineId-' + this.$route.query.cuisineId
        })
        this.nowCuiseId = this.$route.cuisineId;
        this.reloadPage();
      }
    },
    '$route.query.searchLoc': function(val) {
      if(this.searchForm.addressName !== val && this.$route.path == '/browse') {
        this.reloadPage();
      }
    },
  },
  methods: {
    // 延迟 blur 处理click 事件
    delayBlur() {
      setTimeout(() => {
        this.focusInput = false;
      }, 500);
    },
    // 监听输入框
    inputNow() {
      this.resultList = [];
      this.searchLoading = true;
      debounce(() => {
        if (this.keywords.trim().length < 3) return;
        postGateway({
          method: "GET",
          url: "/customerApp/index/getPreKeyword",
          data: { keyword: this.keywords },
        })
          .then((res) => {
            if (res.data.length) {
              this.resultList = res.data.map((x) => {
                return {
                  content: x,
                  type: "content"
                };
              });
              if(this.resultList.length) {
                this.searchLoading = false;
              }
            } else {
              this.googlePreSearch();
            }
          })
          .catch(() => {
            this.googlePreSearch();
          });
      }, 500);
    },
    googlePreSearch() {
      this.searchLoading = true;
      if(this.$isUk) {
				postGateway({
					url: `/customerApp/addressioSearch/autocomplete`,
					method: 'GET',
					data: {
						keyword: this.keywords
					}
				}).then(res=>{
					console.log(res.data,'搜索地址会餐')
					this.searchLoading = false;
					this.resultList = res.data.suggestions.map(x=>({
            ...x,
            content: x.description || x.address,
            place_id: x.id,
            type: "location",
          }));
				})
				.catch(err=>{
					this.searchLoading = false;
					console.log(err,'搜索地址报错')
				})
				return
			}
      postGateway({
        method: "GET",
        url: "/customerWeb/googleSearch/autocomplete",
        data: { keyword: this.keywords },
      })
        .then((res) => {
          this.resultList = this.resultList.concat(
            res.data.predictions.map((x) => {
              return {
                ...x,
                content: x.description,
                type: "location",
              };
            })
          );
          this.searchLoading = false;
        })
        .catch(() => {
          this.searchLoading = false;
        });
    },
    normalSearch(item) {
      if (item.type == "content") {
        this.$router.push({
          path: "/browse",
          query: {
            searchName: item.content,
            lng: "",
            lat: "",
            searchLoc: "",
          },
        });
      } else if (item.type == "location") {
        this.googleSearch(item);
      }
    },
    googleSearch(item) {
      this.itemLoading = item.place_id;

      if(this.$isUk) {
					postGateway({
						url: `/customerApp/addressioSearch/get`,
						method: 'GET',
						data: {
							id: item.id
						}
					}).then(res=>{
						let { town_or_city, longitude, latitude  } = res.data;
						this.$router.push({
              path: "/browse",
              query: {
                searchName: "",
                lng: longitude,
                lat: latitude,
                searchLoc: town_or_city,
                transType: this.transType,
              },
            });
            this.searchVisible = false;
					})
					.catch(()=>{
            this.searchVisible = false;
					})
					return
				}

      postGateway({
        method: "GET",
        url: "/customerWeb/googleSearch/placeGeoCode",
        data: {
          keyword: "",
          placeId: item.place_id,
        },
      }).then((res) => {
        this.itemLoading = '';
        if (res.code == 200) {
          this.$router.push({
            path: "/browse",
            query: {
              searchName: "",
              lng: res.data.lng,
              lat: res.data.lat,
              searchLoc: res.data.formattedAddress,
              transType: this.transType,
            },
          });
          this.searchVisible = false;
        } else {
          this.$message.error("Error happen...");
        }
      }).catch(err=>{
        this.itemLoading = '';
        console.log(err,'搜索报错')
      })
    },
    reloadPage() {
      debounce(()=>{
        this.searchForm.keyword = this.$route.query.keyword || this.$route.query.searchName || ''
        this.keyword = this.searchForm.keyword;

        if(this.$route.query.transType) {
          this.searchForm.type = this.$route.query.transType;
        }
        this.getFoodTypeList();

        if(this.$route.query.searchLoc) {  // 搜索地址携带的地址参数就不用浏览器定位了
          this.searchForm.lng = this.$route.query.lng; 
          this.searchForm.lat = this.$route.query.lat; 
          this.searchForm.addressName = this.$route.query.searchLoc;
          localStorage.setItem('searchAddress',JSON.stringify({
            city: '',
            postcode: '',
            lat: this.searchForm.lat,
            lng: this.searchForm.lng,
            street: this.searchForm.addressName,
          }))
          this.getLocatLoading = false;
          this.getNearMerchant(true,'4'); // 获取商家列表
        } else if(localStorage.getItem('searchAddress')) {
          let obj = JSON.parse(localStorage.getItem('searchAddress'));
          let { lat, lng, street } = obj;
          this.searchForm.lng = lng; 
          this.searchForm.lat = lat; 
          this.searchForm.addressName = street;
          this.getLocatLoading = false;
          this.getNearMerchant(true,'4'); // 获取商家列表
        } else {
          this.getLocation(); // 获取浏览器定位信息
        }
      }, 300)
    },
    goShop(item) {
			if(this.judgeUrl(item.urlIndex)) {  // 站内
				let param = item.urlIndex.split('/#/')[1]
				this.$router.push({
					url: `/${param}`
				})
			} else {
        window.open(item.urlIndex)
			}
		},
    judgeUrl(url) {
			return url.indexOf('nweb.alleatapp.com') != -1
		},
    getHomeImgList() {
      postGateway({
          url: '/merchant/rotation/chart/findList',
          data: {},
          method: 'GET'
      }).then(res=>{
        this.bannerList = res.data;
        console.log(res, '获取轮播图参数')
      })
      .catch(err=>{
          console.log(err, '获取轮播图参数报错')
      })
    },
    checkCuise(item) {
      let { cuisineId, cuisineName } = item;
      this.tagList.push({
        name: cuisineName,
        key: 'cuisineId-' + cuisineId
      })
      this.nowCuiseId = cuisineId;
      this.getNearMerchant(true,'5'); // 获取商家列表
    },
    goCate(item) {
      console.log(item,'item')
      let { cuisineId, cuisineName } = item;
      this.$router.push({
          path: '/cuisine',
          query: {
          cuisineId: cuisineId,
          cuisineName: cuisineName
          }
      })
    },
    // 用户手动搜索地址
    getAddress(obj) {
      this.searchForm.lat = obj.lat;
      this.searchForm.lng = obj.lng;
      // 存入当前位置用于后续查找  不删
			localStorage.setItem('searchAddress',JSON.stringify({
				city: obj.city,
				postcode: obj.zipcode,
				lat: this.searchForm.lat,
				lng: this.searchForm.lng,
				street: obj.street,
			}))
			// 展示街区
			this.searchForm.addressName = obj.street;
      this.addressModal = false;
      this.getNearMerchant(true,'6'); // 获取商家列表
    },
    // 获取浏览器定位信息
    getLocation() {
      this.getLocatLoading = true;
      if(navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position)=>{
          let { latitude, longitude } = position.coords
          this.searchForm.lng = longitude;
          this.searchForm.lat = latitude;
          this.searchLocationByGoogle();
          this.getNearMerchant(true,'7'); // 获取商家列表
          console.log(position, 'position');
        }, ()=>{ 
          console.log('获取地址失败')
          this.getNearMerchant(true,'7')
          this.getLocatLoading = false;
          // alert("Geolocation Failed"); 
        }); 
      } else {
        this.getNearMerchant(true,'7')
      }
    },
    searchLocationByGoogle() { // 通过经纬度查询地址
			postGateway({
        url: '/customerApp/googleSearch/geoGetCity',
        method: 'GET',
        data: {
          lat: this.searchForm.lat,
          lng: this.searchForm.lng
        }
      }).then(res=>{
				// 存入当前位置用于后续查找  不删
				localStorage.setItem('searchAddress',JSON.stringify({
					city: res.data.city,
					postcode: res.data.postCode,
					lat: this.searchForm.lat,
					lng: this.searchForm.lng,
					street: res.data.street,
				}))
				// 展示街区
				this.searchForm.addressName = res.data.street;
				this.getLocatLoading = false;
			}).catch(err=>{
        console.log(err,'查询报错')
				this.getLocatLoading = false;
			})
    },
    // 查询条件变化
    optionChange({ sort,tastRating,type,discount, tagList }) {
      let obj = { sort,tastRating,type,discount };
      this.tagList = tagList;
      for (const key in obj) {
        this.searchForm[key] = obj[key]
      }
      this.getNearMerchant(true,'8'); // 附近商家
    },
    searchMerchant(ind) {
      this.searchForm.type = ind+1;
      this.getNearMerchant(true,'9'); // 附近商家
    },
    // 监听页面滚动
    spideWindowScroll() {
      const that = this
      window.onscroll = function () {
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
        var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
        // console.log(scrollTop + windowHeight, scrollHeight-1000, '页面问题处理', that.pages.pageSize*that.pages.pageNum , that.total)
          if (scrollTop + windowHeight >= scrollHeight-1000 && !that.loadMore) {
            
            if (that.pages.pageSize*that.pages.pageNum < that.total) {
                console.log('加载更多')
                that.loadMore = true
                that.pages.pageNum ++
                that.getNearMerchant(false, '10')
            }
            // 数据全部加载完成
          }
      }
    },
    // 有优惠的商家
    getOffmerchant() {
      postGateway({
          url: '/customerApp/index/queryMerchantOffIndex',
          method: 'GET',
          data: {
            selfLontitude: this.searchForm.lng,
            selfLatitude: this.searchForm.lat,
            type: this.searchForm.type // 类型 
          }
      }).then((res) => {
          if(res.code == 200){
            this.offmerchantList = [];
            this.offmerchantList = res.data.filter(x=>x.merchantOff)
          }
      })
    },
    // 获取菜色分类列表
    getFoodTypeList() {
      postGateway({
        url: '/customerWeb/index/popularCategory',
        method: 'GET',
        data: {}
      }).then(res=>{
        this.foodTypeList = res.data
      })
      .catch(()=>{

      })
    },
    getNearMerchant(refresh=false, val){
      console.log(val,'执行的val')
      const { keyword, sort, tastRating, serviceRating, discount, lat, lng } = this.searchForm;
      if(refresh) {
        this.loading = true;
        this.pages.pageNum = 1;
        this.getOffmerchant();
      }
      postGateway({
        url: '/customerApp/index/queryMerchantByPage',
        method: 'GET',
        data: {
          keyword, sort, tastRating, serviceRating, discount,
          pageNum: this.pages.pageNum,
          pageSize: this.pages.pageSize,
          cuisineId: this.nowCuiseId || "",
          type: this.searchForm.type,
          selfLontitude: lng,
          selfLatitude: lat
        }
      }).then((res) => {
          this.loadMore = false;
          if(refresh) {
            this.merchantList = [];
          }
          if(res.code == 200){
            this.merchantList = this.merchantList.concat(res.data.list)
            this.total = res.data.total
          }
          setTimeout(() => {
            this.loading = false;
          }, 1000);
      }).catch(err => {
        this.loading = false;
        this.loadMore = false;
        this.$message.error(err)
      })
    },
    // 清除tag
    clearTag(key) {
      this.$refs.BrowseSortBox.form[key] = key=='sort'?0:'';
      this.getNearMerchant(true,'1');
    }
  }
};
</script>

<style scoped lang="scss">
$mainColor: #aa0bc0;
$greenColor: #26d07c;
$yellowColor: #ffa132;
.browser-box {
  width:100%;
  background: #f5f5f5;
  .info-box {
    display: flex;
    width: 1600px;
    position: relative;
    padding-top: 10px;
    margin: 0 auto;
    font-family: "Source Sans Pro", sans-serif;
    background: #f5f5f5;
    flex-direction:column;
    .top-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 45px;
      padding: 0px 50px;
      .location-box {
        min-height: 48px;
        // border-radius: 4px;
        border-radius: 30px;
        background-color: #ffffff;
        // background-color: #eeeeee;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 30px 0 30px;
        font-size: 18px;
        font-weight: 600;
        color: #000;
        font-family: Poppins, sans-serif;
        cursor: pointer;

        .icon-ditu1 {
          font-size: 28px;
          margin-right: 10px;
          color: $greenColor;
        }
      }
       .chose-bar {
        // padding-top: 20px;
        display: flex;
        align-items: center;
        margin-left: 50px;
        flex: 1;

        .type-box {
          display: inline-flex;
          height: 48px;
          width: 135px;
          margin-right: 15px;
          background-color: #e9eaea;
          align-items: center;
          justify-content: center;
          // border-radius: 4px;
          border-radius: 30px;
          cursor: pointer;
          font-size: 18px;
          font-weight: 600;
          transition: all ease-in-out .2s;
          font-family: "Source Sans Pro", sans-serif;
          &:hover {
              background: #bfc5c5;
          }

          i {
            color: #000000;
            margin-right: 8px;
            font-size: 26px;
          }
          .font26 {
            font-size: 18px;
          }
        }

        .active-type {
          background: $greenColor;
          color: #fff;
          &:hover {
              background: #1daa63;
          }
          i {
            color: #fff;
          }
        }

        .search-btn {
          display: none;
        }
        // .input-box {
        //   flex: 1;
        //   // border-radius: 4px;
        //   border-radius: 30px;
        //   overflow: hidden;
        //   // background-color: fff;
        //   background-color: #ffffff;
        //   display: flex;
        //   align-items: center;
        //   padding-left: 20px;
        //   // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        //   border: 1px solid #eeeeee;
        //   transition: all ease-in .2s;
        //   margin-left: 30px;
        //   .el-icon-search {
        //     font-size: 26px;
        //     font-weight: 700;
        //     color: #AA0BC0;
        //   }

        //   input {
        //     height: 100%;
        //     width: 100%;
        //     background-color: #fff;
        //     font-family: "Source Sans Pro", sans-serif;
        //     font-size: 18px;
        //     font-weight: 400;
        //     flex: 1;
        //     color: #000;
        //     fill: #000;
        //     padding-left: 20px;
        //   }
        // }

        .input-box {
          height: 60px;
          padding: 0 20px;
          flex: 1;
          display: inline-flex;
          align-items: center;
          position: relative;
          border-radius: 30px;
          .expand-box {
            position: absolute;
            z-index: 10;
            width: 100%;
            height: 0px;
            top: 70px;
            left: 0;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.95);
            padding: 20px 0;
            height: 0px;
            opacity: 0;
            transition: all 0.8s ease-in-out 0s;
            display: none;

            .search-item {
              height: 50px;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #e9e9e9;
              padding: 0 15px;
              font-size: 12px;
              cursor: pointer;

              &:hover {
                background-color: rgba(236, 230, 230, 0.95);
              }

              span {
                flex: 1;
                font-size: 15px;
              }
              .el-icon-loading {
                margin-right: 15px;
              }

              img {
                height: 18px;
                width: 25px;
              }
            }
          }

          .load-box {
            height: 100%;
            width: 100%;
            display: flex;
            // align-items: center;
            padding-top: 6px;
            justify-content: center;
            font-size: 20px;
            .loader {
              width: 48px;
              height: 48px;
              border: 5px solid #fff;
              border-bottom-color: #ff3d00;
              border-radius: 50%;
              display: inline-block;
              box-sizing: border-box;
              animation: rotation 1s linear infinite;
            }

            @keyframes rotation {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }

          .expand-box-active {
            height: 300px;
            opacity: 1;
            display: block;
            overflow-y: scroll;
          }

          input {
            background: transparent;
            font-weight: normal;
          }

          img {
            height: 20px;
            width: 20px;
            margin-right: 10px;
          }
        }

        .focus-input {
          box-shadow: rgba(0, 0, 0, 0.295) 0px 6px 12px, rgba(0, 0, 0, 0.316) 0px 3px 6px;
        }
      }
    }

    .middle-box {
      margin-top: 30px;
      :deep(.cuise-bar) {
        min-height: 108px;
        width: 100%;
        margin: 20px 0 10px 0;
        display: flex;
        flex-wrap: nowrap;
        box-shadow: unset;
        min-height: 160px;
        #cuise-swiper {
          // padding: 2px;
          padding: 15px;
          margin: 0;
          width: 100%;
          background-color: #ffffff;
          // display: flex;
          // flex-direction: row;
          // align-items: center;
        }
        .swiper-container{
         --swiper-navigation-size:20px;
        }
        .swiper-button-prev, .swiper-button-next {
          color:$mainColor;
        }
      }
      .cuise-item {
          display: inline-flex;
          margin-right: 20px;
          margin-right: 0px;
          flex-direction: column;
          // height: 108px;
          width: 108px;
          max-width: 108px;
          min-width: 108px;
          margin-left: 33px;
          // border-radius: 12px;
          background: #fff;
          // box-shadow: 0px 0px 5px #BBBBBB;
          font-family: "Source Sans Pro", sans-serif;
          overflow: hidden;
          cursor: pointer;

          &:nth-child(1) {
            // margin-left: 2px;
            margin-left: 30px;
          }
          .img {
            flex: 1;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              height: 100px;
              width: 103px;
            }
          }
          .name {
            text-align: center;
            // color: #000;
            font-weight: 600;
            height: 30px;
            display: flex;
            // font-size: 14px;
            font-size: 18px;
            justify-content: center;
            align-items: center;
            width: 100%;
            // background: #FF0000;
            color:#aa0bc0;
          }
        }
    }
    .mobile-middle-box {
      display: none;
    }
    .bottom-box {
      display: flex;
      flex-direction: row;
    
    .left-box {
      padding: 20px 20px 0 0;
      position: sticky;
      top: 0px;
      width: 370px;
      min-height: 900px;
      min-width: 350px;
      height: calc(100vh - 96px);
      display: flex;
      flex-direction: column;

      // .location-box {
      //   min-height: 48px;
      //   // border-radius: 4px;
      //   border-radius: 30px;
      //   background-color: #ffffff;
      //   // background-color: #eeeeee;
      //   display: flex;
      //   align-items: center;
      //   justify-content: flex-start;
      //   padding: 0 15px 0 15px;
      //   font-size: 18px;
      //   font-weight: 600;
      //   color: #000;
      //   font-family: Poppins, sans-serif;
      //   cursor: pointer;

      //   .icon-ditu1 {
      //     font-size: 28px;
      //     margin-right: 10px;
      //     color: $greenColor;
      //   }
      // }
      .sort {
        margin-top: 24px;
        flex: 1;
        :deep(.sort-box) {
          border-radius: 30px;
          background: #ffffff;
          box-shadow: unset;
        }
        :deep(.title-box) {
          border-top: 0px;
          border-bottom: 0px;
        }
        :deep(.btn) {
          border-radius: 30px;
          background-color: #f5f5f5;
          color: #09CA6A;
        }
      }
    }

    .right-box {
      margin-top: 24px;
      width: 82%;
      display: flex;
      min-width: 550px;
      flex-direction: column;

      .chose-bar {
        padding-top: 20px;
        display: none;

        .type-box {
          display: inline-flex;
          height: 48px;
          width: 135px;
          margin-right: 15px;
          background-color: #e9eaea;
          align-items: center;
          justify-content: center;
          // border-radius: 4px;
          border-radius: 30px;
          cursor: pointer;
          font-size: 18px;
          font-weight: 600;
          transition: all ease-in-out .2s;
          font-family: "Source Sans Pro", sans-serif;
          &:hover {
              background: #bfc5c5;
          }

          i {
            color: #000000;
            margin-right: 8px;
            font-size: 26px;
          }
          .font26 {
            font-size: 18px;
          }
        }

        .active-type {
          background: $greenColor;
          color: #fff;
          &:hover {
              background: #1daa63;
          }
          i {
            color: #fff;
          }
        }

        .search-btn {
          display: none;
        }
        // .input-box {
        //   flex: 1;
        //   border-radius: 30px;
        //   overflow: hidden;
        //   background-color: #ffffff;
        //   display: flex;
        //   align-items: center;
        //   padding-left: 20px;
        //   border: 1px solid #eeeeee;
        //   transition: all ease-in .2s;

        //   .el-icon-search {
        //     font-size: 26px;
        //     font-weight: 700;
        //     color: #AA0BC0;
        //   }

        //   input {
        //     height: 100%;
        //     width: 100%;
        //     background-color: #fff;
        //     font-family: "Source Sans Pro", sans-serif;
        //     font-size: 18px;
        //     font-weight: 400;
        //     flex: 1;
        //     color: #000;
        //     fill: #000;
        //     padding-left: 20px;
        //   }
        // }

        .input-box {
          padding: 0 8px;
          height: 46px;
          img {
            // zoom: 1.4;
          }
          input {
            font-size: 18px;
            height: 46px;
          }
          .expand-box {
            padding-top: 0;
            height: 500px;
            background: #fff;
            z-index: 100;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
            width: 76vw;
            left: 0px;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
            .search-item {
              height: unset;
              padding: 10px 5px 10px 20px;
              font-size: 18px;
              img {
                height: unset;
                width: 13px;
                margin-left: 10px;
              }
              span {
                font-size: 12px;
              }
            }
          }
        }

        .focus-input {
          box-shadow: rgba(0, 0, 0, 0.295) 0px 6px 12px, rgba(0, 0, 0, 0.316) 0px 3px 6px;
        }
      }
      .mobile-location-box {
        display: none;
      }
      .tag-bar {
        height: 40px;
        margin-top: 20px;
        font-size: 18px;
        font-family: "Source Sans Pro", sans-serif;
        :deep(.tag-item) {
          border-radius: 30px;
          box-shadow: unset;
        }
      }
      :deep(.cuise-bar) {
        min-height: 108px;
        width: 100%;
        margin: 20px 0 10px 0;
        display: flex;
        flex-wrap: nowrap;
        box-shadow: unset;
        #cuise-swiper {
          padding: 2px;
          margin: 0;
          width: 100%;
        }

        // .cuise-item {
        //   display: inline-flex;
        //   margin-right: 20px;
        //   margin-right: 0px;
        //   flex-direction: column;
        //   height: 108px;
        //   width: 108px;
        //   max-width: 108px;
        //   min-width: 108px;
        //   // border-radius: 12px;
        //   background: #fff;
        //   margin-left: -15px;
        //   // box-shadow: 0px 0px 5px #BBBBBB;
        //   font-family: "Source Sans Pro", sans-serif;
        //   overflow: hidden;
        //   cursor: pointer;

        //   &:nth-child(1) {
        //     margin-left: 2px;
        //   }
        //   .img {
        //     flex: 1;
        //     width: 100%;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     img {
        //       height: 70px;
        //       width: 73px;
        //     }
        //   }
        //   .name {
        //     text-align: center;
        //     color: #000;
        //     font-weight: 600;
        //     height: 30px;
        //     display: flex;
        //     font-size: 14px;
        //     justify-content: center;
        //     align-items: center;
        //     width: 100%;
        //     // background: #FF0000;
        //   }
        // }

      }
    }
    }
    :deep(.merchant-box) {
      flex: 1;
      min-height: 800px;
      margin-top: 10px;
    }
    :deep(.merchant-item) {
      border-radius: 30px;
      box-shadow: unset;
    }
    :deep(.status-tag) {
      border-radius: 30px;
    }
    :deep(.banner-box){
      display: none;
    }
    .loadmore-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      .el-icon-loading {
        font-size: 18px;
        color: #aa0bc0;
        font-weight: bold;
        font-size: 36px;
      }
    }
  }

}

// 屏幕宽度把控 < 1600

@media screen  and (min-width: 1300px) and (  max-width: 1600px){
  .browser-box{
    .info-box {
      width: 1300px;
      // overflow-x: hidden;
      .bottom-box {
        .left-box {
          width: 300px;
          padding: 20px 20px 0 10px;
        }
      }
    }
  }
  
}

// 手机相关布局
@media screen and (max-width: 950px) {
  .middle-box {
    display: none;
    margin-top: 0!important;
  }
  .mobile-middle-box{
    display: block!important;
  }
  .main-content {
    width: 100vw;
    background: #f7f7f7;
  }
  .browser-box {
    .info-box {
      width: 100vw;
      flex-direction: column;
      .top-box {
        display:none;
      }
      .bottom-box {


      .left-box {
        display: none;
      }
      .right-box {
        width: 100vw;
        min-width: 100vw;
        margin-top: 0;
        .chose-bar {
          display: flex;
          justify-content: center;
          padding: 10px 15px 0;
          .input-box {
            display: none;
          }
          .type-box {
            height: 44px;
            // width: 118px;
            &:nth-child(3) {
              margin-right: 0px;
            }
            i {
              display: none;
            }
          }
          .active-type {
            i {
              display: block;
            }
          }
          
        }
        .mobile-location-box {
          display: flex;
          min-height: 50px;
          margin-top: 15px;
          padding: 0 15px;
          align-items: center;
          .location-box {
            margin-right: 8px;
            min-height: 48px;
            max-width: 200px;
            // border-radius: 4px;
            border-radius: 30px;
            background-color: #eeeeee;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 10px 0 10px;
            font-size: 13px;
            font-weight: 600;
            color: #000;
            font-family: Poppins, sans-serif;
            cursor: pointer;

            .icon-ditu1 {
              font-size: 24px;
              margin-right: 2px;
              color: $greenColor;
            }
          }
          .location-box-mini {
            width: 45px;
            span {
              display: none;
            }
          }

          // PC版本 css
          .input-box {
            height: 60px;
            background: #f5f5f5;
            padding: 0 20px;
            flex: 1;
            display: inline-flex;
            align-items: center;
            position: relative;
            border-radius: 30px;
            .expand-box {
              position: absolute;
              z-index: 10;
              width: 100%;
              height: 0px;
              top: 60px;
              left: 0;
              background: rgba(255, 255, 255, 0.95);
              border-radius: 10px;
              padding: 20px 0;
              height: 0px;
              opacity: 0;
              transition: all 0.8s ease-in-out 0s;
              display: none;

              .search-item {
                height: 50px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #e9e9e9;
                padding: 0 5px;
                cursor: pointer;

                &:hover {
                  background-color: rgba(236, 230, 230, 0.95);
                }

                span {
                  flex: 1;
                  font-size: 12px;
                }
                .el-icon-loading {
                  margin-right: 15px;
                }

                img {
                  height: 10px;
                  width: 16px;
                }
              }
            }

        .load-box {
          height: 100%;
          width: 100%;
          display: flex;
          // align-items: center;
          padding-top: 6px;
          justify-content: center;
          font-size: 20px;
          .loader {
            width: 48px;
            height: 48px;
            border: 5px solid #fff;
            border-bottom-color: #ff3d00;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
          }

          @keyframes rotation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }

        .expand-box-active {
          height: 300px;
          opacity: 1;
          display: block;
          overflow-y: scroll;
        }

        input {
          background: transparent;
          font-weight: normal;
        }

        img {
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }
      }
          // 移动修复版本
          .input-box {
            height: 50px;
            flex: 1;
            border-radius: 30px;
            background-color: #fff;
            display: flex;
            align-items: center;
            padding-left: 10px;
            border: 1px solid #eeeeee;
            transition: all ease-in .2s;

            .el-icon-search {
              width: 28px;
              font-weight: 700;
              color: #AA0BC0;
            }

            input {
              height: 100%;
              width: 100%;
              font-family: "Source Sans Pro", sans-serif;
              font-size: 14px;
              font-weight: 400;
              flex: 1;
              color: #000;
              fill: #000;
              padding-left: 5px;
            }
          }
          .search-btn {
            margin-left: 8px;
            button {
              height: 36px;
              width: 70px;
              border-radius: 20px;
              background-color: #1daa63;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-weight: bold;
              font-size: 14px;
            }
          }
          .focus-input {
            box-shadow: rgba(0, 0, 0, 0.295) 0px 6px 12px, rgba(0, 0, 0, 0.316) 0px 3px 6px;
          }
        }

        :deep(.cuise-bar) {
          margin: 15px 0 0px 0px;
          
          .cuise-item {
            height: 88px;
            width: 88px;
            min-width: 88px;
            max-width: 92px;
            margin-left: -10px;
            .img {
              flex: 1;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                height: 60px;
                width: 61px;
              }
            }
            .name {
              font-size: 12px;
            }
          }

          .cuise-item:nth-child(1) {
            margin-left: 15px;
          }
          .swiper-button-prev, .swiper-button-next {
            display: none;
          }
        }
      }
    }
    }
    :deep(.banner-box) {
      display: block;
      padding: 15px 15px 0 15px;
      width: 100%;
      .el-carousel__item:nth-child(2n) {
          background-color: #99a9bf;
      }
      
      .el-carousel__item:nth-child(2n+1) {
          background-color: #d3dce6;
      }
    }
    .tag-bar {
      display: none;
    }
    :deep(.merchant-box) {
      margin-top: 15px;
    }
  }
  

}

</style>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,500&display=swap");
</style>

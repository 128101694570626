<template>
  <div class="category-index">
    <div class="match-box">
      <h2 class="title">Popular Categories</h2>
      <!-- <h2 class="title title-green">Categories</h2> -->
      <div class="cate-box">
        <swiper class="my-swiper" :options="swiperOption">
          <swiper-slide class="swiper-slide" v-for="item in cateList" :key="item.cuisineId" >
            <div @click="goCate(item)" class="cate-item">
              <img :src="item.featuredImage" :alt="item.cuisineName" />
              <span class="name" >{{ item.cuisineName }}</span>
            </div>
          </swiper-slide>
        </swiper>
        <!--前进后退按钮-->
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
        <!-- 指示器 -->
        <div class="swiper-pagination"></div>
      </div>

      <div class="cate-box-mobile" >
        <ul>
          <li class="swiper-slide" v-for="item in cateList" :key="item.cuisineId" >
            <div @click="goCate(item)" class="cate-item">
              <img :src="item.featuredImage" :alt="item.cuisineName" />
              <span class="name" >{{ item.cuisineName }}</span>
            </div>
          </li>
        </ul>
      </div>

      <h2 class="title" style="margin-top: 20px;">Hot Offers</h2>
      <div class="off-box" >
        <ul>
          <li class="off-item">
            <div class="left-box" >
              <span>UP TO 20% OFF</span>
              <span class="bold">Only</span>
              <span class="bold">on</span>
              <span class="bold">Pizza</span>
              <button class="btn" @click="goBrowse({ name:'Pizza', cuisineId: 19 })" >See All</button>
            </div>
            <div class="right-box" >
              <img src="@/assets/index/icon_index_cate3.png" style="widht:323px;height:334px" alt="pizza">
            </div>
          </li>
          <li class="off-item" style="background: #F94B00;">
            <div class="left-box" >
              <span>UP TO 20% OFF</span>
              <span class="bold">Only</span>
              <span class="bold">on</span>
              <span class="bold">Chinese</span>
              <button class="btn" @click="goBrowse({ name:'Chinese', cuisineId: 16 })" >See All</button>
            </div>
            <div class="right-box" >
              <img src="@/assets/index/icon_index_cate2.png" alt="pizza">
            </div>
          </li>
          <li class="off-item" style="background: #AA0BC0;">
            <div class="left-box" >
              <span>UP TO 20% OFF</span>
              <span class="bold">Only</span>
              <span class="bold">on</span>
              <span class="bold">Burger</span>
              <button class="btn" @click="goBrowse({ name:'Burger', cuisineId: 11 })" >See All</button>
            </div>
            <div class="right-box" >
              <img src="@/assets/images/img_home.png" alt="pizza">
            </div>
          </li>
        </ul>
      </div>

      <div class="desc-box">
          <img src="@/assets/index/index_card.png" alt="">
          <!-- <ul>
            <li>
              <img src="@/assets/index/icon_index_1.png" alt="Daily">
            </li>
            <li>
              <img src="@/assets/index/icon_index_2.png" alt="Live">
            </li>
            <li>
              <img src="@/assets/index/icon_index_3.png" alt="Quick">
            </li>
          </ul> -->
      </div>

      <!-- 获取定位之后的插槽 -->
      <slot></slot>

      <FaqsBox></FaqsBox>
      

    </div>
  </div>
</template>

<script>
import { postGateway } from "@/request";
import FaqsBox from './FaqsBox.vue';
export default {
  components: {
    FaqsBox
  },
  data() {
    return {
      swiperOption: {
        //幻灯片放映方向
        direction: "horizontal", //通常不与左右按钮一同使用
        slidesPerView: 3,
        spaceBetween: 23,
        //分页器配置项
        pagination: {
            el: ".swiper-pagination", //分页器的类名
            clickable: true, // 点击分页器跳切换到相应的幻灯片
            bottom: 0,
        },
        //前进后退按钮
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        //幻灯片播放配置项
        loop: false, //是否循环播放
        speed: 1000, // 发生页面切换动画时，动画的切换速度
        autoPlay: false,
        breakpoints: {
            1024: {
                slidesPerView: 4,
                spaceBetween: 15,
            }
        },
        on: {
            slideChangeTransitionEnd: function () {
                // console.log(this.activeIndex); //每次切换结束时，在控制台打印现在是第几个slide
            },
        },
      },
      cateList: [],
      descList: [

      ]
    };
  },
  mounted() {
    this.getCateList();
  },
  methods: {
    getCateList() {
      postGateway({
        url: '/customerApp/index/popularCategory',
        method: 'GET',
        data: {}
      }).then(res=>{
        this.cateList = res.data;
      })
    },
    goCate(item) {
      console.log(item,'item')
      let { cuisineId, cuisineName } = item;
      this.$router.push({
        path: "/browse",
        query: {
          searchName: "",
          lng: '',
          lat: '',
          searchLoc: '',
          transType: '',
          name: cuisineName,
          cuisineId
        },
      });
      // this.$router.push({
      //   path: '/cuisine',
      //   query: {
      //     cuisineId: cuisineId,
      //     cuisineName: cuisineName
      //   }
      // })
    },
    goBrowse(obj) {
      let { name, cuisineId } = obj;
      this.$router.push({
        path: "/browse",
        query: {
          searchName: "",
          lng: '',
          lat: '',
          searchLoc: '',
          transType: '',
          name,
          cuisineId
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #AA0BC0;
$greenColor: #09ca6a;

.category-index {
  // background-color: #f4f4f4;
  padding-bottom: 60px;

  .match-box {
    width: 1400px;
  }

  h2 {
    margin: 0;
    font-weight: 700;
    font-family: "Source Sans Pro", sans-serif;
    text-shadow: 0px 14px 15px rgba(255, 174, 0, 0.01),
      0px 27px 82px rgba(255, 174, 0, 0.28);
  }
  .title {
    font-size: 50px;
    color: $mainColor;
    white-space: nowrap;
  }
  .title-green {
    color: $greenColor;
  }

  .cate-box {
    position: relative;
    width: 1210px;
    margin: 40px auto 0;
    padding-bottom: 60px;
  }

  .cate-box-mobile {
    display: none;
    white-space: nowrap;
    overflow-x: scroll;
    width: 100%;
    padding: 10px 0;
    .swiper-slide {
      height: 160px;
      width: 160px;
      border-radius: 25px;
      margin-left: 20px; 
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      background: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      .cate-item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-content: center;

        img {
          height: 120px;
          width: 120px;
        }

        .name {
          font-weight: bold;
          color: #111;
          font-size: 15px;
          text-align: center;
        }

      }

    }
  }

  .my-swiper {
    width: 100%;
    min-height: 290px;
    .swiper-slide {
      height: 280px;
      border-radius: 25px;
      margin: 10px 0px 10px 0px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .cate-item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-content: center;

        img {
          height: 220px;
          width: 220px;
        }

        .name {
          margin-top: 10px;
          font-weight: bold;
          color: #111;
          font-size: 20px;
          text-align: center;
        }

      }
    }
  }
  .swiper-pagination {
    margin-top: 45px;
    left: 44%;
    transform: translateY(-50%);
    :deep(.swiper-pagination-bullet) {
      margin-right: 8px!important;
    }
  }
  .swiper-button-prev, .swiper-button-next {
    background: #fff;
    width: 76px;
    height: 76px;
    top: 120px;
    border-radius: 38px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &::after {
      font-size: 26px;
      color: #000;
    }
  }
  .swiper-button-prev {
    left: -40px;
  }
  .swiper-button-next {
    right: -40px;
  }

  .off-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-top: 44px;

    .off-item {
      display: inline-flex;
      zoom: 0.8;
      margin-right: 30px;
      width: 516px;
      height: 283px;
      // border-radius: 10px;
      border-radius: 30px;
      background: #5CC204;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      .left-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        color: #fff; 
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 700;
        box-shadow: border-box;
        padding: 20px;
        font-size: 25px;
        .bold {
          font-size: 40px;
          line-height: 40px;
        }
        .btn {
          cursor: pointer;
          margin-top: 35px;
          width: 122px;
          height: 54px;
          background: #fff;
          border-radius: 61px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          color: #000;
          font-size: 22px;
        }
      }
      .right-box {
        width: 335px;

        img {
          height: 335px;
          width: 322px;
        }
      }
    }
  }

  .desc-box {
    margin: 100px auto 0;
    width: 1030px;
    // height: 246px;
    border-radius: 46px;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    img {
      width: 100%;
    }

    ul {
      height: 100%;
      width: 100%;
      display: flex;
      li {
        flex: 1;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        &:nth-child(1), &:nth-child(2) {
          &::before {
            content: '';
            position: absolute;
            right: 0;
            display: inline-block;
            background: #CFCFCF;
            height: 96px;
            width: 2px;
          }
        }
        img {
          height: 136px;
          width: 317px;
        }
      }
    }
  }

  // 兼容移动端代码
  @media screen and (max-width: 950px) {
    .match-box {
      width: 100%;
      padding: 20px 20px 0;

      .title {
        font-size: 30px;
      }

      .cate-box {
        display: none;
        width: 100%;
        zoom: 0.4;
        margin-bottom: 120px;
        .my-swiper {
          padding-top: 10px;
          width: 100%;
        }
        .swiper-pagination {
          zoom: 2;
          left: 35%;
          margin-top: 20px;
          :deep(.swiper-pagination-bullet) {
            margin-right: 8px!important;
          }
        }
        .swiper-slide {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
          height: 300px;
          width: 240px;
          &:nth-child(1) {
            margin-left: 10px;
          }
        }
        .swiper-button-prev,.swiper-button-next {
          zoom: 1.3;
        }
      }

      .cate-box-mobile {
        display: block;
      } 

      .off-box {
        zoom: 0.6;
        height: auto;
        padding: 10px 0 60px 0;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start;
        ul {
          width: 100%;
        }
      }

      .desc-box {
        margin: 100px auto 0;
        zoom: 0.32;

        ul li img {
          zoom: 1.2;
        }
      }

    }
  }

   // 部分ipad设备
   @media screen and (min-width: 750px) and (max-width: 1100px) {
    .match-box {
      .off-box {
        zoom: 1;

        ul .off-item {
          zoom: 0.7;
          .right-box {
            zoom: 0.7;
          }
        }
      }
    }
  }

}
</style>

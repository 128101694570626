<template>
    <div class="account-box">
        <div class="account">
            <a @click="backToEdit" class="close">
                <i class="el-icon-close"></i>
            </a>
            <template v-if="type=='editInfo'">
                <div class="flex-box" v-if="true">
                    <div class="input-box" >
                        <div class="form-label">First Name</div>
                        <div class="form-input" >
                            <input type="text" v-model="userInfo.firstName" placeholder="First Name">
                        </div>
                    </div>
                    <div class="input-box" >
                        <div class="form-label">Last Name</div>
                        <div class="form-input" >
                            <input type="text" v-model="userInfo.lastName" placeholder="Last Name">
                        </div>
                    </div>
                </div>
                <div class="form-label">Nick Name</div>
                <div class="form-input" >
                    <input type="text" v-model="userInfo.nickName" placeholder="Please enter your name">
                </div>
                <div class="form-label">Email</div>
                <div class="form-input" >
                    <input type="text" v-model="userInfo.emailAddress" placeholder="Please enter your email">
                </div>
                <div class="form-label" @click="editPhone">Phone Number</div>
                <div class="form-input" style="cursor: pointer;" @click="editPhone">
                    <input type="text" v-model="userInfo.mobilePhone" disabled placeholder="Please enter your phone number">
                </div>
            </template>
            <template v-if="type=='bindPhone'">
                <div class="form-input" style="margin-top: 20px;">
                    <div class="phone-btn-box">
                        <PhoneBtn v-model="form.code"></PhoneBtn>
                    </div>
                    <input type="text" v-model="form.phone" @keyup="form.phone=form.phone.replace(/\D/g,'')" placeholder="Place enter your phone number">
                </div>
                <div class="form-input" >
                    <input type="text" v-model="form.verCode" placeholder="Code">
                </div>
                <!-- 验证token -->
                <CloudflareVerify style="padding-top: 5px;" ref="cloudflare"></CloudflareVerify>
            </template>
            
            <div class="button-box">
                <div class="btn flex-col-center" @click="submit" v-if="type == 'editInfo'">
                    <i class="el-icon-loading" v-if="loading"></i>
                    <span v-else>Save</span>
                </div>
                <div class="btn flex-col-center" @click="showCaptcha" v-if="type == 'bindPhone' && !form.verCode">
                    <i class="el-icon-loading" v-if="loading"></i>
                    <span v-else>{{ sendPhonecode }}</span>
                </div>
                <div class="btn flex-col-center" @click="confirmEditPhone" v-if="type == 'bindPhone' && form.verCode">
                    <i class="el-icon-loading" v-if="loading"></i>
                    <span v-else>Save</span>
                </div>
            </div>
        </div>

        <!-- 验证码滑块模块 -->
        <Verify @success="sendcodeMsg"
            :mode="'pop'"
            :captchaType="'blockPuzzle'"
            :imgSize="{ width: '330px', height: '155px' }"
            ref="verify"
        ></Verify>

    </div>
</template>

<script>
import PhoneBtn from '@/components/PhoneBtn';
import Verify from '@/components/Verifition/Verify';
import CloudflareVerify from '@/components/CloudFlareVerify.vue';
import { postGateway } from '@/request';
export default {
    data() {
        return {
            loading: false,
            secondSend: 0,
            form: {
                code: (this.$isUk?'+44':'+353'),
                phone: '',
                verCode: ''
            },
            type: 'editInfo',
            userInfo: {
                contactPhone: '',
                emailAddress: '',
                nickName: '',
                firstName: '',
                lastName: ''
            },
            num: 0,
            canGetCode: true,
            sendPhonecode: 'Get verification code',
        }
    },
    components: {
        PhoneBtn,
        Verify,
        CloudflareVerify
    },
    computed: {
        isMobile() {
            return window.innerWidth < 950
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        developing() {
            this.$message.info('Developing')
        },
        resetCode() {
            this.$refs.cloudflare.reSetTurnSite();
        },
        backToEdit() {
            if(this.isMobile) {
                this.$router.go(-1)
                return
            }
            this.type = 'editInfo';
            this.getData();
        },
        editPhone() {
            this.form.code = '+44';
            this.form.phone = '';
            this.type = 'bindPhone';
        },
        confirmEditPhone() {
            this.loading = true;
            postGateway({
                url: '/customer/atClient/editPhone',
                data: {
                    "captcha": this.form.verCode,
					"clientId": localStorage.getItem('clientId'),
					"contactPhone": this.form.code + this.form.phone
                }
            }).then(res=>{
                this.loading = false;
                this.type = 'editInfo';
                this.$message.success('Edit Success!')
                this.getData();
                if(res.code && res.code!=200) {
					if(res.code == 50020) {
						this.$message.info('The phone number has been registered')
					} else if (res.code == 50002) {
						this.$message.info('Incorrect verification code')
					} else if (res.code == 50003) {
						this.$message.info('The email has been registered')
					} else {
						this.$message.info(res.msg?res.msg:'Net Error!')
					}
					return
				}
                if(this.isMobile) {
                    this.$router.go(-1)
                }
            }).catch(()=>{
                this.loading = false;
                console.log('编辑手机号报错')
            })
        },
        // 显示验证滑块
        async showCaptcha() {
            // this.sendcodeMsg({captchaVerification:''});
            // return
            /* eslint-disable */
            if(!this.num==0) return
            if(!this.form.phone.trim()) {
                this.$message({
                    message: 'Please enter phone number',
                    type: 'warning'
                })
                return
            }
            if (this.form.phone.indexOf(44) === 0) {
                this.$message({
                message: "Without the '44' country code.",
                type: "warning",
                });
                return;
            }
            // 加一个校验手机号开头
            let phoneCode = this.form.code.replace('+','');
            if(this.form.phone.indexOf(phoneCode) === 0) {
                this.$message({
                    message: "Please check phone number width '" + phoneCode + "'",
                    type: "warning",
                });
                return;
            }
            if(!this.canGetCode) return

            // console.log(this.$refs.cloudflare.getToken(),'获取验证后的token')
            this.sendcodeMsg(this.$refs.cloudflare.getToken());
            // this.$refs.verify.show()
        },
        //发送手机验证码
        async sendcodeMsg(captchaVerification) {
            if(!captchaVerification) {
                return
            }
            this.loading = true;
            // let url = `/customer/atClient/smsSend`
            // if (this.secondSend > 1) {
            //     url = `/customer/atClient/smsSendTextlocal`
            // }
            let url = `/customer/atClient/smsSendCloudFlare`
            if (this.secondSend > 1) {
                url = `/customer/atClient/smsSendTextlocalCloudFlare`
            }
            postGateway({
                url: url,
                data: {
                    phone: this.form.code + this.form.phone.trim(),
                    captchaType: 'register',
                    captchaVerification
                }
            }).then((res) => {
                this.loading = false;
                if(res.code == 200){
                    this.$message({
                        message: 'Verification sent successfully',
                        type: 'success'
                    })
                    this.secondSend++;
                }
                this.resetCode();
            })
            .catch(err=>{
                this.loading = false;
                console.log(err,'发送短信失败')
                this.$message({
                    message: 'Verification sent failed',
                    type: 'error'
                })
                this.resetCode();
            })
            this.num = 60;
            this.timer2 = setInterval( ()=> {
                this.num--;
                this.canGetCode = false;
                this.sendPhonecode = this.num + 'seconds later retry';
                if (this.num == 0) {
                    this.sendPhonecode = 'Get verification code';
                    this.canGetCode = true;
                    clearInterval(this.timer2)
                }
            }, 1000)
            
        },
        getData() {
            postGateway({
                url: '/customerApp/center/getDetail',
                method: 'GET',
                data: {
                    clientId: localStorage.getItem('clientId')
                }
            }).then(res=>{
                this.userInfo = res.data;
                this.loading = false;  
            })
            .catch(err=>{
                this.$message.error(err.msg)
                this.loading = false;  
            })
        },
        submit() {
            this.loading = true;  
            let {avatar,nickName,emailAddress,contactPhone} = this.userInfo;
			
			console.log(avatar,'头像地址')
			
			if(!emailAddress.trim()) {
				this.$message.toast('Check your Email');
				return
			}
			let nameArr = nickName.split(' ')
            console.log(nameArr[0], 'nameArr')
			let param = {
				clientId: localStorage.getItem('clientId'),
				avatar,nickName,emailAddress,contactPhone,
                firstName: this.userInfo.firstName?this.userInfo.firstName:nameArr[0],
                lastName: this.userInfo.lastName?this.userInfo.lastName:(nameArr.length>1?nameArr[1]:''),
			}
            postGateway({
                url: '/customer/atClient/savePersonalInfo',
                method: 'POST',
                data: param
            }).then(()=>{
                this.getData();
                this.$message.info('Success')
            })
            .catch(err=>{
                this.getData();
                this.$message.error(err.msg)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #09CA6A;

.account-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    // border-radius: 6px;
    border-radius: 30px;
    // box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
    position: relative;
    background: #ffffff;
}
.account {
    padding: 80px 0 120px;
    .close {
      position: absolute;
      right: 100px;
      cursor: pointer;
      .el-icon-close {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .flex-box {
        display: flex;
        .input-box {
            // flex: 1;
            margin-right: 10px;
            width: 200px;
            input {
                width: 100%;
                border-radius: 25px;
                background: #fafafa;
            }
        }
    }
    .title {
        padding-top: 40px;
        font-weight: 600;
        font-size: 24px;
        color: $mainColor;
        text-align: center;
    }
    .form-label {
        color: #000;
        font-size: 18px;
        font-weight: 700;
        margin: 10px 0;
    }
    .form-input {
        display: flex;
        height: 49px;
        font-size: 18px;
        margin: 10px 0 30px;
        .phone-btn-box {
            box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
            background-color: #fff;
            margin-right: 20px;
            border-radius: 6px;
        }

        input {
            height: 100%;
            // background-color: #fff;
            // border: 1px solid #EDEDED;
            // box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
            // border-radius: 12px;
            font-size: 18px;
            width: 400px;
            padding-left: 20px;    
            border-radius: 25px;
            background: #f5f5f5;
        }
    }
    .button-box {
        display: flex;
        justify-content: flex-start;
        margin-top: 40px;

        .btn {
            height: 54px;
            min-width: 140px;
            background-color: $greenColor;
            color: #fff;
            font-size: 20px;
            padding: 0 20px;
            font-weight: 600;
            border-radius: 30px;
            transition: all 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                background-color: #07bb61;
            }
        }
    }
}
@media screen and (max-width: 950px) {
    .account-box {
        margin: 15px;
        align-items: unset;
        .account {
            padding: 25px 15px 15px;
            .close {
                right: 10px;
                top: 10px;
            }
            .button-box {
                justify-content: flex-end;
            }
            .form-label {
                font-size: 16px;
            }
            .form-input  {
                font-size: 16px;
                input {
                    font-size: 16px;
                }
            }
        }
    }
}
</style>
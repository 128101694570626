<template>
  <div class="checkout" :style="{ 'min-height': innerHeight }">
    <div class="content-box">
      <div class="match-box">
        <div class="break-box">
          <div class="break">
            <i class="el-icon-back" @click="goBack"></i>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item @click="$router.go(-1)"
                >Menu</el-breadcrumb-item
              >
              <el-breadcrumb-item>
                <span style="color: rgb(9, 202, 106)">Check out</span>
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
        <h2 class="check-title" v-if="token">Check out</h2>
        <h2 class="check-title" v-else>Login To Continue</h2>
        <img
          class="cuise-img"
          src="@/assets/images/cuiseNot.png"
          alt="nofound"
        />
      </div>
    </div>

    <div class="main-content">
      <!-- Display a payment form -->
      <form id="card-form">
        <h2 class="title">PayPal Pay</h2>
        <div class="loading-box" v-if="showLoad">
          <i class="el-icon-loading"></i>
        </div>

        <div id="paypal-button-container"></div>
      </form>
    </div>

    <LoadErrorModal v-model="showLoadError" @confirm="reloadScript" ></LoadErrorModal>

  </div>
</template>

<script>
/* eslint-disable */
import { postGateway, postGatewayOther } from "@/request";
import LoadErrorModal from "@/components/LoadErrorModal.vue"
export default {
  name: "NotFound",
  components: {
    LoadErrorModal
  },
  metaInfo: {
    meta: ["All Eat "],
    title: "All Eat not found",
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      paypalToken: "",
      merchantId: null,
      orderId: "",
      amount: "",
      innerHeight: "",
      scriptLoading: true,
      message: "vue-btn",
      showToast: false,
      showErr: false,
      errMsg: "Please enter complete information",
      showLoad: true,
      currency: 'GBP',
      showLoadError: false,
      form: {
        cardNumber: "",
        expirationDate: "",
        cvv: "",
      },
    };
  },
  watch: {
    'showLoadError': function(val) {
      if(val) {
        this.uploadError('paypal加载报错');
      }
    }
  },
  created() {
    let { merchantId, orderId, amount } = this.$route.query;
    this.merchantId = merchantId;
    this.orderId = orderId;
    this.amount = amount;
    this.creatScript();
  },
  mounted() {
    this.innerHeight = window.innerHeight + "px";
  },
  methods: {
    reloadScript() {
      this.showLoadError = false;
      this.showLoad = true;
      this.creatScript();
    },
    creatScript() {
      // 获取paypal client id
      // 先获取clientid
      postGateway({
        url: "/customerApp/order/getPayClientId",
        method: "GET",
        data: {
          merchantId: this.merchantId,
        },
      })
        .then((data) => {
          console.log(data, "client");
          let { paypal_client_id, currencyType } = data.data;
          console.log(paypal_client_id, "paypal_client_id", currencyType);
          const scriptInfo = document.createElement("script");
          scriptInfo.type = "text/javascript";
          this.currency = currencyType || 'GBP';
          let src = `https://www.paypal.com/sdk/js?client-id=${paypal_client_id}&currency=${this.currency}&locale=en_GB&disable-funding=card`;

          if(this.currency != 'GBP') {  // 不是英镑的时候可以显示 card
            src = `https://www.paypal.com/sdk/js?client-id=${paypal_client_id}&currency=${this.currency}&locale=en_IE`
          }

          scriptInfo.src = src;
          document.head.appendChild(scriptInfo);

          scriptInfo.onload = () => {
            this.renderPaypal();
          };
          scriptInfo.onerror = (err) => {
            this.showLoadError = true;
            console.log(err, '加载script 报错')
          }
        })
        .catch(function (error) {
          this.showLoadError = true;
          console.log(error);
        });
    },
    renderPaypal() {
      setTimeout(() => {
        this.showLoad = false;
      }, 500);
      let _this = this;
      paypal
        .Buttons({
          createOrder: function (data, actions) {
            console.log("createOrderData", data);
            console.log("createOrderactions", actions);
            // This function sets up the details of the transaction, including the amount and line item details.
            return actions.order.create({
              intent: "CAPTURE",
              // application_context: {
              //     user_action: "CONTINUE",
              //     brand_name: "nicefood",
              //     locale: "en-US",
              //     landing_page: "BILLING",
              //     shipping_preference: "SET_PROVIDED_ADDRESS"
              // },
              purchase_units: [
                {
                  // reference_id: "PUHF",
                  // soft_descriptor: "HighFashions",
                  invoice_id: _this.orderId ? _this.orderId : null, // 订单号
                  // custom_id: "CUST-HighFashions",
                  amount: {
                    currency_code: _this.currency,
                    value: _this.amount ? _this.amount : 0, //订单金额
                  },
                },
              ],
            });
          },
          onApprove: function (data, actions) {
            //支付成功回调
            console.log("onApproveData", data);
            console.log("onApproveActions", actions);
            // This function captures the funds from the transaction.
            return actions.order.capture().then(function (details) {
              console.log("交易成功", details);

              _this.showLoad = true; // 展示loading
              postGatewayOther({
                url: `https://nweb.alleatapp.com/base/paypal/check?id=${details.id}&currencyType=${_this.currency}`,
                method: "GET",
                data: {}
              }).then(res=>{
                _this.showLoad = false;
                console.log(res,'通知后台订单支付成功.')
                // 跳转支付成功
                // _this.$router.push({
                //   path: `/my?orderId=${_this.orderId}&type=orderDone`,
                // });
                _this.$router.push({
                  path: `/orderSuccess?orderId=${_this.orderId}&type=orderDone`,
                });
              })
              .catch(err=>{
                _this.showLoad = false;
                console.log(err,'报错内容')
              })

            });
          },
          onCancel: function (data) {
            // 取消支付的回调
            // Show a cancel page, or return to cart
          },
        })
        .render("#paypal-button-container");
    },
    detectBrowser() {
      const userAgent = navigator.userAgent;
      if (userAgent.indexOf("Chrome")!== -1 && userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Edg") === -1) {
        return "Google Chrome"; // Chrome
      } else if (userAgent.indexOf("Firefox") !== -1) {
        return "Mozilla Firefox"; // Firefox
      } else if (userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Chrome") === -1 && userAgent.indexOf("Edge") === -1) {
        return "Apple Safari"; // Safari
      } else if (userAgent.indexOf("Edg") !== -1) {
        return "Microsoft Edge"; // Edge
      } else {
        return "Unknown browser"; // 其他浏览器...（可根据自己需要确定是否新增其他浏览器的判断）
      }
    },
    uploadError(str) {
      try {
        postGateway({
            url: '/customerApp/order/submitPaylog',
            method: "POST",
            data: {
              cardPayInfo: str + ` -${this.orderId} - paypal - ${this.detectBrowser()} - ${JSON.stringify(this.$route.query)}`,
            }
        }).then((res) => {
            console.log(res, "错误数据上报");
          })
          .catch((err) => {
            console.log("数据上报失败");
          });
          
      } catch (e) {
        console.log("数据上报错误处理");
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
$greenColor: rgb(9, 202, 106);
$mainColor: #aa0bc0;
.checkout {
  min-height: 100vh;

  .content-box {
    position: relative;
    background-image: url("@/assets/images/notfoundBg.png");
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 360px;
    .match-box {
      position: relative;
      height: 100%;
    }
    .break-box {
      position: absolute;
      box-sizing: border-box;
      top: 48px;

      .break {
        height: 370px;
        height: 44px;
        display: inline-flex;
        align-items: center;
        padding: 20px;
        border-radius: 22px;
        background-color: #0a191e;
        color: #ffffff;
        .el-icon-back {
          color: #2680ed;
          font-size: 22px;
          margin-right: 10px;
          cursor: pointer;
        }
        :deep(.el-breadcrumb__inner) {
          font-weight: 600;
          color: #fff;
        }
        :deep(.el-breadcrumb__separator) {
          color: #fff;
        }
      }
    }
    .check-title {
      position: absolute;
      box-sizing: border-box;
      bottom: 48px;
      font-weight: 700;
      font-size: 70px;
      color: #fff;
      margin: 0;
    }
    .cuise-img {
      height: 259px;
      width: 370px;
      position: absolute;
      z-index: 1;
      right: 6%;
      top: 0px;
    }
    // tips
    .title-tips {
      font-family: "Poppins";
      font-size: 50px;
      color: #fff;
      font-weight: bold;
    }

    .title-desc {
      font-family: "Poppins";
      margin-top: 10px;
      font-size: 20px;
      color: #fff;
      letter-spacing: 0.5px;
    }
  }

  .page-title {
    display: flex;
    justify-content: center;
    font-size: 24px;
    color: #000;
    font-weight: 600;
    margin: 25px 0 10px;
  }

  .steps-box {
    margin: 20px auto;
    width: 1600px;
  }

  .main-content {
    margin: 20px auto;
    width: 1600px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .section {
      display: flex;
      flex-direction: column;
      margin-right: 3%;
    }
    .section-login {
      width: 924px;
    }
    .section-1 {
      width: 538px;
    }
    .section-2 {
      width: 476px;
    }
    .section-3 {
      width: 693px;
    }
  }
}

#card-form {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
  padding: 20px;
  border-radius: 12px;
  width: 400px;
  .title {
    text-align: center;
    padding-bottom: 20px;
    font-weight: 700;
  }
  .loading-box {
    display: flex;
    justify-content: center;
    font-size: 25px;
  }
  #submit {
    margin-top: 20px;
    background: #5469d4;
    color: #fff;
    font-weight: 600;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
  }
  #card-holder-name {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 12px;
    margin-top: 6px;
    margin-bottom: 16px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
    font-size: 17px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-weight: normal;
    resize: vertical;
  }
}

.paypal-button-container {
  width: 100%;
  max-width: 760px;
  padding: 20px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
}
.card_container {
  width: 100%;
  max-width: 760px;
  padding: 20px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
}
.card_field {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 12px;
  margin-top: 6px;
  margin-bottom: 16px;
  color: #3a3a3a;
  font-family: helvetica, tahoma, calibri, sans-serif;
  font-size: 17px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

button {
  border: none;
}
input {
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 20px;
}

@media screen and (max-width: 950px) {
  .checkout {
    min-height: unset!important;
    .content-box {
      height: 48px;
      .match-box {
        width: 100vw;

        .check-title {
          font-size: 20px;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          bottom: unset;
        }
        .break-box, .cuise-img {
          display: none;
        }

      }
    }
    .main-content {
      width: 100vw;
      #card-form {
        width: 97vw;
        #submit {
          font-size: 20px;
        }
      }
    }
  }
  
}

</style>

<template>
  <div class="checkout" :style="{ 'min-height': innerHeight }">
    <div class="content-box" >
      <div class="match-box" >
        <div class="break-box" >
          <div class="break">
              <i class="el-icon-back" @click="goBack"></i>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item @click="$router.go(-1)">Menu</el-breadcrumb-item>
                  <el-breadcrumb-item >
                    <span style="color:rgb(9, 202, 106);">Check out</span>
                  </el-breadcrumb-item>
              </el-breadcrumb>
          </div>
        </div>
        <h2 class="check-title" v-if="token">Check out</h2>
        <h2 class="check-title" v-else>Login To Continue</h2>
        <img
          class="cuise-img"
          src="@/assets/images/cuiseNot.png"
          alt="nofound"
        />
      </div>
    </div>
    
    <div class="main-content">
      <!-- Display a payment form -->
      <form id="payment-form">
        <h2 class="title">
          Stripe Pay
        </h2>
        <div class="loading-box"  v-if="scriptLoading">
          <i class="el-icon-loading" ></i>
        </div>
        <div id="payment-element">
          <!--Stripe.js injects the Payment Element-->
        </div>
        <el-button id="submit" @click="handleSubmit">
          <div class="spinner hidden" id="spinner"></div>
          <span id="button-text" >{{ scriptLoading?'Loading...':'Pay now '}}</span>
        </el-button>
        <div id="payment-message" class="hidden"></div>
      </form>
    </div>

  </div>
</template>

<script>
/* eslint-disable */ 
import { postGateway } from '@/request';
let elements;
let stripe;
export default {
  name: "NotFound",
  components: {
  },
  metaInfo: {
    meta: ["All Eat "],
    title: "All Eat not found",
  },
  data() {
    return {
      token: localStorage.getItem('token'),
      merchantId: null,
      orderId: '',
      amount: '',
      innerHeight: '',
      scriptLoading: true,
    }
  },
  watch: {
   
  },
  created() {
    let { merchantId, orderId, amount } = this.$route.query;
    this.merchantId = merchantId;
    this.orderId = orderId;
    this.amount = amount;
    this.getClientToken().then(()=>{
      this.initStripe();
    })
  },
  mounted() {
    this.innerHeight = window.innerHeight + "px";
  },
  methods: {
    async handleSubmit() {
      this.setLoading(false);
      console.log(elements);
      const { error } = await stripe.confirmPayment({
        elements: elements,
        confirmParams: {
          //支付成功跳转页面
          return_url: "http://localhost:8080/usercenter?orderSuccess=1",
        },
      });
      if (error.type === "card_error" || error.type === "validation_error") {
        this.$message({
          type: "error",
          message: error.message,
        });
      } else {
        this.$message({
          type: "error",
          message: "An unexpected error occured.",
        });
      }
      this.setLoading(false);
    },
    getClientToken() {
      return postGateway({
        url: '/customerApp/order/getPayClientId',
        method: 'GET',
        data: {
          merchantId: this.merchantId
        }
      }).then(res=>{
        let { stripe_client_id } = res.data;
        stripe = Stripe(`${stripe_client_id}`);
        console.log(stripe,'stripe')
      })
    },
    initStripe() {
      postGateway({
        url: '/stripePay/create-payment-intent',
        method: 'GET',
        data: {
          oId: this.orderId
        }
      }).then(res=>{
        let { clientSecret } = res.data;
        const appearance = {
          theme: "stripe",
        };
        elements = stripe.elements({ appearance, clientSecret });
        const paymentElement = elements.create("payment");
        paymentElement.mount("#payment-element");
        setTimeout(() => {
          this.scriptLoading = false;
        }, 200);
      })
    },
    setLoading(isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("#submit").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("#submit").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  },
};
</script>

<style lang="scss" scoped>
$greenColor: rgb(9, 202, 106);
$mainColor: #aa0bc0;
.checkout {
  min-height: 100vh;

  .content-box {
    position: relative;
    background-image: url('@/assets/images/notfoundBg.png');
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 360px;
    .match-box {
      position: relative;
      height: 100%;
    }
    .break-box {
      position: absolute;
      box-sizing: border-box;
      top: 48px;

      .break {
          height: 370px;
          height: 44px;
          display: inline-flex;
          align-items: center;
          padding: 20px;
          border-radius: 22px;
          background-color: #0A191E;
          color: #FFFFFF;
          .el-icon-back {
              color: #2680ED;
              font-size: 22px;
              margin-right: 10px;
              cursor: pointer;
          }
          :deep(.el-breadcrumb__inner) {
              font-weight: 600;
              color: #fff;
          }
          :deep(.el-breadcrumb__separator) {
              color: #fff;
          }
      }
  }
  .check-title {
    position: absolute;
    box-sizing: border-box;
    bottom: 48px;
    font-weight: 700;
    font-size: 70px;
    color: #fff;
    margin: 0;
  }
  .cuise-img {
      height: 259px;
      width: 370px;
      position: absolute;
      z-index: 1;
      right: 6%;
      top: 0px;
    }
    // tips
    .title-tips {
      font-family: "Poppins";
      font-size: 50px;
      color: #fff;
      font-weight: bold;
    }

    .title-desc {
      font-family: "Poppins";
      margin-top: 10px;
      font-size: 20px;
      color: #fff;
      letter-spacing: 0.5px;
    }
  }

  .page-title {
    display: flex;
    justify-content: center;
    font-size: 24px;
    color: #000;
    font-weight: 600;
    margin: 25px 0 10px;
  }

  .steps-box {
    margin: 20px auto;
    width: 1600px;
  }

  .main-content {
    margin: 20px auto;
    width: 1600px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .section {
      display: flex;
      flex-direction: column;
      margin-right: 3%;
    }
    .section-login {
      width: 924px;
    }
    .section-1 {
      width: 538px;
    }
    .section-2 {
      width: 476px;
    }
    .section-3 {
      width: 693px;
    }


  }

}

#payment-form {
  min-width: 400px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  border-radius: 12px;
  .title {
    text-align: center;
    padding-bottom: 20px;
    font-weight: 700;
  }
  #submit {
    margin-top: 20px;
    background: #5469d4;
    color: #fff;
    font-weight: 600;
    width: 100%;
  }
  #button-text {
    margin-top: 10px;
  }
  .loading-box {
    display: flex;
    justify-content: center;
    font-size: 25px;
  }
}

button {
  border: none;
}
input {
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 20px;
}

@media screen and (max-width: 950px) {
  .checkout {
    min-height: unset!important;
    .content-box {
      height: 48px;
      .match-box {
        width: 100vw;

        .check-title {
          font-size: 20px;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          bottom: unset;
        }
        .break-box, .cuise-img {
          display: none;
        }

      }
    }
    .main-content {
      width: 100vw;
      .card-form {
        width: 97vw;
      }
    }
  }
  
}

</style>

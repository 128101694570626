<template>
  <div class="home" :style="{ 'min-height': innerHeight }">
    <div class="content-box">
      <div class="match-box">
        <div class="left-box " >
          <div class="title-box">
            <div>
              <div class="title-tips">Feeling hungry?</div>
              <div class="title-desc">
                With just a few clicks, discover a variety of delicious meals available nearby!
              </div>
              <div class="title-desc-mobile">
                With just a few clicks, <br /> 
                discover a variety of delicious <br />
                meals available nearby!
              </div>
            </div>
            <div class="right-box-mobile">
              <img
                class="cuise-img"
                src="@/assets/images/img_home.png"
                alt="nofound"
              />
            </div>
          </div>
          <div class="search-box">
            <div class="top-box">
              <div
                :class="['type-btn', { 'btn-active': transType == ind+1 }]"
                v-for="(item, ind) in typeList"
                :key="item"
                @touchstart="transType = ind+1"
                @click="transType = ind+1"
              >
                <i :class="['iconfont', iconList[ind], { font26: ind == 2 }]"></i>
                <span>{{ item }}</span>
              </div>
            </div>
            <div class="bottom-box">
              <div class="input-box">
                <img src="@/assets/images/location.png" alt="location-img" />
                <input
                  type="text"
                  v-model="keywords"
                  @input="inputNow"
                  @keyup="inputNow"
                  :placeholder="$isUk?'Enter your postcode':'Enter your irecode'"
                  style="height: 100%; width: 100%;font-size: 16px;"
                />

                <!-- 搜索结果内容 -->
                <div :class="['expand-box', { 'expand-box-active': keywords }]">
                  <div
                    class="search-item"
                    v-for="(item, ind) in resultList"
                    :key="ind"
                    @click="normalSearch(item)"
                  >
                    <span>{{ item.content }}</span>
                    <i class="el-icon-loading" v-if="itemLoading == item.place_id && item.type=='location'"></i>
                    <img v-else src="@/assets/images/right.png" alt="right-arrow" />
                  </div>
                  <div class="load-box" v-if="loading">
                    <span class="loader"></span>
                  </div>
                  <div class="load-box" v-else-if="!loading && !resultList.length">
                    No data.
                  </div>
                </div>
              </div>
              <button class="search-btn" @click="inputNow">
                <img src="@/assets/images/search.png" alt="search-img" />
                <span class="search-text" >Find Food</span>
              </button>
            </div>
          </div>
        </div>
        <div class="right-box" >
          <img
            class="cuise-img"
            src="@/assets/images/img_home.png"
            alt="nofound"
          />
        </div>
      </div>
    </div>

    <!-- 获取定位之后的商家盒子 -->

    <div v-if="merchantList.length">
      <MerchantBox :merchantList="merchantList" :merchantListNoDe="merchantListNoDe"></MerchantBox>
    </div>

    <!-- categorybox -->
    <div :style="{ 'padding-top': (merchantList.length?'52px':'152px')}" class="block-box">
      <CategoryIndexVue></CategoryIndexVue>
    </div>

    <!-- app下载 -->
    <div>
      <DownloadBox></DownloadBox>
    </div>

    <!-- 回到顶部按钮 -->
    <el-backtop :bottom='128' :right='60'>
      <i class="el-icon-caret-top"></i>
    </el-backtop>

    <!-- app 下载提示弹窗 -->
    <DownloadAppModal v-model="showDownloadModal"></DownloadAppModal>

  </div>
</template>

<script>
import debounce from "@/utils/debounce.js";
import CategoryIndexVue from '@/components/IndexComps/CategoryIndex.vue';
import DownloadBox from '@/components/IndexComps/DownloadBox.vue';
import MerchantBox from "@/components/IndexComps/MerchantBox.vue";
import DownloadAppModal from "@/components/DownloadAppModal.vue";
import { postGateway } from '@/request/index.js';
export default {
  name: 'NotFound',
  metaInfo: {
    meta: ["All Eat "],
    title: "All Eat not found",
  },
  components: {
    CategoryIndexVue,
    DownloadBox,
    MerchantBox,
    DownloadAppModal
  },
  data() {
    return {
      transType: 1,
      showDownloadModal: false,
      typeList: ["Delivery", "Pick-up", "Dine-in"],
      iconList: ["icon-waimai", "icon-01_zitidian", "icon-tangshi"],
      keywords: "",
      resultList: [],
      loading: false,
      innerHeight: 800,
      itemLoading: null,
      searchForm: {
        addressName: '',
        keyword: '', 
        sort: '', 
        tastRating: '', 
        serviceRating: '', 
        type: '', // 默认没有选择类型
        discount: '',
        lng: '',
        lat: '',
        nowCuiseId: ''
      },
      pages: {
        pageNum: 1,
        pageSize: 12
      },
      total: 0,
      merchantList: [], // 商家列表
      merchantListNoDe: [],  // 没有运费的商家列表
    };
  },
  created() {
    // if(localStorage.getItem('searchAddress')) {
    //   this.$router.push({
    //     path: '/browse'
    //   })
    //   return
    // }
    this.getLocation(); // 首页获取定位信息
  },
  mounted() {
    this.innerHeight = window.innerHeight + "px";
  },
  methods: {
    // 获取浏览器定位信息
    getLocation() {
      this.getLocatLoading = true;
      if(navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position)=>{
          let { latitude, longitude } = position.coords
          this.searchForm.lng = longitude;
          this.searchForm.lat = latitude;

          this.getNearMerchant(true); // 获取商家列表
          console.log(position, 'position-获取浏览器定位成功');
        }, (err)=>{ 
          console.log('获取地址失败',err)
          // this.getNearMerchant(true); // 获取商家列表 TODO 上线去掉
          this.getLocatLoading = false;
        }); 
      }
    },
    // 获取附近商家
    getNearMerchant(refresh=false){
      const { keyword, sort, tastRating, serviceRating, discount, lat, lng, nowCuiseId } = this.searchForm;
      if(refresh) {
        this.loading = true;
        this.pages.pageNum = 1;
        // this.getOffmerchant(); // 获取优惠商家
      }
      console.log(lat, lng, '经纬度')
      postGateway({
        url: '/customerApp/index/queryMerchantByPage',
        method: 'GET',
        data: {
          keyword, sort, tastRating, serviceRating, discount,
          pageNum: this.pages.pageNum,
          pageSize: this.pages.pageSize,
          cuisineId: nowCuiseId || "",
          type: this.searchForm.type,
          selfLontitude: lng,
          selfLatitude: lat,
          // selfLontitude: -0.0642075,
          // selfLatitude: 51.3627294,
        }
      }).then((res) => {
          if(refresh) {
            this.merchantList = [];
          }
          if(res.code == 200){
            this.merchantList = this.merchantList.concat(res.data.list)
            this.total = res.data.total
          }
          setTimeout(() => {
            this.loading = false;
          }, 1000);
      }).catch(err => {
        this.loading = false;
        this.$message.error(err)
      })

      // 获取没有运费商家列表
      postGateway({
        url: '/customerApp/index/queryMerchantByPage',
        method: 'GET',
        data: {
          keyword, tastRating, serviceRating, discount,
          sort: 2,
          pageNum: this.pages.pageNum,
          pageSize: this.pages.pageSize,
          cuisineId: nowCuiseId || "",
          type: this.searchForm.type,
          selfLontitude: lng,
          selfLatitude: lat,
          // selfLontitude: -0.0642075,
          // selfLatitude: 51.3627294,
        }
      }).then((res) => {
          if(refresh) {
            this.merchantListNoDe = [];
          }
          if(res.code == 200){
            this.merchantListNoDe = this.merchantListNoDe.concat(res.data.list)
            this.total = res.data.total
          }
          setTimeout(() => {
            this.loading = false;
          }, 1000);
      }).catch(err => {
        this.loading = false;
        this.$message.error(err)
      })
    },
    // 监听输入框
    inputNow() {
      this.resultList = [];
      this.loading = true;
      debounce(() => {
        if (this.keywords.trim().length < 3) return;
        postGateway({
          method: "GET",
          url: "/customerApp/index/getPreKeyword",
          data: { keyword: this.keywords },
        })
          .then((res) => {
            if (res.data.length) {
              this.resultList = res.data.map((x) => {
                return {
                  content: x,
                  type: "content"
                };
              });
              if(this.resultList.length) {
                this.loading = false;
              }
            } else {
              this.googlePreSearch();
            }
          })
          .catch(() => {
            this.googlePreSearch();
          });
      }, 500);
    },
    googlePreSearch() {
      this.loading = true;
      if(this.$isUk) {
				postGateway({
					url: `/customerApp/addressioSearch/autocomplete`,
					method: 'GET',
					data: {
						keyword: this.keywords
					}
				}).then(res=>{
					console.log(res.data,'搜索地址会餐')
					this.loading = false;
					this.resultList = res.data.suggestions.map(x=>({
            ...x,
            content: x.description || x.address,
            place_id: x.id,
            type: "location",
          }));
				})
				.catch(err=>{
					this.loading = false;
					console.log(err,'搜索地址报错')
				})
				return
			}
      postGateway({
        method: "GET",
        url: "/customerWeb/googleSearch/autocomplete",
        data: { keyword: this.keywords },
      })
        .then((res) => {
          this.resultList = this.resultList.concat(
            res.data.predictions.map((x) => {
              return {
                ...x,
                content: x.description,
                type: "location",
              };
            })
          );
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    normalSearch(item) {
      if (item.type == "content") {
        this.$router.push({
          path: "/browse",
          query: {
            searchName: item.content,
            lng: "",
            lat: "",
            searchLoc: "",
          },
        });
      } else if (item.type == "location") {
        this.googleSearch(item);
      }
    },
    googleSearch(item) {
      this.itemLoading = item.place_id;

      if(this.$isUk) {
					postGateway({
						url: `/customerApp/addressioSearch/get`,
						method: 'GET',
						data: {
							id: item.id
						}
					}).then(res=>{
						console.log(res,'搜索地址会餐')
						let { town_or_city, longitude, latitude  } = res.data;
						this.$router.push({
              path: "/browse",
              query: {
                searchName: "",
                lng: longitude,
                lat: latitude,
                searchLoc: town_or_city,
                transType: this.transType,
              },
            });
            this.searchVisible = false;
					})
					.catch(()=>{
            this.searchVisible = false;
					})
					return
				}

      postGateway({
        method: "GET",
        url: "/customerWeb/googleSearch/placeGeoCode",
        data: {
          keyword: "",
          placeId: item.place_id,
        },
      }).then((res) => {
        this.itemLoading = '';
        if (res.code == 200) {
          this.$router.push({
            path: "/browse",
            query: {
              searchName: "",
              lng: res.data.lng,
              lat: res.data.lat,
              searchLoc: res.data.formattedAddress,
              transType: this.transType,
            },
          });
          this.searchVisible = false;
        } else {
          this.$message.error("Error happen...");
        }
      }).catch(err=>{
        this.itemLoading = '';
        console.log(err,'搜索报错')
      })
    },
    becomePart() {
      window.open("https://alleatapp.net/become-partner/");
    },
    aboutUs() {
      window.open("https://alleatapp.net/");
    },
    goLogin() {
      this.$router.replace({
        path: "/login",
      });
    },
    goHome() {
      this.$router.replace({
        path: "/",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  min-height: 100vh;
  // background: linear-gradient(
  //   to bottom,
  //   #fff 0,
  //   #fff 645px,
  //   #F4F4F4 645px,
  //   #F4F4F4 1750px,
  //   #fff 1750px,
  //   #fff 100%,
  // );
  background: #f5f5f5;
  .content-box {
    position: relative;
    width: 100%;
    height: 500px;
    background-image: url('../assets/images/notfoundBg.png');
    background-size: cover;
    background-position: center;
    box-sizing: border-box;

    .match-box {
      position: relative;
      display: flex;
      width: 1400px;
      .left-box {
        flex: 1;
        .title-box {
          position: relative;
          padding-top: 160px;
        }
      }
      .right-box {
        padding-left: 20px;
        width: 500px;
        padding-top: 10%;
        position: relative;
        .cuise-img {
          display: inline-block;
          // height: 562px;
          width: 90%;
        }
      }
    } 

    // tips
    .title-tips {
      font-size: 70px;
      color: #fff;
      font-weight: bold;
    }

    .title-desc {
      margin-top: 0px;
      font-size: 22px;
      color: #fff;
      letter-spacing: 0.5px;
    }
    .title-desc-mobile {
      display: none;
    }
    .right-box-mobile {
      display: none;
    }
  }

  .search-box {
    position: relative;
    z-index: 2;
    margin-top: 30px;
    height: 260px;
    width: 100%;
    background: #fff;
    padding: 24px 24px 0;
    box-sizing: border-box;
    // border-radius: 12px;
    border-radius: 30px;
    // box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 10px,
    //   rgba(139, 79, 201, 0.23) 0px 6px 6px;
    display: flex;
    flex-direction: column;
    .top-box {
      height: 60px;
      border-radius: 8px;
      color: #222;
      font-weight: bold;
      font-size: 15px;
      display: inline-flex;
      align-items: center;

      .type-btn {
        cursor: pointer;
        display: inline-flex;
        margin-right: 20px;
        height: 60px;
        // border-radius: 4px;
        border-radius: 30px;
        justify-content: center;
        width: 172px;
        align-items: center;
        background: #e9eaea;
        font-weight: bold;
        font-size: 24px;
        transition: all 0.2s ease-in-out 0s;

        i {
          color: #000000;
          margin-right: 8px;
          font-size: 26px;
        }
        .icon-tangshi {
          font-size: 20px;
        }
        &:hover {
          background: #cbcdcd;
          color: #fff;
        }
      }

      .btn-active {
        background: #09ca6a;
        color: #fff;

        &:hover {
          background: #049d51;
        }
        i {
          color: #fff;
          margin-right: 8px;
          font-size: 26px;
        }
      }
    }

    .bottom-box {
      flex: 1;
      display: flex;
      align-items: center;

      .input-box {
        height: 60px;
        background: #f5f5f5;
        padding: 0 20px;
        flex: 1;
        display: inline-flex;
        align-items: center;
        position: relative;
        border-radius: 30px;
        .expand-box {
          position: absolute;
          width: 100%;
          height: 0px;
          top: 60px;
          left: 0;
          background: rgba(255, 255, 255, 0.95);
          padding: 20px 0;
          height: 0px;
          opacity: 0;
          transition: all 0.8s ease-in-out 0s;
          display: none;

          .search-item {
            height: 50px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e9e9e9;
            padding: 0 20px;
            cursor: pointer;

            &:hover {
              background-color: rgba(236, 230, 230, 0.95);
            }

            span {
              flex: 1;
              font-size: 20px;
            }
            .el-icon-loading {
              margin-right: 15px;
            }

            img {
              height: 18px;
              width: 25px;
            }
          }
        }

        .load-box {
          height: 100%;
          width: 100%;
          display: flex;
          // align-items: center;
          padding-top: 6px;
          justify-content: center;
          font-size: 24px;
          .loader {
            width: 48px;
            height: 48px;
            border: 5px solid #fff;
            border-bottom-color: #ff3d00;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
          }

          @keyframes rotation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }

        .expand-box-active {
          height: 300px;
          opacity: 1;
          display: block;
          overflow-y: scroll;
        }

        input {
          background: transparent;
          font-weight: normal;
        }

        img {
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }
      }

      .search-btn {
        height: 60px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        // border-radius: 6px;
        border-radius: 30px;
        background: #09ca6a;
        color: #fff;
        font-weight: bold;
        margin-left: 16px;
        padding: 0 40px;
        font-size: 22px;
        cursor: pointer;
        transition: all 0.2s ease-in-out 0s;

        img {
          height: 22px;
          width: 22px;
          margin-right: 10px;
        }
        &:hover {
          background: #049d51;
          color: #e8e4e4;
        }
      }
    }
  }
}

// 处理屏幕兼容性 < 1600px

@media screen and (min-width:1300px) and (max-width:1600px) {
  
}

// 处理兼容 移动端
@media screen and (max-width: 950px) {
  .home {
    .content-box {
      height: 201px;
      background-image: url('../assets/images/mobile_banner.png');
      .match-box {
        width: 100%;
        padding: 0 25px 10px;
        // overflow: hidden;
        .left-box {
          .title-box {
            position: relative;
            padding-top: 35px;
            display: flex;
            .title-tips {
              font-size: 30px;
              color: #fff;
              font-weight: bold;
            }
            .title-desc {
              display: none;
            }
            .title-desc-mobile {
              display: block;
              font-size: 13px;
              color: #fff;
              letter-spacing: 0.5px;
            }
            .right-box-mobile {
              display: block;
              position: relative;
              img {
                display: inline-block;
                position: absolute;
                display: none;
                top: -30px;
                height: 150px;
                width: 150px;
              }
            }
          }
          .search-box {
            zoom: 1;
            margin-top: 70px;
            height: 150px;
            padding: 10px 20px;
            z-index: 2;
            border-radius: 8px;
            .top-box {
              height: 65px;
              i {
                display: none;
              }
              .type-btn {
                font-size: 18px;
                width: unset;
                height: 36px;
                margin-right: 10px;
                flex: 1;
                &:nth-last-child(1) {
                  margin-right: 0;
                }
              }
            }
            .input-box {
              padding: 0 8px;
              height: 46px;
              img {
                // zoom: 1.4;
              }
              input {
                font-size: 18px;
                height: 46px;
              }
              .expand-box {
                padding-top: 0;
                height: 500px;
                background: #fff;
                z-index: 100;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                width: 76vw;
                left: 0px;
                background-color: #fff;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                .search-item {
                  height: unset;
                  padding: 10px 5px 10px 20px;
                  font-size: 18px;
                  img {
                    height: unset;
                    width: 13px;
                    margin-left: 10px;
                  }
                  span {
                    font-size: 16px;
                  }
                }
              }
            }
            .search-btn {
              // width: 40px;
              height: 46px;
              padding: 0 20px;
              img {
                margin-right: 0;
                height: 18px;
                width: 18px;
              }
              .search-text {
                display: none;
              }
            }
          }
        }
        .right-box {
          display: none;
        }
        
      }
    }
    .block-box {
      padding-top: 152px!important;
    }
  }
}

// 处理兼容 移动端
@media screen and (max-width: 400px) {
  .home {
    .content-box {
      .match-box {
        // overflow: hidden;
        .left-box {
          .search-box {
            margin-top: 70px;
          }
        }
        
      }
    }
    .block-box {
      padding-top: 152px!important;
    }
  }
}



button {
  border: none;
}
input {
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 24px;
}
</style>

<template>
    <div class="cloud-box">
        <div id="cloudflare-block" class="cf-turnstile" data-sitekey="0x4AAAAAAAcQl-bV-nkc3JPX" data-callback="javascriptCallback"></div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'CloudFlareVerify',
    data() {
        return {
            verifyToken: '',
            turnstileReady: false
        }
    },
    mounted() {
        this.turnstileReady = false;
        this.getCloudflareVerify();
        console.log('我不会执行了两次 mounted 吧')
    },
    methods: {
        reSetTurnSite() {
            this.verifyToken = '';
            turnstile.reset();
        },
        getCloudflareVerify() {
            // if using synchronous loading, will be called once the DOM is ready

            let that = this;
            
            turnstile.ready(function (info) {
                console.log('rederDoneInfo',info, that.turnstileReady)
                if(that.turnstileReady) {
                    return
                }
                that.turnstileReady = true;
                turnstile.render('#cloudflare-block', {
                    sitekey: '0x4AAAAAAAcQl-bV-nkc3JPX',
                    callback: function (token) {
                        // console.log(`Challenge Success ${token}`);
                        that.verifyToken = token;
                        that.$emit('verifyDone', token);
                    },
                });
            });

        },
        getToken() {
            if(!this.verifyToken) {
                this.$message({
                    message: 'Please pass the verification first',
                    type: 'warning',
                    offset: 50
                })
                return
            }
            return this.verifyToken;
        }
    }
}
</script>

<style lang="scss" >
.cloud-box {
    padding-left: 45px;
}
@media screen and (min-width: 950px) {
    .cloud-box{
        padding-left: 156px;
    }
}
</style>
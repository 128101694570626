var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"verify-img-out"},[_c('div',{staticClass:"verify-img-panel",style:({
                width: _vm.setSize.imgWidth,
                height: _vm.setSize.imgHeight,
                'background-size':
                    _vm.setSize.imgWidth + ' ' + _vm.setSize.imgHeight,
                'margin-bottom': _vm.vSpace + 'px',
            })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRefresh),expression:"showRefresh"}],staticClass:"verify-refresh",staticStyle:{"z-index":"3"},on:{"click":_vm.refresh}},[_c('i',{staticClass:"iconfont icon-refresh"})]),_c('img',{ref:"canvas",staticStyle:{"width":"100%","height":"100%","display":"block"},attrs:{"src":_vm.pointBackImgBase
                        ? 'data:image/png;base64,' + _vm.pointBackImgBase
                        : _vm.defaultImg,"alt":""},on:{"click":function($event){_vm.bindingClick ? _vm.canvasClick($event) : undefined}}}),_vm._l((_vm.tempPoints),function(tempPoint,index){return _c('div',{key:index,staticClass:"point-area",style:({
                    'background-color': '#1abd6c',
                    color: '#fff',
                    'z-index': 9999,
                    width: '20px',
                    height: '20px',
                    'text-align': 'center',
                    'line-height': '20px',
                    'border-radius': '50%',
                    position: 'absolute',
                    top: parseInt(tempPoint.y - 10) + 'px',
                    left: parseInt(tempPoint.x - 10) + 'px',
                })},[_vm._v(" "+_vm._s(index + 1)+" ")])})],2)]),_c('div',{staticClass:"verify-bar-area",style:({
            width: _vm.setSize.imgWidth,
            color: this.barAreaColor,
            'border-color': this.barAreaBorderColor,
            'line-height': this.barSize.height,
            'text-align': 'center'
        })},[_c('span',{staticClass:"verify-msg"},[_vm._v(_vm._s(_vm.text))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <div class="page-loading" v-if="value">
            <div class="img-box">
                <!-- <img src="@/assets/page_loading.gif" alt="pageLoading"> -->
                <i class="el-icon-loading" ></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageLoading',
        model: {
            prop: "value",
            handle: "input"
        },
        props: {
            value: Boolean
        },
        data() {
            return {

            }
        },
        watch: {
            'value': function(val) {
                if(val) {
                    this.setUnScroll();
                } else {
                    this.setScroll();
                }
            }
        },
        mounted() {
            if(this.value) {
                this.setUnScroll();
            }
        },
        methods: {
            setUnScroll() {
                this.$nextTick(()=>{
                    document.querySelector('body').style.overflow = 'hidden';
                })
            },
            setScroll() {
                document.querySelector('body').style.overflow = 'unset';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-loading {
        position: absolute;
        z-index: 2000;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.7);
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        
        .img-box {
            padding-top: 30vh;
            img {
                width: 500px;
                border-radius: 10px;
            }
            .el-icon-loading {
                font-size: 50px;
            }
        }
    }
    @media screen and (max-width: 950px) {
        .page-loading {
           .img-box {
                img {
                    width:  80vw;
                    height: unset;
                }
           }
        }
    }
</style>
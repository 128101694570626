<template>
	<div class="customer-btn" @click="goLive" v-if="isMobile">
		<img src="@assets/images/live_chat.png" mode="widthFix" />
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		computed: {
			isMobile() {
				return window.innerWidth < 950
			}
		},
		methods: {
			goLive() {
				window.Tawk_API.showWidget()  //直接隐藏
				window.Tawk_API.maximize()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.customer-btn {
		z-index: 100;
		position: fixed;
		left: 5px;
		bottom: 20%;
		height: 65px;
		width: 65px;
		font-size: 10px;
		border-radius: 50%;
		background-color: #03a84e;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		
		img {
			height: 40px;
			width: 40px;
		}
	}
	@media screen and (max-width: 950px) {
		.customer-btn {
			z-index: 100;
			position: fixed;
			left: 5px;
			bottom: 20%;
			height: 45px;
			width: 45px;
			font-size: 10px;
			border-radius: 50%;
			background-color: #03a84e;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			
			img {
				height: 30px;
				width: 30px;
			}
		}
	}

</style>
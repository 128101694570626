<template>
  <div>
    <div v-if="showSkiLoading" class="skiMask" >
        <img src="@/assets/images/skiLogo.png" alt="AllEat">
        <div class="desc">Loading ...</div>
    </div>
  </div>
</template>

<script>
import { postGateway } from '@/request';
export default {
  components: {},
  data() {
    return {
        showSkiLoading: false
    };
  },
  mounted() {
    this.judgeScreenH5()
  },

  methods: {
    getMerchantId(slug) {
        console.log(slug,'请求参数的 slug')
        return postGateway({
            url: "/customerWeb/merchantDetail/getMerchantBySlug",
            method: "GET",
            data: {
                slug
            }
        })
    },
    // 判断当前屏幕宽度 小于等于 780px 跳转h5
    judgeScreenH5(pa = "") {
      let width = window.screen.width;

      let path = pa.trim();

      console.log(width, "设备宽度问题处理", path, Boolean(path));

      // if (width < 780) { // 需要跳转到 h5
      //   this.showSkiLoading = true
      //   let protocol = window.location.protocol;
      //   let host = "www.alleatapp.com";
      //   let pathname = window.location.pathname;

      //   let disableList = ['the-money-tree', 'aha-wok', 'new-world-rm17', 'dragon-inn-restaurant', 'jp-house-chinese-restaurant','all-eat-test', 
      //     'china-town-chinese-takeaway', 'lucky-house-ng4', 'wok-u-like-potters-bar', 'happy-munchy-box-fk1', 'wing-wah'];
      //   if(pathname.includes('menu-') && disableList.includes(window.location.pathname.split('menu-')[1]) || localStorage.getItem('noJump')) {
      //     console.log('不跳转了')
      //     localStorage.setItem('noJump', true)
      //     this.showSkiLoading = false
      //     return false
      //   }

      //   if(pathname.includes('menu-')) {  // 需要跳转到 商家菜单页面
      //     let slug = window.location.pathname.split('menu-')[1]
      //     if(slug.includes('/')) {
      //       slug = slug.replace('/','');
      //     }
      //     this.getMerchantId(slug).then(res=>{
      //       console.log(res.data,'获取商家id回参')
      //       window.location.href = protocol + "//" + host + `/alleat-h5/#/pages/shopPage/shopDetails?id=${res.data*1}&timestamp=${new Date().valueOf()}`;
      //     }).catch(()=>{
      //       this.$message.warning('Error Happen')
      //       window.location.href = protocol + "//" + host + `/alleat-h5/#/?timestamp=${new Date().valueOf()}`
      //     })
      //   } else if(path.includes('browse') || pathname.includes('browse') ) {
      //     window.location.href = protocol + "//" + host + `/alleat-h5/#/?timestamp=${new Date().valueOf()}`;
      //   } else if(path.includes('mySecond') || pathname.includes('my') ) {
      //     window.location.href = protocol + "//" + host + `/alleat-h5/#/pages/orders/orders?timestamp=${new Date().valueOf()}`;
      //   } else {
      //     window.location.href = protocol + "//" + host + "/alleat-h5/#" + (path ? path : pathname)+`?timestamp=${new Date().valueOf()}`;
      //   }

      //   console.log(
      //     "是h5应该跳转",
      //     protocol + "//" + host + "/alleat-h5/#" + (path ? path : pathname)
      //   );

        
      // } else {
      //   this.showSkiLoading = false
      // }
      return width < 780;
    }
  }
};
</script>

<style lang="scss" scoped>
  .skiMask {
    height:100%;
    width:100%;
    position: absolute;
    top:0;
    left:0;
    z-index: 9999;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      height: 180px;
      width: 180px;
      animation: jump .5s ease-in-out infinite alternate;
    }

    .desc {
      margin-top: 30px;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 1px;
    }

    @keyframes jump {
        from { 
          transform: translateY(-20px);
        }
        to { 
          transform: translateY(0px);
        }
    }

  }
</style>

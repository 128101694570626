import { postGateway } from "@/request";
import router from "@/router/index";

/**
 * 支付方式 支持 type
 * paypal_v2 paypal - 原生
 * paypal_pro paypal Advance -自定义
 * cod - 现金支付
 * stp - 支付
 *
 * param - orderId, amount
 */

export function payment(paymentType, param) {
    if (paymentType == "cod") {
        cashPay(param);
        return;
    }

    if (paymentType == "trustPay") {
        trustPayment(param);
        return;
    }

    if (paymentType == "guavaPay") {
        guavaPayment(param);
        return;
    }

    // Stripe 支付
    // 现金支付不需要走支付流程 下单之后跳转下单成功页面
    if (paymentType == "stp") {
        // 跳转 已经下单
        stripePay(param);
        return;
    }

    // card 支付 paypal_advance
    if (paymentType == "paypal_pro") {
        paypalAdvance(param);
        return;
    }

    // viva 支付 viva wallet
    if (paymentType == "viva") {
        vivaPay(param);
        return;
    }

    // 默认走 paypal
    paypalPay(param);
}

// Stripe 支付 跳转stripe 支付页面
function stripePay(param) {
    router.push({
        path: `/stripe?orderId=${param.orderId}&amount=${param.amount}&merchantId=${param.merchantId}`,
    });
}

// paypalAdvance 支付
function paypalAdvance(param) {
    // 跳转paypal card 之前先保存一份数据到本地
    localStorage.setItem(
        "newCardPayment",
        JSON.stringify({
            param,
        })
    );
    router.push({
        path: `/paypalAdvance?orderId=${param.orderId}&amount=${param.amount}&merchantId=${param.merchantId}`,
    });
}

// viva wallet 支付
function vivaPay(param) {
    router.push({
        path: `/vivaWallet?orderId=${param.orderId}&amount=${param.amount}&merchantId=${param.merchantId}`,
    });
}

// trust paymetn 支付
function trustPayment(param) {
    router.push({
        path: `/trustPayment?orderId=${param.orderId}&amount=${param.amount}&merchantId=${param.merchantId}`,
    });
}

//  支付
function guavaPayment(param) {
    // router.push({
    //     path: `/guavaPayment?orderId=${param.orderId}&amount=${param.amount}&merchantId=${param.merchantId}`
    // })
    postGateway({
        url: `/guavaPay/createOrder`,
        method: "GET",
        data: {
            oId: param.orderId,
        },
    })
        .then((res) => {
            location.href = res.data?.paymentPageUrl;
        })
        .catch(function (error) {
            throw error
        });
}

// 现金支付
function cashPay(param) {
    router.push({
        path: `/orderSuccess?orderId=${param.orderId}&type=orderDone&merchantId=${param.merchantId}&orderSuccess=1`,
    });
}

// paypal 支付
function paypalPay(param) {
    // 老版通过后端返回的url 跳转支付, 没办法自定义回调地址
    router.push({
        path: `/paypal?orderId=${param.orderId}&amount=${param.amount}&merchantId=${param.merchantId}`,
    });
    // postGateway({
    //     method: 'GET',
    //     url: '/paypal/create_payment',
    //     data: {
    //         oId: param.orderId,
    //     }
    // }).then(res => {
    //     window.location = res.data
    // })
    // .catch(err => {
    //     console.log(err, '支付报错')
    // })
}

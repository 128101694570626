import { postGateway } from "@/request";

export function setCart(merchantId, list) {
    if(list.length) {
        localStorage.setItem("cart-" + merchantId, JSON.stringify(list));
    }
}

export function removeCart(merchantId) {
    localStorage.removeItem("cart-" + merchantId);

    if (!localStorage.getItem('clientId')) {
        return
    }
    let param = {
        merchantId: merchantId * 1,
        clientId: localStorage.getItem('clientId')
    }
    postGateway({
            url: '/customerApp/cart/cleanCart',
            data: param
        })
        .then(res => {
            console.log(res, '清除购物车成功')
        })
        .catch(err => {
            console.log(err, '清除购物车失败')
        })
}

export function getCart(merchantId) {
    let result = [];
    if (localStorage.getItem("cart-" + merchantId)) {
        result = JSON.parse(localStorage.getItem("cart-" + merchantId))
    }
    return result
}
<template>
  <div class="download-box" >
    <div class="download-img">
      <img src="@/assets/index/app_download_bg.png" alt="download_app">
      <div class="click-box" >
        <div class="btn">
          <a href="https://play.google.com/store/apps/details?id=io.dcloud.uniAllEat" target="_blank"></a>
        </div>
        <div class="btn">
          <a href="http://itunes.apple.com/cn/app/id1603420846?mt=8" target="_blank"></a>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div class="download-img-mobile">
      <img src="@/assets/index/download_index_mobile.png" alt="download_app">
      <div class="click-box" >
        <div class="btn">
          <a href="https://play.google.com/store/apps/details?id=io.dcloud.uniAllEat" target="_blank"></a>
        </div>
        <div class="btn">
          <a href="http://itunes.apple.com/cn/app/id1603420846?mt=8" target="_blank"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
  .download-box {
    min-height: 329px;
    position: relative;

    .download-img {
      .click-box {
        top: 58%;
        position: absolute;
        right: 22%;
        width: 23%;
        height: 30%;
        z-index: 10;
        display: flex;

        .btn {
          flex: 1;
          cursor: pointer;
          a {
            display: block;
            height: 100%;
            width: 100%;
          }
        }

      }

      img {
        width: 100%;
      }
    }

    .download-img-mobile {
      display: none;
      width: 100%;
      .click-box {
        bottom: 20px;
        position: absolute;
        left: 0%;
        width: 100%;
        height: 100px;
        z-index: 10;
        display: flex;

        .btn {
          flex: 1;
          cursor: pointer;
          a {
            display: block;
            height: 100%;
            width: 100%;
          }
        }
      }
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 950px) {
    .download-box {
      min-height: auto;
      background: #fff;
      .download-img-mobile {
        display: block;
        width: 100%;
        padding-right: 10px;

        img {
          width: 100%;
        }
      }
    }
    .download-img {
      display: none;
    }
  }
</style>

<template>
    <div class="my-order">
        <div class="order-box">
            <!-- <h2 class="title">My Orders</h2> -->
            <div style="height: 200px;" v-if="loading && !orderList.length" ></div>
            <ul v-if="!loading || orderList.length">
                <li v-for="(item) in orderList" :key="item.bookingId" >
                    <div :class="['order-card',{ 'card-color': false }]">
                        <div class="card-left flex-col-center ">
                            <img :src="item.photo" alt="">
                            <div :class="['status-tag', ('status-'+item.status)]" >{{ item.status }}</div>
                        </div>
                        <div class="card-center">
                            <div class="name">{{ item.merchantName }}</div>
                            <div class="desc-mobile" >
                                <div class="line" >
                                    <span>Booking Name:</span>
                                    <span style="margin-left:5px;">{{ item.bookingName }}</span>
                                </div>
                                <div class="line" >
                                    <span>Order Time:</span>
                                    <span style="margin-left:5px;">{{ item.dateBooking +  ' ' + item.bookingTime }}</span>
                                </div>
                                <div class="line" >
                                    <span>Number of dinners:</span>
                                    <span style="margin-left:5px;">{{ item.numberGuest }}</span>
                                </div>
                            </div>
                            <div class="rate-box">
                                <el-rate v-model="nowRate" :colors="['#aa0bc0','#aa0bc0','#aa0bc0']" :disabled="true"></el-rate>
                                <span class="review-btn" >Write review</span>
                                <img class="sumb-up img-btn" src="@/assets/icon/sumb_up.png" alt="">
                                <img class="sumb-down img-btn"  src="@/assets/icon/sumb_down.png" alt="">
                            </div>
                        </div>
                        <div class="card-right flex-col-center " >
                            <div class="btn" @click="goMerchant(item)">Visit Store</div>
                        </div>
                    </div>
                    <div class="card-bottom" >
                        <div class="btn" @click="goMerchant(item)">Visit Store</div>
                    </div>
                    
                </li>
            </ul>
            <div class="more-btn" v-if="!loading && total>this.pages.pageSize*this.pages.pageNum" @click="getMoreOrder">Show More</div>
            <div class="loading-box" v-if="loading">
                <i class="el-icon-loading"></i>
            </div>
            <div class="empty-block" v-if="!loading && !orderList.length">
                <img src="@/assets/menu/empty_cart3.png" alt="empty">
            </div>
        </div>
    </div>
</template>

<script>
import { postGateway } from '@/request';
import { payment } from '@/utils/payment';
export default {
    name: 'Booking',
    data() {
        return {
            pages: {
                pageNum: 1,
                pageSize: 20,
            },
            keyword: '',
            total: 0,
            orderList: [],
            loading: false,
            nowRate: 5
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        goMerchant(item) {
            // this.$message.info('Developing')
           
            this.$router.push({
                path: '/menu-' + item.restaurantSlug
            })
        },
        paynow(item) {
            console.log(item, 'item')
            let pa = {
                orderId: item.bookingId,
                amount: item.price,
                merchantId: item.merchantId
            }
            payment(item.paymentType, pa)
        },
        getMoreOrder() {
            if(this.pages.pageSize==3) {
                this.pages.pageSize = 10;
                this.getData(true);
            } else {
                this.pages.pageNum ++;
                this.getData();
            }
        },
        getData(refresh=false) {
            this.loading = true;
            postGateway({
                url: '/merchant/bookingTable/queryBookingByClient',
                method: 'GET',
                data: {
                    clientId: localStorage.getItem('clientId'),
                    ...this.pages,
                    keyword: this.keyword
                }
            }).then(res=>{
                if(refresh) {
                    this.orderList = [];
                }
                this.orderList = this.orderList.concat(res.data);
                this.loading = false;
                this.total = res.total;
            })
            .catch(err=>{
                this.loading = false;
                this.$message.info(err.msg)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #09CA6A;
.my-order {
    background: #fafafa;
    .order-box {
        margin-top: 30px;
        min-height: calc(100vh - 260px);
        overflow-y: auto;
        padding: 20px 0 20px;
        // border-radius: 6px;
        border-radius: 30px;
        box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
        .more-btn {
            margin: 60px auto;
            height: 52px;
            width: 240px;
            border-radius: 6px;
            background-color: $mainColor;
            color: #fff;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            transition: background-color 0.2s ease-in;
            box-shadow: 0px 5px 10px #8F9698;
            cursor: pointer;
            &:hover {
                background-color: #AA0BC0;
            }
        }
        .title {
            color: $mainColor;
            font-weight: bold;
            margin: 0 0 20px;
            padding: 30px 40px 0;
            padding-bottom: 10px;
            font-size: 22px;
            position: sticky;
            top: 0;
            z-index: 50;
        }
        .loading-box {
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 40px;
                color: #FF8000;
                font-weight: 600;
            }
        }
        ul {
            padding-left: 40px;
        }
        .order-card {
            display: flex;
            white-space: wrap;
            word-break: normal;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba($color: #a7a5a5, $alpha: 0.5);
            margin-bottom: 10px;
            .card-left {
                padding: 0;
                margin-right: 20px;
                img {
                    height: 110px;
                    width: 110px;
                    // border-radius: 8px;
                    border-radius: 30px;
                    background: #a7a5a5;
                }
                .status-tag {
                    margin-top: 8px;
                    // border-radius: 4px;
                    // padding: 6px 0;
                    border-radius: 30px;
                    padding:3px 0px;
                    width: 110px;
                    text-align: center;
                    color: #fff;
                    font-weight: 600;
                    font-size: 16px;
                    background-color: #cf1e07;
                    box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
                }
                .status-unpaid {
                    background-color: #FF8000;
                }
                .status-cancel {
                    background-color: #cf1e07;
                }
                .status-accepted {
                    background-color: $greenColor;
                }
                .status-initial_order {
                    background-color: #8F9698;
                }
            }
            .card-center {
                flex: 1;
                .name {
                    padding-top: 10px;
                    font-size: 20px;
                    font-weight: 700;
                    color: #000;
                    margin-bottom: 6px;
                }
                .desc-mobile {
                    display: none;
                }
                .desc {
                    color: #000;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    font-size: 14px;

                    .point {
                        display: inline-block;
                        height: 4px;
                        width: 4px;
                        border-radius: 2px;
                        background-color: #000;
                        margin-left: 8px;
                    }
                    .under-line {
                        position: relative;
                        bottom: 1px;
                        font-size: 17px;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
                .rate-box {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    :deep(.el-rate__icon) {
                        font-size: 22px;
                        margin-right: -2px;
                    }

                    .review-btn {
                        color: #8F9698;
                        margin-left: 10px;
                        font-size: 18px;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    .img-btn {
                        height: 24px;
                        width: 24px;
                        margin-left: 10px;
                        cursor: pointer;
                        transition: all 0.2s ease-in-out;
                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }
            }
            .card-right {
                padding-right: 40px;
                .btn {
                    height: 42px;
                    width: 180px;
                    background-color: $greenColor;
                    font-weight: bold;
                    color: #fff;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 15px;
                    // box-shadow: 0px 5px 10px #8F9698;
                    // border-radius: 8px;
                    border-radius: 30px;
                    cursor: pointer;
                    transition: all .2s ease;
                    &:hover {
                        background-color: #08b25d;
                    }
                }
                .btn-again {
                    background: #AA0BC0;
                    &:hover {
                        background-color: #8d0d9e;
                    }
                }
                .btn-pay {
                    background: #FF8000;
                    &:hover {
                        background-color: #c36507;
                    }
                }
            }
        }
        .card-bottom {
            display: none;
        }
        .empty-block {
            height: 600px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 400px;
                border-radius: 12px;
            }
        }
        .card-color {
            background: #fafafa;
        }
    }
}
@media screen and (max-width: 950px) {
    .my-order {
        overflow: unset;
        padding: 0;
        background: unset;
        .order-box {
            margin-top: 10px;
            box-shadow: unset;
            padding-top: 10px;
            ul {
                padding-left: 0;
                li:nth-child(even) {
                    background-color: #fff;
                }
                li {
                    // margin: 0 15px 10px;
                    padding: 10px;
                    border-radius: 4px;
                    // background: #fff;
                    // border: 1px solid rgba($color: #a7a5a5, $alpha: 0.5);

                    .order-card {
                        border-bottom: 0px;
                        margin-bottom: 0px;
                        .card-left {
                            justify-content: flex-start;
                            align-items: flex-start;
                            img {
                                height: 80px;
                                width: 80px;
                                // border-radius: 4px;
                                border-radius: 30px;
                            }
                            .status-tag {
                                width: 80px;
                                font-size: 12px;
                            }
                        }
                        .card-center {
                            .name {
                                padding-top: 0;
                                font-size: 16px;
                            }
                            .desc {
                                display: none;
                            }
                            .desc-mobile {
                                display: flex;
                                flex-direction: column;
                                .line {
                                    display: flex;
                                    font-size: 12px;
                                    padding-right: 10px;
                                    color: #000;
                                    margin-top: 2px;
                                    text-overflow: -o-ellipsis-lastline;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    -webkit-line-clamp: 1;  // 控制展示几行
                                    line-clamp: 1;  // 控制展示几行
                                    -webkit-box-orient: vertical;
                                }
                                .price {
                                    font-size: 16px;
                                    font-weight: bold;
                                    margin-top: 10px;
                                }
                            }
                            .rate-box {
                                display: none;
                            }
                        }
                        .card-right {
                            display: none;
                        }
                    }
                    
                    .card-bottom {
                        display: flex;
                        justify-content: flex-end;
                        .btn {
                            height: 32px;
                            padding: 0 12px;
                            background-color: $greenColor;
                            font-weight: bold;
                            color: #fff;
                            font-size: 14px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-bottom: 0px;
                            border-radius: 16px;
                            margin-right: 6px;
                            cursor: pointer;
                            transition: all .2s ease;
                            &:hover {
                                background-color: #08b25d;
                            }
                        }
                        .btn-again {
                            background: #AA0BC0;
                            &:hover {
                                background-color: #8d0d9e;
                            }
                        }
                        .btn-pay {
                            background: #FF8000;
                            &:hover {
                                background-color: #c36507;
                            }
                        }
                    }
                }
            }
            .more-btn {
                height: 42px;
                width: 200px;
                margin: 20px auto;
            }
        }
    }
}
</style>
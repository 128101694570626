<template>
    <div class="faq-box">
        <div class="loading-box" v-if="loading">
            <i class="el-icon-loading"  ></i>
        </div>
        <div v-html="info"></div>
    </div>
</template>

<script>
import { postGateway } from '@/request';
    export default {
        name: 'Faqs',
        data() {
            return {
                info: '',
                loading: true,
            }
        },
        methods: {
            getFaqs() {
                postGateway({
                    url: '/mtCustomPage/getFaq',
                    method: 'GET',
                    data: {
                        pageName: 'FAQ',
                    }
                }).then(res=>{
                    console.log(res, '获取faqs内容')
                    this.info = res.data.content;
                    this.loading = false;
                }).catch(()=>{
                    this.$message.info('Error')
                    this.loading = false;
                })
            },
        },
        created() {
            this.getFaqs();
        }
    }
</script>

<style lang="scss" scoped>
    .faq-box {
        max-width: 1250px;
        margin: 0 auto;
        padding: 0 15px;
        .loading-box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40vh;
            i {
                font-size: 20px;
            }
        }
    }
</style>
<template>
  <div class="tag-bar">
    <div class="tag-item" v-for="(item,ind) in value" :key="ind">
      <span>{{ item.name }}</span>
      <i class="el-icon-close" v-if="item.key != 'sort'" @click="deleteVal(item)"></i>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    deleteVal(item) {
      if(item.key.includes('cuisine')) {
        let list = this.value.filter(x=>x.key!=item.key)
        this.$emit('input', list)
      }
      this.$emit('clearTag', item.key)
    },
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #26d07c;
$yellowColor: #ffa132;
.tag-bar {
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-family: "Source Sans Pro", sans-serif;

  .tag-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    padding: 0 10px 0 20px;
    background: $mainColor;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    margin-right: 15px;

    span {
      margin-right: 10px;
    }

    .el-icon-close {
      font-size: 20px;
      color: #000;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
</style>

<template>
    <div class="my-order">
        <div class="order-box">
            <div style="height: 200px;" v-if="loading && !merchantList.length" ></div>
            <ul v-if="!loading || merchantList.length">
                <li v-for="(item) in merchantList" :key="item.merchantId" :class="['order-card',{ 'card-color': false }]">
                    <div class="card-left flex-col-center ">
                        <img :src="item.restaurantImg" alt="">
                    </div>
                    <div class="card-center">
                        <div class="name">{{ item.restaurantName }}</div>
                        <!-- <div class="desc" >{{ item.restaurantSlug }}</div> -->
                        <div class="address-box">
                            <!-- <div class="el-icon-location" style="color: blue; width:30px;height: 30px;"></div> -->
                            <i class="el-icon-location"></i>
                            <div class="address">address</div>
                        </div>
                        <div class="rate-box">
                            <el-rate v-model="item.tasteRating" :disabled="true"></el-rate>
                            <div class="rate-content">{{ item.tasteRating }} (5)</div> 
                        </div>
                    </div>
                    <div class="card-right flex-col-center " >
                        <div class="btn" @click="goMerchant(item)">Visit Store</div>
                        <div class="btn btn-again" @click="removeFavorite(item)">
                            <img src="@/assets/icon/whiteHeart.png" alt="">
                              Remove from Wishlist
                        </div>
                    </div>
                </li>
            </ul>
            <div class="more-btn" v-if="!loading && total>this.pages.pageSize*this.pages.pageNum" @click="moreFav">Show More</div>
            <div class="loading-box" v-if="loading">
                <i class="el-icon-loading"></i>
            </div>
            <div class="empty-block" v-if="!loading && !merchantList.length">
                <img src="@/assets/menu/empty_cart3.png" alt="empty">
            </div>
        </div>
    </div>
</template>

<script>
import { postGateway } from '@/request';
export default {
    data() {
        return {
            pages: {
                pageNum: 1,
                pageSize: 2,
            },
            keyword: '',
            total: 0,
            merchantList: [],
            loading: false,
            nowRate: 5
        }
    },
    mounted() {
        this.getData();
        console.log(this.pages.pageSize*this.pages.pageNum);
        console.log(this.total,'jilushu')
    },
    methods: {
        goMerchant(item) {
            this.$router.push({
                path: '/menu-' + item.restaurantSlug
            })
        },
        removeFavorite(item) {
            let clientId = localStorage.getItem('clientId');
            if(!clientId) {
                this.$message.info('You Need Login First.')
                return
            }
            this.loading = true;
            postGateway({
                url: '/customer/myfavorite/delete',
                data: {
                    merchantId: item.merchantId * 1,
                    clientId: clientId * 1
                }
            }).then(()=>{
                this.$message.info('Success!')
                this.merchantList = [];
                this.getData();
            }).catch()
        },
        getData(refresh=false) {
            this.loading = true;
            if(refresh) {
                this.pages.pageSize = 10;
                this.pages.pageNum = 1;
            }
            postGateway({
                url: '/customer/myfavorite/findByPage',
                method: 'GET',
                data: {
                    // clientId: localStorage.getItem('clientId'),
                    clientId:210282,
                    ...this.pages,
                    // keyword: this.keyword
                }
            }).then(res=>{
                if(refresh) {
                    this.merchantList = [];
                }
                this.merchantList = this.merchantList.concat(res.data);
                this.total = res.total;
                this.loading = false;
            })
            .catch(err=>{
                this.loading = false;
                this.$message.info(err.msg)
            })
        },
        moreFav() {
            if(this.pages.pageSize==3) {
                this.pages.pageSize = 10;
                this.getData(true);
            } else {
                this.pages.pageNum ++;
                this.getData();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #09CA6A;
.my-order {
    background: #fafafa;
    border-radius: 30px;
    .order-box {
        margin-top: 30px;
        min-height: calc(100vh - 260px);
        overflow-y: auto;
        padding: 30px 0 20px;
        // border-radius: 6px;
        border-radius: 30px;
        // border-bottom: 1px solid rgba($color: #a7a5a5, $alpha: 0.5);
        .title {
            color: $mainColor;
            font-weight: bold;
            margin: 0 0 20px;
            padding: 30px 40px 0;
            padding-bottom: 10px;
            font-size: 22px;
            position: sticky;
            top: 0;
            z-index: 50;
        }
        .loading-box {
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 40px;
                color: #FF8000;
                font-weight: 600;
            }
        }
        ul {
            padding-left: 40px;
            margin-top: 30px;
        }
        .order-card:nth-child(odd) {
            background: #f8f6f6dc;
        }
        .order-card {
            display: flex;
            white-space: wrap;
            word-break: normal;
            padding-bottom: 10px;
            // margin-bottom: 10px;
            margin-bottom: 25px;
            padding: 15px;
            .card-left {
                padding: 0;
                margin-right: 20px;
                img {
                    height: 110px;
                    width: 110px;
                    // border-radius: 8px;
                    border-radius: 30px;
                    background: #a7a5a5;
                }
                .status-tag {
                    margin-top: 8px;
                    border-radius: 4px;
                    padding: 6px 0;
                    width: 110px;
                    text-align: center;
                    color: #fff;
                    font-weight: 600;
                    font-size: 16px;
                    background-color: $greenColor;
                    box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
                }
                .status-unpaid {
                    background-color: #FF8000;
                }
                .status-cancel {
                    background-color: #FF1F00;
                }
                .status-initial_order {
                    background-color: #8F9698;
                }
            }
            .card-center {
                flex: 1;
                .name {
                    padding-top: 10px;
                    font-size: 20px;
                    font-weight: 700;
                    color: #000;
                    margin-bottom: 6px;
                }
                .address-box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    :deep(.el-icon-location) {
                        color: #09CA6A;
                        // width: 20px;
                        font-size: 20px;
                    }
                    .address {
                        color: #767676;
                        font-size: 20px;
                    }
                }
                .desc {
                    color: #000;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    font-size: 14px;

                    .point {
                        display: inline-block;
                        height: 4px;
                        width: 4px;
                        border-radius: 2px;
                        background-color: #000;
                        margin-left: 8px;
                    }
                    .under-line {
                        position: relative;
                        bottom: 1px;
                        font-size: 17px;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
                .rate-box {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    :deep(.el-rate__icon) {
                        font-size: 22px;
                        margin-right: -2px;
                    }
                    .rate-content {
                        position: relative;
                        top:1px;
                        margin-left: 5px;
                        font-size: 15px;
                    }

                    .review-btn {
                        color: #8F9698;
                        margin-left: 10px;
                        font-size: 18px;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    .img-btn {
                        height: 24px;
                        width: 24px;
                        margin-left: 10px;
                        cursor: pointer;
                        transition: all 0.2s ease-in-out;
                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }
            }
            .card-right {
                padding-right: 40px;
                .btn {
                    height: 42px;
                    width: 190px;
                    background-color: $greenColor;
                    font-weight: bold;
                    color: #fff;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 15px;
                    // box-shadow: 0px 5px 10px #8F9698;
                    // border-radius: 8px;
                    border-radius: 30px;
                    cursor: pointer;
                    transition: all .2s ease;
                    &:hover {
                        background-color: #08b25d;
                    }
                    img {
                        width:16px;
                        height: 16px;
                        margin-right: 3px;
                    }
                }
                .btn-again {
                    background: #AA0BC0;
                    &:hover {
                        background-color: #8d0d9e;
                    }
                }
                .btn-pay {
                    background: #FF8000;
                    &:hover {
                        background-color: #c36507;
                    }
                }
            }
        }
        .more-btn {
            margin: 45px auto;
            height: 52px;
            width: 240px;
            border-radius: 30px;
            background-color:  #aa0bc0;
            color: #fff;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            transition: background-color 0.2s ease-in;
            // box-shadow: 0px 5px 10px #8F9698;
            cursor: pointer;
            &:hover {
                background-color: #AA0BC0;
            }
        }
        .empty-block {
            height: 600px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 400px;
                border-radius: 12px;
            }
        }
        .card-color {
            background: #fafafa;
        }
    }
}
@media screen and (max-width:950px) {
    
    .my-order {
        width:100%;
        .order-box {
            padding: 0px;
            ul {
                padding-left: unset;
                padding: 5px;
            }
            .order-card {
                padding: 2px;
                .card-left {
                    margin-right: 5px;
                    img {
                        width: 75px;
                        height: 75px;
                        border-radius: 15px;
                    }
                }
                .card-center {
                    .name {
                        font-size: 12px;
                    }
                    .address-box {
                        :deep(.el-icon-location) {
                            font-size: 15px;
                         }
                         .address {
                            font-size: 15px;
                         }
                    }
                    .rate-box {
                        margin-top: 5px;
                        font-size: 15px;
                        :deep(.el-rate__icon) {
                            font-size: 15px;
                        }
                        .rate-content {
                            font-size: 12px;
                            top:-1px;
                        }
                    }
                }
                .card-right {
                    padding-right: unset;
                    .btn {
                        width: 160px;
                        height: 30px;
                        border-radius: 15px;
                        font-size: 14px;
                        img {
                            width: 13px;
                            height: 13px;
                        }
                    }
                }
            }
            .more-btn {
                margin: 20px auto;
                width: 195px;
            }
        }
    }
}
</style>
<template>
  <div class="address-box">
    <header :class="[{'empty-tips-animate': chooseAddressTips}, 'header-box']" >
      <i class="el-icon-location" ></i>
      <span>Select Address</span>
    </header>
    <div class="item-box">
      <ul>
        <li v-for="(item) in list" :key="item.id" @click="setAddress(item)">
          <span :class="['radio', {'radio-active': item.id==value}]"></span>
          <i class="el-icon-location" ></i>
          <span class="address-name">{{item.doorNumber + ' ' + item.locationName + item.city + ' '  + item.zipcode + ', ' + item.country}}</span>
        </li>
      </ul>
      <div class="loading-box" v-if="loading">
        <i class="el-icon-loading" ></i>
      </div>
      <div class="add-btn" @click="goAddAddress">
        <a>Add New Address</a>
      </div>
    </div>
  </div>
</template>

<script>
import { postGateway } from '@/request';
import bus from "@/utils/bus.js";
export default {
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      clientId: localStorage.getItem('clientId'),
      loading: true,
      list: [],
      chooseAddressTips: false,
    };
  },
  mounted() {
    this.getAddressList();
    bus.$on('chooseAddress',()=>{
      this.chooseAddressTips = true;
      setTimeout(() => {
        this.chooseAddressTips = false;
      }, 4000);
    })
    bus.$on('login',()=>{
      setTimeout(() => {
        this.getAddressList();
      }, 1000);
    })
  },
  methods: {
    goAddAddress() {
      this.$emit('addAddress', true)
    },
    getAddressList() { // 获取用户保存的地址
      if(!localStorage.getItem('token')) {
        return
      }
      this.clientId = localStorage.getItem('clientId');
      this.loading = true;
      postGateway({
        method: 'GET',
        url: '/customer/address/queryList',
        data: {
          clientId: this.clientId
        }
      }).then(res=>{
        this.list = res.data
        this.list.forEach(ele=>{
          if(ele.asDefault){
            this.$emit('input', ele.id)
          }
        })
        this.loading = false;
      }).catch(err=>{
        console.log(err, '地址获取报错')
        this.loading = false;
      })
    },
    setAddress(item) {
      this.$emit('input', item.id)
    }
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #26d07c;
$yellowColor: #ffa132;
.address-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  .header-box {
    width: 100%;
    height: 58px;
    border-radius: 4px;
    box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.6);
    font-size: 18px;
    color: #000;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 0 15px;
    .el-icon-location {
      margin-right: 6px;
      color: $greenColor;
      font-size: 24px;
    }
  }
  .item-box {
    padding: 10px 10px;
    width: 86%;
    min-height: 80px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.3);

    .loading-box {
      height: 100px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-icon-loading {
        font-size: 20px;
      }
    }

    li {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      font-size: 17px;
      font-weight: bold;
      color: #000;
      padding: 10px;
      cursor: pointer;
      &:nth-last-child(1) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
    .address-name {
      flex: 1;
    }
    .el-icon-location {
      margin-right: 8px;
      color: #000;
      font-size: 15px;
    }
    .radio {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      min-width: 20px;
      border-radius: 10px;
      box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.3);
      margin-right: 10px;
    }
    .radio-active {
      &::after {
        content: '';
        position: absolute;
        z-index: 5;
        height: 14px;
        width: 14px;
        border-radius: 7px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: $greenColor;
      }
    }
    .add-btn {
      margin-top: 10px;
      cursor: pointer;
      height: 45px;
      border-radius: 23px;
      background-color: $greenColor;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 600;
      color: #000;
      box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.3);
      transition: all .2s ease-in-out;
      &:hover {
        background-color: #21bd6f;
      }
    }
  }
}

// 处理兼容 < 1600px 内容
@media screen and (min-width:1300px) and (max-width:1600px) {
  .address-box {
    .item-box {
      li {
        .address-name {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width:950px) {
  .address-box {
    display: none;
  }
}
</style>

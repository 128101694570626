<template>
  <div class="footer">
      <div class="match-box">
         <div class="top-box">
            <ul>
              <li>
                <div class="title" >Our Top Cuisines</div>
                <span class="item" v-for="item in showFoodTypeList" :key="item.cuisineId">
                  <a :href="'/cuisine?cuisineId='+item.cuisineId+'&cuisineName='+item.cuisineName">
                    {{ item.cuisineName }}
                  </a>
                </span>
                <!-- <span class="item" v-for="item in showFoodTypeList" :key="item.cuisineId" @click="goCate(item)" >{{ item.cuisineName }}</span> -->
                <span class="item item-btn" @click="showALlCate">{{ showFoodTypeList.length>4?'Close':'View All Categories' }}</span>
              </li>
              <li>
                <div class="title" >Our Top Cities</div>
                <span class="item">
                  <a href="">London</a>
                </span>
                <span class="item">
                  <a href="">Birmingham</a>
                </span>
                <span class="item">
                  <a href="">Manchester</a>
                </span>
                <span class="item">
                  <a href="">Leeds</a>
                </span>
                <span class="item">
                  <a href="">Liverpool</a>
                </span>
              </li>
              <li>
                <div class="title" >Quick Links</div>
                <span class="item" >
                  <a href="https://blog.alleatapp.com/what-is-the-points-system-in-all-eat/">Earn Points</a>
                </span>
                <span class="item" >
                  <a href="/faqs">FAQ</a>
                </span>
                <span class="item" >
                  <a href="https://restaurants.alleatapp.com">Become Partner</a>
                </span>
                <span class="item" >
                  <a href="https://blog.alleatapp.com">Blog</a>
                </span>
              </li>
              <li>
                <div class="title" >Contact</div>
                <span class="item">
                  <a href="https://alleatapp.com/contact">Help & Support</a>
                </span>
                <span class="item">
                  <i class="el-icon-phone-outline"></i>
                  020 3953 8888
                </span>
                <span class="item">
                  <i class="el-icon-chat-dot-square"></i>
                  info@alleatapp.com
                </span>
              </li>
            </ul>
         </div>
         <div class="center-box">
          <section class="left">
            <ul>
              <li>
                <div class="title" >Company</div>
                <span class="item">
                  <a href="/about">About Us</a>
                </span>
                <span class="item">
                  <a href="/contact">Contact Us</a>
                </span>
                <span class="item">
                  <a href="https://careers.alleatapp.com/">Careers</a>
                </span>
                <span class="item">
                  <a href="https://blog.alleatapp.com">Blog</a>
                </span>
              </li>
              <li>
                <div class="title" >Legal</div>
                <a class="item" href="/termsConditions">Terms & Conditions</a>
                <a class="item" href="/refundAndCancellations" >Refund & Cancellation</A>
                <a class="item"  href="/Privacy-Policy" >Privacy Policy</a>
                <a class="item" href="/cookiePolicy">Cookie Policy</A>
              </li>
            </ul>
          </section>
          <section class="right">
            <div class="right-box">
              <div class="follow" >Follow Us</div>
              <div class="icon-box">
                <img src="@/assets/icon/insta.svg" alt="ins" @click="goWeb('https://www.instagram.com/alleatapp/')">
                <!-- <img src="@/assets/icon/tiktok.svg" alt="ins" @click="goWeb('https://www.tiktok.com/@alleatapp?lang=en')"> -->
                <img src="@/assets/icon/tiktok.svg" alt="ins" @click="goWeb('https://www.tiktok.com/@alleatuk')">
                <img src="@/assets/icon/facebook.svg" alt="ins" @click="goWeb('https://www.facebook.com/alleatdelivery')">
                <img src="@/assets/icon/youyube.svg" alt="ins" @click="goWeb('https://www.youtube.com/@alleatapp5200/videos')">
                <!-- <img src="@/assets/icon/twitter.svg" alt="ins" @click="goWeb('https://www.linkedin.com/company/alleatapp')"> -->
                <img src="@/assets/icon/x.png" alt="ins" @click="goWeb('https://www.linkedin.com/company/alleatapp')">
              </div>
              <div class="desc">Sign up to receive exclusive offers</div>
              <div class="concat-box" >
                <div class="input-box" @click="inputDisabled=false">
                  <i class="el-icon-s-comment"></i>
                  <input type="text" :disabled="inputDisabled" autofocus @focus="inputDisabled=false" @blur="inputDisabled=true"
                    placeholder="Enter your email" />
                </div>
                <button>Subscribe</button>
              </div>
            </div>
          </section>
         </div>
         <div class="bottom-box">
            <div class="rights-box">
              <span>All Rights Reserved</span>
              <img src="@/assets/icon/icon_rights.png" alt="rights">
              <span>All Eat App,  {{ fullYear }}</span>
            </div>
         </div>
      </div>
  </div>
</template>

<script>
import { postGateway } from '@/request'
export default {
  name: "app-footer",
  data() {
    return {
      fullYear: "",
      foodTypeList: [],
      showFoodTypeList: [],
      inputDisabled: true,
    };
  },
  mounted() {
    this.fullYear = new Date().getFullYear();
    //分类
    this.getFoodType();
  },
  methods: {
    getFoodType() {
      postGateway({
        url: "/customerWeb/index/popularCategory",
        method: "GET",
        data: {},
      }).then((res) => {
        this.foodTypeList = res.data;
        this.showFoodTypeList = res.data.splice(0 ,4)
        console.log(res, "res");
      });
    },
    showALlCate() {
      let list = [...this.foodTypeList]
      if(this.showFoodTypeList.length > 4) {
        this.showFoodTypeList = list.splice(0 ,4)
      } else {
        this.showFoodTypeList = list
      }
    },
    goCate(item) {
      let { cuisineId, cuisineName } = item;
      this.$router.push({
        path: '/cuisine',
        query: {
          cuisineId: cuisineId,
          cuisineName: cuisineName
        }
      })
    },
    contactUs() {
      window.open("https://alleatapp.net/contact-us/");
    },
    earnPoints() {
      window.open(
        "https://alleatapp.net/what-is-the-points-system-in-all-eat/"
      );
    },
    partnerUs() {
      window.open("https://alleatapp.net/become-patner/");
    },
    aboutUs() {
      window.open("/aboutUs");
    },
    careers() {
      window.open("https://alleatapp.net/careers/");
    },
    seedFeedback() {
      window.open("https://alleatapp.net/contact-us/");
    },
    gotoFaqs() {
      this.$router.push("/faqs");
    },
    myAccount() {
      this.$router.push("/accountManagement/personalDetails");
    },
    gotoCookie() {
      this.$router.push("/cookiePage");
    },
    gotoPrivate() {
      this.$router.push("/Privacy-Policy");
    },
    goHelp() {
      this.$router.push("/help");
    },
    goWeb(url) {
      window.open(url)
    },
    gotoBlog() {
      window.open("https://blog.alleatapp.com/");
    },
    // TODO
    gotoPath(item) {
      item
        ? this.$router.push(`/takeaway/nearme/${item.cuisineName}`)
        : this.$router.push(`/takeaway/nearme/all`);
      sessionStorage.setItem("typeId", item ? item.cuisineId : "");
    },
    gotoLocation(item) {
      this.$router.push(`/takeaway/${item}`);
    },
    gotoGplay() {
      window.open(
        "https://play.google.com/store/apps/details?id=io.dcloud.uniAllEat&gl=GB"
      );
    },
    gotoApp() {
      window.open(
        "https://apps.apple.com/gb/app/all-eat-food-delivery-uk/id1603420846"
      );
    },
    gotoTermsAndCond() {
      this.$router.push("/termsConditions");
    },
  },
};
</script>
<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #26d07c;
$yellowColor: #ffa132;
.footer {
  min-height: 753px;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #212121;

  .match-box {
    .top-box {
      height: 300px;
      border-bottom: 1px solid #424242;
    }

    .top-box, .center-box .left{
      ul {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        li {
          width: 200px;
          display: flex;
          flex-direction: column;

          .title {
            font-weight: 700;
            color: #fff;
            font-size: 24px;
            margin-bottom: 40px;
            white-space: nowrap;
          }
          .item {
            font-weight: 400;
            color: #F5F5F5;
            font-size: 17px;
            margin-bottom: 12px;
            opacity: 0.9;
            a {
              color: #F5F5F5;
            }
          }
          .item-btn {
            cursor: pointer;
          }
        }
      }
    }

    .center-box {
      min-height: 230px;
      border-bottom: 1px solid #424242;
      display: flex;
      padding-top: 80px;
      padding-bottom: 100px;
      .left {
        flex: 1;
        ul {
          width: 650px;

          li {
            .title {
              font-size: 20px;
            }
            .item {
              font-size: 15px;
              opacity: 0.9;
            }
          }
        }
      }
      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        

        .right-box {
          width: 500px;
          .follow {
            font-weight: 700;
            font-size: 20px;
            color: #F5F5F5;
            opacity: 0.5;
          }
          .icon-box {
            width: 200px;
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            img {
              height: 36px;
              width: 36px;
              margin-right: 15px;
            }
          }
          .desc {
            margin-top: 30px;
            color: #BBBBBB;
            font-size: 16px;
            font-weight: 700;
            opacity: 0.6;
          }
          .concat-box {
            margin-top: 30px;
            height: 48px;
            width: 100%;
            display: flex;
            align-items: center;

            .input-box {
              background-color: #424242;
              border-radius: 4px;
              margin-right: 10px;
              flex: 1;
              height: 40px;
              display: flex;
              align-items: center;
              padding: 0 20px 0 10px;
              .el-icon-s-comment {
                font-size: 25px;
                color: #fff;
                margin-right: 5px;
              }
            }
            input {
              height: 100%;
              height: 40px;
              font-size: 16px;
              flex: 1;
              background-color: transparent;
              color: #fff;
            }
            button {
              height: 40px;
              border-radius: 4px;
              width: 100px;
              font-weight: 700;
              font-size: 14px;
              flex-wrap: nowrap;
              white-space: nowrap;
              color: #fff;
              background-color: #09CA6A;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              transition: all .2s ease-in;
              &:hover {
                background-color: #10a058;
              }
            }
          }
        }

      }
    }

    .bottom-box {
      padding: 20px 0;
      display: flex;
      .rights-box  {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #fff;
        font-weight: 700;
        img {
          width: 16px;
          height: 18px;
          margin: 0 18px;
        }
      }
    }

  }

}

@media screen and (max-width: 950px) {
  .footer{
    padding: 0;
    background-color: #1F2025;
    .match-box {
      width: 100%;
      padding: 0 20px;
      .top-box {
        border-bottom: 2px solid #424242;
      }
      .top-box, .center-box .left {
        height: auto;
        padding-bottom: 20px;
        ul {
          width: 100%;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            width: 40%;
            white-space: nowrap;
            &:nth-child(2n) {
              margin-left: 4%;
            }
            .title {
              opacity: 0.5;
              font-size: 16px;
              margin-bottom: 20px;
              margin-top: 20px;
            }
            .item {
              font-size: 14px;
              opacity: 1;
            }
          }
        }
      }

      .center-box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        .right .right-box{
          width: 100%;
          display: flex;
          flex-direction: column;
          padding-top: 10px;
          border-top: 2px solid #424242;
          .follow {
            width: 100%;
            margin-top: 10px;
            font-size: 18px;
          }
          .icon-box {
            width: 100%;
            margin-top: 15px;
            justify-content: flex-start;
            img {
              margin-right: 15px;
              height: 36px;
              width: 36px;
            }
          }
          .desc {
            margin-top: 10px;
            font-size: 15px;
            color: #BBBBBB;
            opacity: 1;
          }
          .concat-box {
            width: 100%;
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .input-box {
              height: 48px;
              width: 100%;
              border-radius: 6px;
              padding-left: 15px;
              .el-icon-s-comment {
                margin-right: 5px;
              }
              input {
                font-size: 16px;
              }
            }

            button {
              margin-top: 20px;
              border-radius: 6px;
              padding: 20px;
            }
          }
        }
      }

      .bottom-box {
        .rights-box {
          font-size: 15px;
        }
      }

    }
  }
}
</style>

<template>
  <div class="home" :style="{ 'min-height': innerHeight }">
    <div class="content-box">
      <div class="match-box">
        <div class="left-box " >
          <div style="position: relative;padding-top: 160px;">
            <div class="title-tips">Feeling hungry?</div>
            <div class="title-desc">
              With just a few clicks, discover a variety of delicious meals available nearby!
            </div>
          </div>
          <div class="search-box">
            <div class="top-box">
              <div
                :class="['type-btn', { 'btn-active': transType == ind+1 }]"
                v-for="(item, ind) in typeList"
                :key="item"
                @click="transType = ind+1"
              >
                <i :class="['iconfont', iconList[ind], { font26: ind == 2 }]"></i>
                <span>{{ item }}</span>
              </div>
            </div>
            <div class="bottom-box">
              <div class="input-box">
                <img src="@/assets/images/location.png" alt="location-img" />
                <input
                  type="text"
                  v-model="keywords"
                  @input="inputNow"
                  @keyup="inputNow"
                  :placeholder="$isUk?'Enter your postcode':'Enter your irecode'"
                  style="height: 100%; width: 100%"
                />

                <!-- 搜索结果内容 -->
                <div :class="['expand-box', { 'expand-box-active': keywords }]">
                  <div
                    class="search-item"
                    v-for="(item, ind) in resultList"
                    :key="ind"
                    @click="normalSearch(item)"
                  >
                    <span>{{ item.content }}</span>
                    <i class="el-icon-loading" v-if="itemLoading == item.place_id && item.type=='location'"></i>
                    <img v-else src="@/assets/images/right.png" alt="right-arrow" />
                  </div>
                  <div class="load-box" v-if="loading">
                    <span class="loader"></span>
                  </div>
                  <div class="load-box" v-else-if="!loading && !resultList.length">
                    No data.
                  </div>
                </div>
              </div>
              <button class="search-btn" @click="inputNow">
                <img src="@/assets/images/search.png" alt="search-img" />
                Find Food
              </button>
            </div>
          </div>
        </div>
        <div class="right-box" >
          <img
            class="cuise-img"
            src="@/assets/index/icon_index_cate2.png"
            alt="nofound"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "@/utils/debounce.js";
import { postGateway } from '@/request/index.js';
// import Lang from "../components/lang.vue";
export default {
  name: 'NotFound',
  metaInfo: {
    meta: ["All Eat "],
    title: "All Eat not found",
  },
  data() {
    return {
      transType: 1,
      typeList: ["Delivery", "Pick-up", "Dine-in"],
      iconList: ["icon-waimai", "icon-01_zitidian", "icon-tangshi"],
      keywords: "",
      resultList: [],
      loading: false,
      innerHeight: 800,
      itemLoading: null,
      slug: '',
      merchantId: '',
    };
  },
  components: {
    // Lang,
  },
  created() {
    // this.initMerchant();
  },
  mounted() {
    this.innerHeight = window.innerHeight + "px";
  },
  methods: {
    // 加载商家的方法
    initMerchant() {
      let slug = this.$route.params.pathMatch;
      this.slug = slug || '';
      if(this.slug.includes('/')) {  //处理 ssr 默认访问会带一个 /
        this.slug = this.slug.replace(/\//g,'')
      }
      this.getMerchantId(this.slug).then(()=>{

        if(!localStorage.getItem('token')) {
          return
        }
        // 获取缓存购物车
        console.log(this.slug,'slug')
      })
      .catch(()=>{
        this.pageLoadingClose();
        this.$router.push({
              path: '/404'
          })
      })
    },
    getMerchantId(slug) {
      console.log(slug,'请求参数的 slug')
      return postGateway({
        url: "/customerWeb/merchantDetail/getMerchantBySlug",
        method: "GET",
        data: {
          slug
        }
      }).then(res=>{
        this.merchantId = res.data*1;
        console.log(this.merchantId, 'merchantId')
      })
    },
    inputNow() {
      this.resultList = [];
      this.loading = true;
      debounce(() => {
        if (this.keywords.trim().length < 3) return;
        postGateway({
          method: "GET",
          url: "/customerApp/index/getPreKeyword",
          data: { keyword: this.keywords },
        })
          .then((res) => {
            if (res.data.length) {
              this.resultList = res.data.map((x) => {
                return {
                  content: x,
                  type: "content",
                };
              });
              this.loading = false;
            } else {
              this.googlePreSearch();
            }
          })
          .catch(() => {
            this.googlePreSearch();
          });
      }, 500);
    },
    googlePreSearch() {
      this.loading = true;
      if(this.$isUk) {
				postGateway({
					url: `/customerApp/addressioSearch/autocomplete`,
					method: 'GET',
					data: {
						keyword: this.keywords
					}
				}).then(res=>{
					console.log(res.data,'搜索地址会餐')
					this.loading = false;
					this.resultList = res.data.suggestions.map(x=>({
            ...x,
            content: x.description || x.address,
            place_id: x.id,
            type: "location",
          }));
				})
				.catch(err=>{
					this.loading = false;
					console.log(err,'搜索地址报错')
				})
				return
			}
      postGateway({
        method: "GET",
        url: "/customerWeb/googleSearch/autocomplete",
        data: { keyword: this.keywords },
      })
        .then((res) => {
          this.resultList = this.resultList.concat(
            res.data.predictions.map((x) => {
              return {
                ...x,
                content: x.description,
                type: "location",
              };
            })
          );
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    normalSearch(item) {
      if (item.type == "content") {
        this.$router.push({
          path: "/browse",
          query: {
            searchName: item.content,
            lng: "",
            lat: "",
            searchLoc: "",
          },
        });
      } else if (item.type == "location") {
        this.googleSearch(item);
      }
    },
    googleSearch(item) {
      this.itemLoading = item.place_id;

      if(this.$isUk) {
					postGateway({
						url: `/customerApp/addressioSearch/get`,
						method: 'GET',
						data: {
							id: item.id
						}
					}).then(res=>{
						console.log(res,'搜索地址会餐')
						let { town_or_city, longitude, latitude  } = res.data;
						this.$router.push({
              path: "/browse",
              query: {
                searchName: "",
                lng: longitude,
                lat: latitude,
                searchLoc: town_or_city,
                transType: this.transType,
              },
            });
            this.searchVisible = false;
					})
					.catch(()=>{
            this.searchVisible = false;
					})
					return
				}

      postGateway({
        method: "GET",
        url: "/customerWeb/googleSearch/placeGeoCode",
        data: {
          keyword: "",
          placeId: item.place_id,
        },
      }).then((res) => {
        this.itemLoading = '';
        if (res.code == 200) {
          this.$router.push({
            path: "/browse",
            query: {
              searchName: "",
              lng: res.data.lng,
              lat: res.data.lat,
              searchLoc: res.data.formattedAddress,
              transType: this.transType,
            },
          });
          this.searchVisible = false;
        } else {
          this.$message.error("Error happen...");
        }
      }).catch(err=>{
        this.itemLoading = '';
        console.log(err,'搜索报错')
      })
    },
    becomePart() {
      window.open("https://alleatapp.net/become-partner/");
    },
    aboutUs() {
      window.open("https://alleatapp.net/");
    },
    goLogin() {
      this.$router.replace({
        path: "/login",
      });
    },
    goHome() {
      this.$router.replace({
        path: "/",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  min-height: 100vh;

  .content-box {
    zoom: 0.85;
    position: relative;
    width: 100%;
    height: 500px;
    background-image: url('../assets/images/notfoundBg.png');
    background-size: cover;
    background-position: center;
    box-sizing: border-box;

    .match-box {
      position: relative;
      display: flex;

      .left-box {
        flex: 1;
      }
      .right-box {
        padding-left: 20px;
        width: 560px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        .cuise-img {
          display: inline-block;
          height: 470px;
          width: 470px;
          position: relative;
          right: -300px;
          top: -50px;
        }
      }
    } 

    // tips
    .title-tips {
      font-size: 96px;
      color: #fff;
      font-weight: bold;
    }

    .title-desc {
      margin-top: 10px;
      font-size: 26px;
      color: #fff;
      letter-spacing: 0.5px;
    }
  }

  .search-box {
    position: relative;
    z-index: 10;
    margin-top: 30px;
    height: 260px;
    width: 100%;
    background: #fff;
    padding: 24px 24px 0;
    box-sizing: border-box;
    // border-radius: 12px;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 10px,
      rgba(139, 79, 201, 0.23) 0px 6px 6px;
    display: flex;
    flex-direction: column;

    .top-box {
      height: 60px;
      border-radius: 8px;
      color: #222;
      font-weight: bold;
      font-size: 15px;
      display: inline-flex;
      align-items: center;

      .type-btn {
        cursor: pointer;
        display: inline-flex;
        margin-right: 20px;
        height: 60px;
        // border-radius: 4px;
        border-radius: 30px;
        justify-content: center;
        width: 172px;
        align-items: center;
        background: #e9eaea;
        font-weight: bold;
        font-size: 24px;
        transition: all 0.2s ease-in-out 0s;

        i {
          color: #000000;
          margin-right: 8px;
          font-size: 26px;
        }
        .icon-tangshi {
          font-size: 20px;
        }
        &:hover {
          background: #cbcdcd;
          color: #fff;
        }
      }

      .btn-active {
        background: #09ca6a;
        color: #fff;

        &:hover {
          background: #049d51;
        }
        i {
          color: #fff;
          margin-right: 8px;
          font-size: 26px;
        }
      }
    }

    .bottom-box {
      flex: 1;
      display: flex;
      align-items: center;

      .input-box {
        height: 60px;
        background: #f5f5f5;
        padding: 0 20px;
        flex: 1;
        display: inline-flex;
        align-items: center;
        position: relative;
        border-radius: 30px;
        .expand-box {
          position: absolute;
          width: 100%;
          height: 0px;
          top: 60px;
          left: 0;
          background: rgba(255, 255, 255, 0.95);
          padding: 20px 0;
          height: 0px;
          opacity: 0;
          transition: all 0.8s ease-in-out 0s;

          .search-item {
            height: 50px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e9e9e9;
            padding: 0 20px;
            cursor: pointer;

            &:hover {
              background-color: rgba(236, 230, 230, 0.95);
            }

            span {
              flex: 1;
              font-size: 20px;
            }
            .el-icon-loading {
              margin-right: 15px;
            }

            img {
              height: 18px;
              width: 25px;
            }
          }
        }

        .load-box {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          .loader {
            width: 48px;
            height: 48px;
            border: 5px solid #fff;
            border-bottom-color: #ff3d00;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
          }

          @keyframes rotation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }

        .expand-box-active {
          height: 300px;
          opacity: 1;
          overflow-y: scroll;
        }

        input {
          background: transparent;
          font-weight: normal;
        }

        img {
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }
      }

      .search-btn {
        height: 60px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        // border-radius: 6px;
        border-radius: 30px;
        background: #09ca6a;
        color: #fff;
        font-weight: bold;
        margin-left: 16px;
        padding: 0 40px;
        font-size: 22px;
        cursor: pointer;
        transition: all 0.2s ease-in-out 0s;

        img {
          height: 22px;
          width: 22px;
          margin-right: 10px;
        }
        &:hover {
          background: #049d51;
          color: #e8e4e4;
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .address-box {
    width: 100vw;
    box-shadow: unset;
    background: unset;
    .address-box-l {
      padding: 20px 10px;
      .form-address-line {
        height: 42px;
        width: 100vw;
        padding: 0 20px;
        box-sizing: border-box;
        .search-btn {
          font-size: 14px;
          display: none;
        }
      }
      .expand-box {
        left: unset;
        width: 100%;
        left: 0px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        .search-item {
          height: unset;
          padding: 10px 5px 10px 20px;
          img {
            height: unset;
            width: 13px;
            margin-left: 10px;
          }
        }
      }
      
    }
  }
}

button {
  border: none;
}
input {
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 24px;
}
</style>

<template>
  <div>
    <el-dialog
    title="Location"
    :visible.sync="modalShow"
    :before-close="closeModal"
    :width="isMobile?'90%':'50%'"
  >
    <div class="modal-content" >
      <AddressSearchInput @getAddress="getAddress"></AddressSearchInput>
    </div>
  </el-dialog>
  </div>
  
</template>

<script>
import AddressSearchInput from './AddressSearchInput.vue';
export default {
  data() {
    return {
      modalShow: false,
    };
  },
  components: {
    AddressSearchInput
  },
  model: {
    prop: "value",
    event: "input",
  },
  computed: {
    isMobile() {
      return window.innerWidth < 950
    }
  },
  watch: {
    'value': function(val) {
      this.modalShow = val;
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit('input', false)
    },
    getAddress(obj) {
      this.$emit('getAddress', obj)
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  padding-bottom: 20px;
}
@media screen and (max-width: 900px) {
  .modal-content {
    margin-top: -20px;
  }
}
</style>

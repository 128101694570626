<template>
  <div>
    <BoxOneVue></BoxOneVue>
    <BoxTwoVue></BoxTwoVue>
    <BoxThreeVue></BoxThreeVue>
    <BoxFourVue></BoxFourVue>
    <div class="box5">
      <div>
        <div class="title">Our Partners</div>
        <div class="content">
          We don’t walk alone, <br />
          All Eat to our partners
        </div>
        <div class="footer">
          All Eat is the UK’s first online food ordering platform which charges 0% commission from restaurants.
        </div>
      </div>
    </div>
    <BoxFiveVue></BoxFiveVue>
    <BoxSixVue></BoxSixVue>
  </div>
</template>

<script>
import BoxFiveVue from "./components/BoxFive.vue";
import BoxFourVue from "./components/BoxFour.vue";
import BoxOneVue from "./components/BoxOne.vue";
import BoxSixVue from "./components/BoxSix.vue";
import BoxThreeVue from "./components/BoxThree.vue";
import BoxTwoVue from "./components/BoxTwo.vue";
export default {
  components: {
    BoxOneVue,
    BoxTwoVue,
    BoxThreeVue,
    BoxFourVue,
    BoxFiveVue,
    BoxSixVue,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.box5 {
  width: 100%;
  display: flex;
  justify-content: center;
  .title {
    display: flex;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    /* or 111% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1.08px;
    text-transform: uppercase;

    color: #AA0BC0;
  }
  .content {
    margin-top: 10px;
    width: 600px;
    font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 54px;
    justify-content: center;
    /* or 123% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #121212;
  }
  .footer {
    width: 640px;
    margin-top: 20px;
    font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    /* or 167% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #656565;
  }
}
@media screen and (max-width:900px) {
      .box5{
        width:100%;
        height:100%;
        padding: 5% 7%;
        justify-content:center;
        align-items:center;
        .title{
          width:auto;

        }
        .content{
          width:100%;
          font-size:1.9rem;
          line-height: 30px;
        }
        .footer
        {
          width:100%;
        }
        
        }

        
      }
</style>

<template>
    <div class="review">
        <PageLoading v-model="pageLoading"></PageLoading>
        <div class="break-box" v-if="!isMobile" >
            <div class="break">
                <i class="el-icon-back" ></i>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/browse' }">Browse</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/menu-'+this.slug }">{{ slug }}-menu</el-breadcrumb-item>
                    <el-breadcrumb-item>
                        <span style="cursor:default;font-weight: 600;color:#09CA6A;">Reviews</span>
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="review-box">
            <div class="title">
                Reviews
            </div>
            <div class="content">
                <div v-if="listCount==0" class="noData-box">No Data</div>
                <div class="content-card" v-for="item in reviewList" :key="item.index">
                    <div class="score-box">
                        <el-rate v-model="item.rating" :disabled="true">
                        </el-rate>
                        <div class="score-text">({{ item.rating }})</div>
                    </div>
                    <div class="middle-box">
                        <div class="customer-name">{{ item.clientName }}</div>
                        <div class="comment-time">{{ item.dateCreated }}</div>
                    </div>
                    <div class="customer-comment">{{ item.review }}</div>
                    <el-divider v-if="isMobile"></el-divider>
                </div>
            </div>
            <div class="more-btn" v-if="!loading && listCount>this.pageSize*this.pageNum" @click="moreReviews">Show More</div>
            <div class="loading-box" v-if="loading">
                <i class="el-icon-loading"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { postGateway } from '@/request';
import PageLoading from '@/components/PageLoading.vue'
export default {
    components:{
        PageLoading
    },
    data() {
        return {
            merchantId:'',
            slug:'',
            reviewList:[],
            pageLoading:true,
            pageNum:1,
            pageSize:10,
            listCount:0,//总条数
            loading:false,
        }
    },
    created() {
        this.pageLoading=false;
        this.slug = this.$route.query.slug;
        this.merchantId= this.$route.query.merInfoId;
        // console.log(this.slug+this.merchantId+"数据获取"),
        this.getReviews();
        console.log(this.listCount,'记录数');
    },
    computed: {
        isMobile(){
            return window.innerWidth < 950
        }
    },
    methods: {
        getReviews(refresh=false) {
            this.loading=true;
            if(refresh) {
                this.pageSize=10;
                this.pageNum=1;
            }
            postGateway({
            url:'/customerApp/merchantDetail/getReviewList',
            method:'Get',
            data:{
                merchantId:this.merchantId,
                pageNum:this.pageNum,
                pageSize:this.pageSize,
            }
            }).then((res)=>{
                if(refresh) {
                    this.reviewList=[];
                }
                this.reviewList=this.reviewList.concat(res.data);
                this.loading=false;
                this.pageLoading=false;
                this.listCount=res.total;
                console.log(this.pageNum,'当前页');
                console.log(this.listCount,'记录数');
            }).catch(err=>{
                this.loading=false;
                this.$message.info(err.msg)
            })
        },
        moreReviews() {
            if(this.pageSize==3) {
                this.pageSize=10;
                this.getReviews(true);
            }
            else {
            this.pageNum+=1; 
            this.getReviews();
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .review {
        .break-box {
            flex: 1;
            box-sizing: border-box;
            padding: 48px 12%;
            .break {
                height: 44px;
                display: inline-flex;
                align-items: center;
                padding: 20px;
                border-radius: 22px;
                background-color: #0A191E;
                color: #FFFFFF;
                .el-icon-back {
                    color: #2680ED;
                    font-size: 22px;
                    margin-right: 10px;
                }
                :deep(.el-breadcrumb__inner) {
                    color: #fff;
                }
                :deep(.el-breadcrumb__separator) {
                    color: #fff;
                }
            }
        }
        .review-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin:50px;
            .title {
                font-size: 50px;
            }
            .content {
                margin-top: 75px;
                width: 1000px;
                min-height: 100px;
                box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
                border-radius: 24px;
                margin: 20px;
                .noData-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height:100px;
                    color:#8F9698;
                }
                .content-card:nth-child(odd) {
                    background: #fdfbfbdc;
                }
                .content-card {
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    // background: #F7F8FA;
                    .score-box {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .score-text {
                            line-height: 30px;
                            font-size: 15px;
                            color:#8F9698;
                        }
                    }
                    .middle-box {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        .customer-name {
                            font-size: 22px;
                            line-height: 30px;
                            font-weight: bold;
                        }
                        .comment-time {
                            font-size: 15px;
                        }
                    }
                    .customer-comment {
                        color:#8F9698;
                        line-height: 30px;
                        font-size: 15px;
                    }
                }
            }
            .more-btn {
                margin: 45px auto;
                height: 52px;
                width: 240px;
                border-radius: 6px;
                background-color:  #aa0bc0;
                color: #fff;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                transition: background-color 0.2s ease-in;
                box-shadow: 0px 5px 10px #8F9698;
                cursor: pointer;
                &:hover {
                    background-color: #AA0BC0;
                }
            }
            .loading-box {
                height: 80%;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 40px;
                    color: #FF8000;
                    font-weight: 600;
                }
            }
        }
        
    }
    @media screen and (max-width:950px) {
        .review{
            .break-box {
                padding: 48px 0px;
                text-align: center;
                .break {
                    height: auto;
                    padding: 5px 15px;
                    :deep(.el-breadcrumb__inner) {
                        font-size: 10px;
                    }
                }
            }
            .review-box {
                margin:5px;
                .title {
                    font-size: 30px;
                    margin-top: 15px;
                }
                .content {
                    width:100%;
                    box-shadow: unset;
                    border-radius: unset;
                    margin:20px 0px 0px 0px;
                    .content-card:first-child {
                        border-radius: 24px 24px 0px 0px;
                    }
                    .content-card:last-child {
                        border-radius: 0px 0px 24px 24px;
                    }
                    .content-card:nth-child(odd) {
                        background:unset;
                    }
                    .content-card {
                        padding: 12px;
                        .score-box {
                            .score-text {
                                font-size: 10px;
                            }
                        }
                        .middle-box {
                            // width:100%;
                            .customer-name {
                                font-size: 18px;
                            }
                            .comment-time {
                                font-size: 10px;
                            }
                        }
                        .customer-comment {
                            font-size: 10px;
                        }
                        :deep(.el-divider--horizontal) {
                            margin:12px auto;
                        }
                    }
                }
                .more-btn {
                    height: 42px;
                    width: 200px;
                    margin: 20px auto;
                }
            }
        }
    }
</style>
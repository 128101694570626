<template>
  <div class="checkout" :style="{ 'min-height': innerHeight }">
    <div class="content-box" >
      <div class="match-box" >
        <div class="break-box" >
          <div class="break">
              <i class="el-icon-back" @click="goBack"></i>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item @click="$router.go(-1)">Menu</el-breadcrumb-item>
                  <el-breadcrumb-item >
                    <span style="color:rgb(9, 202, 106);">Check out</span>
                  </el-breadcrumb-item>
              </el-breadcrumb>
          </div>
        </div>
        <h2 class="check-title" v-if="token">Check out</h2>
        <h2 class="check-title" v-else>Login To Continue</h2>
        <img
          class="cuise-img"
          src="@/assets/images/cuiseNot.png"
          alt="nofound"
        />
      </div>
    </div>
    
    <div class="main-content">
      <!-- Display a payment form -->
      <form id="card-form">
          <h2 class="title">
          Card Payment
          </h2>
          <div class="loading-box"  v-if="!payLoadDone">
            <i class="el-icon-loading" ></i>
          </div>
          
          <label for="card-number" >Card Number</label>
          <div id="card-number" class="card_field" ></div>
          <div>
            <label for="expiration-date">Expiration Date</label>
            <div id="expiration-date" class="card_field"></div>
          </div>
          <div>
            <label for="cvv">CVV</label>
            <div id="cvv" class="card_field"></div>
          </div>
          <label for="card-holder-name">Name on Card</label>
          <input type="text" id="card-holder-name" name="card-holder-name" @click="showToast=false" autocomplete="off" placeholder="card holder name"/>
          <div v-show="showToast" class="msg" >Please enter Card holder name</div>
          <br />
          <button value="submit" id="submit" class="btn">{{ payLoadDone?'Pay':'Loading...' }}</button>
      </form>
    </div>

    <LoadErrorModal v-model="showLoadError" @confirm="reloadScript" ></LoadErrorModal>

  </div>
</template>

<script>
/* eslint-disable */ 
import { postGateway, postGatewayOther } from '@/request';
import LoadErrorModal from "@/components/LoadErrorModal.vue";
export default {
  name: "NotFound",
  components: {
    LoadErrorModal
  },
  metaInfo: {
    meta: ["All Eat "],
    title: "All Eat not found",
  },
  data() {
    return {
      token: localStorage.getItem('token'),
      paypalToken: '',
      merchantId: null,
      orderId: '',
      amount: '',
      innerHeight: '',
      scriptLoading: true,
      message: 'vue-btn',
      showToast: false,
      showLoadError: false,
      showErr: false,
      errMsg: 'Please enter complete information',
      currency: 'GBP',
      payLoadDone: false,
      form: {
          cardNumber: '',
          expirationDate: '',
          cvv: '',
      },
    }
  },
  watch: {
    'showLoadError': function(val) {
      if(val) {
        this.uploadError('paypal card 加载报错');
      }
    },
    'showErr': function(val) {
      if(val) {
        this.$message.warning(this.errMsg)
      } 
    }
  },
  created() {

    if(this.$route.query.merchantId && this.$route.query.orderId) {
      let { merchantId, orderId, amount } = this.$route.query;
      this.merchantId = merchantId;
      this.orderId = orderId;
      this.amount = amount;
    } else if(localStorage.getItem('newCardPayment')) {
      // 丢失路由传参的情况
      let param = JSON.parse(localStorage.getItem('newCardPayment'))
      let { merchantId, orderId, amount } = param;
      this.merchantId = merchantId;
      this.orderId = orderId;
      this.amount = amount;
      console.log(localStorage.getItem('newCardPayment'),localStorage.getItem('newCardPayment'), '----在这里')
    }

    // 额外保存一次参数
    if(this.merchantId && this.orderId) {
      localStorage.setItem('newCardPayment', JSON.stringify({
          merchantId: this.merchantId,
          orderId: this.orderId,
          amount: this.amount
      }))
    }

    this.getClientToken().then(()=>{
      this.creatScript();
    })
  },
  mounted() {
    this.innerHeight = window.innerHeight + "px";
  },
  methods: {
    reloadScript() {
      this.showLoadError = false;
      this.payLoadDone = false;
      document.querySelector("#card-form").style = 'display: block';
      this.getClientToken().then(()=>{
        this.creatScript();
      })
    },
    getClientToken() {  // 第一步获取 商家支付tiken
      return postGateway({
        url: '/payPal/getAccessToken',
        method: 'GET',
        data: {
          merchantId: this.merchantId
        }
      }).then(res=>{
        this.paypalToken = res.data.accessToken;
      })
      .catch(err=>{
        this.showLoadError = true;
        console.log(err,'加载paypaltoken报错')
      })
    },
    creatScript() { // 获取paypal client id
      postGateway({
        url: '/customerApp/order/getPayClientId',
        method: 'GET',
        data: {
          merchantId: this.merchantId
        }
      }).then(res=>{
        let { paypal_client_id, currencyType } = res.data;
        this.currency = currencyType || 'GBP'
        console.log(paypal_client_id,'paypal_client_id -- 商家clientid')
        this.initScript(paypal_client_id)
      })
      .catch(err=>{
        this.showLoadError = true;
      })
    }, 
    initScript(paypal_client_id) {  //生成script
      postGatewayOther({
        url: 'https://api-m.paypal.com/v1/identity/generate-token',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'en_US',
          'Authorization': 'Bearer ' + this.paypalToken,
        }
      }).then(res=>{
        console.log('initScript', res)
        const scriptInfo = document.createElement("script")
        scriptInfo.type = "text/javascript"
        scriptInfo.setAttribute("data-client-token",res.client_token)
        scriptInfo.src = `https://www.paypal.com/sdk/js?components=buttons,hosted-fields&locale=en_GB&currency=${this.currency}&enable-funding=card&disable-funding=credit&client-id=${paypal_client_id}`
        document.head.appendChild(scriptInfo)
        scriptInfo.onload = () => {
          this.initPaypal()
        }
        scriptInfo.onerror = () => {
          this.showLoadError = true;
        }

      }).catch((err)=>{
        console.log(err, '获取paypaltoken失败')
        this.showLoadError = true;
      })
    },
    initPaypal() {
        let _this = this;
        // If this returns false or the card fields aren't visible, see Step #1.
        let payOrderId = null;
        setTimeout(()=>{
            _this.payLoadDone = true;
        },1000)
        if (paypal.HostedFields.isEligible()) {
            // Renders card fields  
            // 渲染 表单提交页面
            paypal.HostedFields.render({
                // Call your server to set up the transaction
                createOrder: function() {
                    // 后台要生成订单 拿到订单id 去支付
                    return fetch('https://nweb.alleatapp.com/base/payPal/createCardOrder?oId='+ _this.orderId, 
                    {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json',
                            'refreshToken': localStorage.getItem('refreshToken'),
                            'token': localStorage.getItem('token'),
                        }
                    }).then(function(res) {
                        return res.json();
                    }).then(function(orderData) {
                        // if(orderData.code == 50033) {  // 支付成功跳转
                        //   _this.$router.push('/my?orderSuccess=1&type=orderDone')
                        //   return 
                        // }
                        if(orderData.code == 50033) {  // 支付成功跳转
                          _this.$router.push(`/orderSuccess?orderSuccess=1&type=orderDone&orderId=${_this.orderId}`)
                          return 
                        }
                        
                        payOrderId = orderData.data;
                        console.log(JSON.stringify(orderData),'下单返回的参数')
                        return payOrderId;
                    }).catch(error => {
                        _this.$message.error(error)
                    });
                },
                styles: {
                    '.valid': {
                        'color': 'green'
                    },
                    '.invalid': {
                        'color': 'red'
                    }
                },
                fields: {
                    // 卡号
                    number: {
                        selector: "#card-number",
                        placeholder: "**** **** **** ****"
                    },
                    cvv: {
                        selector: "#cvv",
                        placeholder: "***"
                    },
                    // 过期事件
                    expirationDate: {
                        selector: "#expiration-date",
                        placeholder: "MM/YY"
                    }
                },
                // 点击提交
            }).then( (cardFields)=> {
                // 监听表单 提交事件 
                document.querySelector("#card-form").addEventListener('submit', (event) => {
                    event.preventDefault();
                    
                    _this.payLoadDone = false;
                    
                    var state = cardFields.getState();
                    var formValid = Object.keys(state.fields).every(function (key) {
                        return state.fields[key].isValid;
                    });
                        
                    if(!formValid) {
                        console.log('校验没有通过')
                        _this.errMsg = 'The form is not valid. <br />Please make sure you have filled in all of the fields properly.';
                        _this.showErr = true;
                        setTimeout(()=>{
                            _this.showErr = false;
                        },4000)
                        _this.payLoadDone = true;
                        return
                    }
                    
                    // 校验 是否有填写姓名
                    if(!document.getElementById('card-holder-name').value) {
                        _this.errMsg = 'The form is not valid. <br />Please make sure you have filled in all of the fields properly.';
                        _this.showErr = true;
                        setTimeout(()=>{
                            _this.showErr = false;
                        },4000)
                        _this.payLoadDone = true;
                        return
                    }
                    console.log('订单生成完了，提交卡信息')
                    // 这里 ios 执行了  但是没有回调 then  catch  所以页面丢到线上H5处理
                    cardFields.submit({
                        // Cardholder's first and last name
                        // Cardholder's first and last name
                            cardholderName: document.getElementById('card-holder-name').value,
                            // countryCodeAlpha2: 'GBP',
                            contingencies: ['SCA_WHEN_REQUIRED']
                        // Billing Address
                    }).then(function(payload) {
                        console.log(payload,'payload校验通过')
                        if (payload.liabilityShifted !== undefined) {
                        if (payload.liabilityShifted === false && payload.authenticationStatus === 'NO' && 
                          (payload.authenticationReason === 'SKIPPED_BY_BUYER' || payload.authenticationReason === 'FAILURE')) {
                          _this.errMsg =
                            'Failed to process your card payment. Please try another card.';
                          _this.showErr = true;
                          setTimeout(() => {
                            _this.showErr = false;
                          }, 4000)
                          _this.payLoadDone = true;
                          return;
                        }
                      }
                        // loading
                        _this.payLoadDone = false;
                        fetch('https://nweb.alleatapp.com/base/payPal/cardPay?token='+payOrderId, {
                                method: 'get',
                                headers: {
                                'Content-Type': 'application/json',
                                'refreshToken': localStorage.getItem('refreshToken'),
                                'token': localStorage.getItem('token'),
                            }
                        }).then(function(res) {
                            return res.json();
                        }).then(function (orderData) {
                            // 跳转支付成功
                            console.log(orderData,'orderData')
                            if(orderData.code == 200) {
                                setTimeout(()=>{
                                    _this.payLoadDone = true;
                                },300)
                                // _this.$router.push('/my?orderSuccess=1&type=orderDone')
                                _this.$router.push(`/orderSuccess?orderSuccess=1&type=orderDone&orderId=${_this.orderId}`)
                            } else {
                                _this.payLoadDone = true;
                                _this.errMsg = 'Failed to process your payment.';
                                _this.showErr = true;
                                setTimeout(()=>{
                                    _this.showErr = false;
                                },4000)
                            }												
                        })
                    }).catch(function(err) {
                        _this.$message.error(err)
                        _this.payLoadDone = true;
                        if(!err ||  JSON.stringify(err) == '{}') {
                            return
                        }
                        var errorMsg = 'Failed to process your payment.';
                        var errorDetail = Array.isArray(err.details) && err.details[0];
                        if (errorDetail) {
                            errorMsg += ' Error: ' + errorDetail.description;
                        }
                        // 这里是 支付报错 
                        _this.errMsg = errorMsg;
                        _this.showErr = true;
                        setTimeout(()=>{
                            _this.showErr = false;
                        },4000)
                        console.log('Payment could not be captured! ' + JSON.stringify(err),'支付失败')
                    })
                    .finally(err=>{
                        // _this.payLoadDone = true;
                    })
                });
            });
        } else {
            // Hides card fields if the merchant isn't eligible
            document.querySelector("#card-form").style = 'display: none';
            _this.showLoadError = true;
        }
    },
    detectBrowser() {
      const userAgent = navigator.userAgent;
      if (userAgent.indexOf("Chrome")!== -1 && userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Edg") === -1) {
        return "Google Chrome"; // Chrome
      } else if (userAgent.indexOf("Firefox") !== -1) {
        return "Mozilla Firefox"; // Firefox
      } else if (userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Chrome") === -1 && userAgent.indexOf("Edge") === -1) {
        return "Apple Safari"; // Safari
      } else if (userAgent.indexOf("Edg") !== -1) {
        return "Microsoft Edge"; // Edge
      } else {
        return "Unknown browser"; // 其他浏览器...（可根据自己需要确定是否新增其他浏览器的判断）
      }
    },
    uploadError(str) {
      try {
        const userAgent = navigator.userAgent;
        postGateway({
            url: '/customerApp/order/submitPaylog',
            method: "POST",
            data: {
              cardPayInfo: str + ` -${this.orderId} - card - ${this.detectBrowser()} - ${JSON.stringify(this.$route.query)} - ${userAgent}`,
            }
        }).then((res) => {
            console.log(res, "错误数据上报");
          })
          .catch((err) => {
            console.log("数据上报失败");
          });
          
      } catch (e) {
        console.log("数据上报错误处理");
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  },
};
</script>

<style lang="scss" scoped>
$greenColor: rgb(9, 202, 106);
$mainColor: #aa0bc0;
.checkout {
  min-height: 100vh;

  .content-box {
    position: relative;
    background-image: url('@/assets/images/notfoundBg.png');
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 360px;
    .match-box {
      position: relative;
      height: 100%;
    }
    .break-box {
      position: absolute;
      box-sizing: border-box;
      top: 48px;

      .break {
          height: 370px;
          height: 44px;
          display: inline-flex;
          align-items: center;
          padding: 20px;
          border-radius: 22px;
          background-color: #0A191E;
          color: #FFFFFF;
          .el-icon-back {
              color: #2680ED;
              font-size: 22px;
              margin-right: 10px;
              cursor: pointer;
          }
          :deep(.el-breadcrumb__inner) {
              font-weight: 600;
              color: #fff;
          }
          :deep(.el-breadcrumb__separator) {
              color: #fff;
          }
      }
  }
  .check-title {
    position: absolute;
    box-sizing: border-box;
    bottom: 48px;
    font-weight: 700;
    font-size: 70px;
    color: #fff;
    margin: 0;
  }
  .cuise-img {
      height: 259px;
      width: 370px;
      position: absolute;
      z-index: 1;
      right: 6%;
      top: 0px;
    }
    // tips
    .title-tips {
      font-family: "Poppins";
      font-size: 50px;
      color: #fff;
      font-weight: bold;
    }

    .title-desc {
      font-family: "Poppins";
      margin-top: 10px;
      font-size: 20px;
      color: #fff;
      letter-spacing: 0.5px;
    }
  }

  .page-title {
    display: flex;
    justify-content: center;
    font-size: 24px;
    color: #000;
    font-weight: 600;
    margin: 25px 0 10px;
  }

  .steps-box {
    margin: 20px auto;
    width: 1600px;
  }

  .main-content {
    margin: 20px auto;
    width: 1600px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .section {
      display: flex;
      flex-direction: column;
      margin-right: 3%;
    }
    .section-login {
      width: 924px;
    }
    .section-1 {
      width: 538px;
    }
    .section-2 {
      width: 476px;
    }
    .section-3 {
      width: 693px;
    }


  }

}

#card-form {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
  padding: 20px;
  border-radius: 12px;
  .title {
    text-align: center;
    padding-bottom: 20px;
    font-weight: 700;
  }
  .loading-box {
    display: flex;
    justify-content: center;
    font-size: 25px;
  }
  #submit {
    margin-top: 20px;
    background: #5469d4;
    color: #fff;
    font-weight: 600;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
  }
  #card-holder-name {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 12px;
    margin-top: 6px;
    margin-bottom: 16px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
    font-size: 17px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-weight: normal;
    resize: vertical;
  }
}

.paypal-button-container {
  width: 100%;
  max-width: 760px;
  padding: 20px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
}
.card_container {
  width: 100%;
  max-width: 760px;
  padding: 20px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
}
.card_field {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 12px;
  margin-top: 6px;
  margin-bottom: 16px;
  color: #3a3a3a;
  font-family: helvetica, tahoma, calibri, sans-serif;
  font-size: 17px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

button {
  border: none;
}
input {
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 20px;
}

@media screen and (max-width: 950px) {
  .checkout {
    min-height: unset!important;
    .content-box {
      height: 48px;
      .match-box {
        width: 100vw;

        .check-title {
          font-size: 20px;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          bottom: unset;
        }
        .break-box, .cuise-img {
          display: none;
        }

      }
    }
    .main-content {
      width: 100vw;
      #card-form {
        width: 97vw;
        #submit {
          font-size: 20px;
        }
      }
    }
  }
  
}

</style>

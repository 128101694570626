<template>
  <div>
    <div class="box6">
      <div class="content">
        <div class="card" v-for="(item, index) in contentArr2" :key="index">
          <div class="img-box">
            <div class="img">
              <img
                style="height: 100%; width: 100%"
                :src="item.img"
                alt="people"
              />
            </div>
          </div>
          <div class="title">{{ item.title }}</div>
          <div class="footer">{{ item.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contentArr2: [
        {
          img: require("@/assets/about/Group2.png"),
          title: "Customers",
          content:
            "All Eat is the UK’s first online food ordering platform which charges 0% commission from restaurants.",
        },
        {
          img: require("@/assets/about/Group.png"),
          title: "Restaurants",
          content:
            "With All Eat, you can create your own Shop. Simply fill the form and our team will contact you.",
        },
        {
          img: require("@/assets/about/Group3.png"),
          title: "Riders",
          content:
            "Driver consistently ensures the protection of food quality and exemplifies remarkable politeness.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.box6 {
  margin-top: 100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
  .content {
    display: flex;
    align-items: center;
    .card {
      .img-box {
        display: flex;
        justify-content: center;
        .img {
          width: 160px;
          height: 160px;
        }
      }
      .title {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        font-family: 'Inter', sans-serif;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 27px;
        line-height: 40px;
        /* or 148% */

        display: flex;
        align-items: center;
        text-align: center;

        color: #121212;
      }
      .footer {
        width: 320px;
        font-family: 'Inter', sans-serif;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        /* or 167% */

        display: flex;
        align-items: center;
        text-align: center;

        color: #656565;
      }
    }
  }
}
@media screen and (min-width:901px)and (max-width:1350px) {
      .box6{
        width:100%;
        height:100%;
        justify-content:center;
        .content
        {
          width: 80%;
          .card{
            .img-box{
              margin: auto;
              width: 15vw;
              .img{
                width: 15vw;
              }
            }
            .title{
              width:100%;
              height: 100%;
            }
            .footer{
              width:100%;
              height: 100%;
            }
          }
        }
      }
    }
@media screen and (max-width:900px) {
      .box6{
        width:100%;
        height:100%;
        padding: 1% 3%;
        margin-top: 20px;
        justify-content:center;
         .content{
          width: 90%;
          display: flex;
          flex-direction: column;
          .card{
            display: flex;
            flex-direction: column;
            margin-top:30px;
            .img-box{
              margin: auto;
              width: 80px;
              .img{
                width: 80px;
                height:80px;
              }
            }
          
            .title{
              width:100%;
              // font-size: 1.1rem;
            }
            .footer{
              // font-size: 0.8rem;
              padding:3% 3%;
              width:100%;
              height: 100%;
            }
          }
        }
      }
    }
</style>

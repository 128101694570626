<template>
    <div class="cart">
        <div class="view-info" @click="showMerchantAddress" >View Restaurant Information</div>
        <div class="book-btn" @click="showMerchantBook" v-if="info.dinein && info.merchantTableBooking">
            <img src="@/assets/icon/book_icon.png" alt="">
            Book a Table
        </div>
        <div :class="['cart-title', { 'showCardTitle': showCart && isMobile }]" >
            <i class="el-icon-shopping-bag-1"></i>
            <span style="flex:1;" v-if="!isMobile">Your Cart</span>
            <span  v-else style="margin-right: 15px;">Your Cart</span>
            <i class="el-icon-delete-solid" @click="handleRemoveCart"></i>
            <span style="flex:1;"></span>
            <i class="el-icon-error" v-if="isMobile" @click="showCart=false"></i>
        </div>
        <div :class="['main-cart', {'showCart': showCart && isMobile}]">
            <div class="cart-content" >
                <ul>
                    <li v-for="(food, ind) in cartList" :key="food.itemId + '-' +ind" class="cart-item">
                        <!-- <span class="index-num">{{ ind+1 }}.</span> -->
                        <div class="add-btn" style="display: flex;justify-content: center;" v-if="addCartLoading == food.itemId">
                            <i class="el-icon-loading"></i>
                        </div>
                        <span class="add-btn" v-else>
                            <i class="el-icon-minus" v-if="food.qty > 1" @click="reduceQty(food)"></i>
                            <i class="el-icon-delete" v-else @click="deleteFood(food)"></i>
                            <span>{{ food.qty }}</span>
                            <i v-if="!food.offId" class="el-icon-plus" @click="increaseQty(food)"></i>
                            <i v-else class="el-icon-plus" style="opacity:0;"></i>
                        </span>
                        <span class="name">
                            {{ food.itemName }} <br />
                            <div class="food-desc" v-if="food.chooseItems">
                                {{food.chooseItems}}
                                <template v-for="(addon) in food.subItems" >
                                    {{ ', ' + addon.subItemName }}
                                </template>
                            </div>
                        </span>
                        <span class="price" >{{ currencySign }} {{ food.price?(food.price*1).toFixed(2):food.price }}</span>
                        <!-- <div class="add-btn" style="display: flex;justify-content: center;" v-if="addCartLoading == food.itemId">
                            <i class="el-icon-loading"></i>
                        </div>
                        <span class="add-btn" v-else>
                            <i class="el-icon-minus" v-if="food.qty > 1" @click="reduceQty(food)"></i>
                            <i class="el-icon-delete" v-else @click="deleteFood(food)"></i>
                            <span>{{ food.qty }}</span>
                            <i v-if="!food.offId" class="el-icon-plus" @click="increaseQty(food)"></i>
                            <i v-else class="el-icon-plus" style="opacity:0;"></i>
                        </span> -->
                        
                    </li>
                </ul>
                <div class="empty-box" v-if="!cartList.length">
                    <img v-if="info.delivery" src="@/assets/menu/empty_cart2.png" alt="">
                    <img v-else src="@/assets/menu/empty_cart3.png" alt="">
                </div>
            </div>
            <div class="type-box">
                <div :class="['type-btn', { 'type-active': transType === 'delivery' }]" @click="changeTrans('delivery')" v-if="info.delivery">
                    <i class="iconfont icon-waimai"></i>
                    <span>Delivery</span>
                </div>
                <div :class="['type-btn', { 'type-active': transType === 'pickup' }]" @click="changeTrans('pickup')" v-if="info.pickup">
                    <i class="el-icon-s-goods"></i>
                    <span>Pick-up</span>
                </div>
                <div :class="['type-btn', { 'type-active': transType === 'dinein' }]" @click="changeTrans('dinein')" v-if="info.dinein">
                    <i class="el-icon-dish"></i>
                    <span>Dine-in</span>
                </div>
            </div>
            <div class="time-btn">
                {{ form.bookTime?form.bookTime:'Select Time' }} 
                <span class="value" v-if="dateLading">
                    <i class="el-icon-loading" ></i>
                </span>
                <i class="el-icon-arrow-right" v-else></i>
                <el-cascader class="cover-cascader" popper-class="timer-pop" :disabled="dateLading"
                    v-model="form.bookTime" :options="dateOptions" @change="handleChangeTime"></el-cascader>
            </div>
        </div>
        <transition name="fade" mode="out-in" >
            
        </transition>

        <div v-if="(isMobile && cartList.length) || !isMobile" :class="['order-btn',{'inactive-btn': !cartList.length || !totalPrice*1 || (totalPrice*1 < info[transType+'MinimumOrder']*1) || info.merchantCloseStore}]" >
            <span class="price-box" @click="showCart=!showCart" v-if="isMobile">
                <i class="el-icon-shopping-bag-1" ></i>
                <span class="red-point" >{{ cartList.length }}</span>
                {{currencySign}}{{ totalPrice }}
            </span>
            <span class="price-box" v-else>  {{currencySign}}{{ totalPrice }}  </span>
            
            <span style="flex:1;text-align:center;" v-if="info.merchantCloseStore">
                Merchant Closed
            </span>
            <span v-else-if="showOrderLoading" style="flex:1;text-align:center;" >
                <i class="el-icon-loading " ></i>
            </span>
            <span style="flex:1;text-align:center;" @click="orderNow"
                v-else-if="totalPrice && (totalPrice*1 >= info[transType+'MinimumOrder']*1)">
                Order Now
            </span>
            <span style="flex:1;text-align:center;font-size: 16px;" v-else>
                Minimum Order {{ currencySign }} {{ info[transType+'MinimumOrder'] }}
            </span>
        </div>
        <div class="limit-box" v-if="(isMobile && cartList.length) || !isMobile" >
            <span class="icon" >{{ currencySign }}</span>
            <span class="text">Minimum Order {{ currencySign }} {{ info[transType+'MinimumOrder'] }}</span>
        </div>

        <!-- 商家信息弹窗 -->
        <el-dialog
            title=""
            :visible.sync="showMerchantModal"
            :append-to-body="true"
            :close-on-click-modal="false"
            :width="isMobile?'96%':'580px'"
            :top="isMobile?'10px':'50px'">
            <div class="merchant-info" slot="title">
                <h3>Restaurant Information</h3>
            </div>
            <div class="merchant-info">
                <div class="map-box">
                    <gmap-map :center="{ lat: info.latitude*1, lng: info.lontitude*1 }"
                    :zoom="16" map-type-id="terrain" ref="myMap" style="width: 100%; height: 240px" >
                    <gmap-marker
                            :position="{ lat: info.latitude*1, lng: info.lontitude*1 }"
                            label=""
                        />
                    </gmap-map>
                </div>
                <div class="map-desc" >
                    <i class="el-icon-location"></i>
                    {{ info.merchantAddr }}
                </div>
                <div class="name-box" >
                    <img class="icon-img" src="@/assets/menu/Shop_light.png" alt="shop">
                    <span class="name" >{{ info.restaurantName }}</span>
                    <div class="icon-box" v-if="info.facebookPage || info.googlePage || info.telegramPage">
                        <div class="icon icon-facebook" @click="openWeb('facebookPage')" v-if="info.facebookPage">
                            <img src="@/assets/icon/facebook.png" alt="">
                        </div>
                        <div class="icon icon-google" @click="openWeb('googlePage')" v-if="info.googlePage">
                            <img src="@/assets/icon/google.png" alt="">
                        </div>
                        <div class="icon icon-telegram" @click="openWeb('telegramPage')" v-if="info.telegramPage" >
                            <img src="@/assets/icon/telegram.png" alt="">
                        </div>
                        <!-- <div class="icon share">Share</div> -->
                    </div>
                </div>
                <div class="richform-box" v-html="info.merchantInformation"></div>
                <div class="merchant-title" >Opening Time</div>
                <div class="time-box" >
                    <div class="tab-box" >
                        <ul>
                            <li v-for="item in tabsList" :key="item.key" :class="['tab-item',{'item-active': item.key==nowTab}]" @click="nowTab=item.key">{{ item.name }}</li>
                        </ul>
                    </div>
                    <div class="opentime-box">
                        <ul>
                            <li v-for="(item,ind) in timeList[nowTab]" :key="item.id">
                                <span class="name" >{{ timeMap[ind] }}</span>
                                <span class="time-line">{{ item.startTime1.replace('false','') }}-{{ item.endTime1.replace('false','') }}</span>
                                <span class="time-line">{{ item.startTime2.replace('false','') }}-{{ item.endTime2.replace('false','') }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="merchant-title" >Allergy Problem</div>
                <p class="content-text" >{{ info.allergyProblem }}</p>
                <div class="merchant-title" v-if="info.hygieneRating !='pass'&&info.hygieneRating*1>2" >Hygiene rating</div>
                <p class="content-text" v-if="info.hygieneRating !='pass'&&info.hygieneRating*1>2" >
                    <img v-if="info.hygieneRating" style="width: 180px;" :src="require('@/assets/menu/fhrs_'+info.hygieneRating+'_en-gb.jpg')" alt="FOOD HYGIENE RATING">
                    <img v-else style="width: 180px;" :src="require('@/assets/menu/fhrs_0_en-gb.jpg')" alt="FOOD HYGIENE RATING">
                </p>
            </div>
        </el-dialog>

        <!-- 商家预定弹窗 -->
        <el-dialog
            :visible.sync="showBookModal"
            :append-to-body="true"
            :close-on-click-modal="false"
            :width="isMobile?'96%':'540px'"
            center
            top="50px">
            <div class="book-title" slot="title">
                <h2>Book Table</h2>
                <div class="desc-1" v-if="!isMobile">in</div>
                <div class="desc-2" >{{ info.restaurantName }}</div>
            </div>
            <div class="book-form">
                <div class="form-line " >
                    <div class="input-box">
                        <span class="name">Name</span>
                        <div class="input-shadow">
                            <input class="input" type="text" v-model="bookForm.bookingName" placeholder="Name">
                        </div>
                    </div>
                    <div class="input-box" style="width: 160px;flex:none;margin-left:20px;">
                        <span class="name">Guest Number</span>
                        <div class="input-shadow">
                            <input class="input" type="number" v-model="bookForm.numberGuest" placeholder="Number">
                        </div>
                    </div>
                </div>
                <div class="form-line " >
                    <div class="input-box">
                        <span class="name">Email</span>
                        <div class="input-shadow">
                            <input class="input" type="text" v-model="bookForm.email" placeholder="Email">
                        </div> 
                    </div>
                </div>
                <div class="form-line " >
                    <div class="input-box">
                        <span class="name">Phone number</span>
                        <div class="phone-box" >
                            <PhoneBtn v-model="bookForm.code"></PhoneBtn>
                            <input type="text" v-model="bookForm.mobile" placeholder="Phone number">
                        </div>
                    </div>
                </div>
                <div class="form-line " >
                    <div class="input-box">
                        <span class="name">Booking Time</span>
                        <div class="input-shadow">
                            <input class="input" type="text" v-model="bookForm.bookingTime" placeholder="Book Time">
                        </div>
                        <el-cascader class="cover-cascader" popper-class="timer-pop"
                                v-model="bookForm.pickTime" :options="bookTimeOption" @change="handleChangeBookTime"></el-cascader>
                    </div>
                </div>
                <div class="form-line " v-if="merchantId==1397" >
                    <div class="input-box">
                        <span class="name">Korean BBQ / Chinese Food</span>
                        <div class="input-shadow" style="padding: 10px;">
                            <el-radio v-model="foodTypeDesc" border size="medium" label="Korean BBQ" >Korean BBQ </el-radio>
                            <el-radio v-model="foodTypeDesc" border size="medium" label="Chinese Food" >Chinese Food</el-radio>
                        </div>
                    </div>
                </div>
                <div class="form-line " >
                    <div class="input-box">
                        <span class="name">Booking Note</span>
                        <div class="input-shadow">
                            <el-input class="input-textarea" type="textarea" placeholder="Add note..." rows="4" v-model="bookForm.bookingNotes" maxlength="300" show-word-limit></el-input>
                        </div>
                    </div>
                </div>

                <div class="btn-box" >
                    <div class="btn btn-cancel" @click="showBookModal=false">Cancel</div>
                    <div class="btn" @click="confirmBook">
                        <i class="el-icon-loading" v-if="bookLoading"></i>
                        <span v-else>Confirm</span>
                    </div>
                </div>
                
            </div>
        </el-dialog>

        <!-- 商家预定弹窗 -->
        <el-dialog
            :visible.sync="fillTableNumberModal"
            :append-to-body="true"
            :close-on-click-modal="false"
            :width="isMobile?'96%':'540px'"
            center
            top="100px">
            <div class="book-title" slot="title">
                <h2>Table Number</h2>
                <div class="desc-1" v-if="!isMobile">in</div>
                <div class="desc-2" >{{ info.restaurantName }}</div>
            </div>
            <div class="book-form" style="min-height:200px;padding-top: 50px;" >
                <div class="form-line " >
                    <div class="input-box">
                        <!-- <span class="name">Table Number</span> -->
                        <div class="input-shadow">
                            <input class="input" type="text" v-model="tableNumber" placeholder="Your Table Number">
                        </div> 
                    </div>
                </div>

                <div style="height: 80px;" ></div>

                <div class="btn-box" >
                    <div class="btn btn-cancel" @click="fillTableNumberModal=false">Cancel</div>
                    <div class="btn" @click="fillTableNumberModal=false">
                        <span>Confirm</span>
                    </div>
                </div>
                
            </div>
        </el-dialog>

    </div>
</template>
<script>
import qs from "qs";
import { removeCart } from '@/utils/manageCart';
import PhoneBtn from "../PhoneBtn.vue";
import { gmapApi } from "vue2-google-maps"
import { postGateway } from '@/request';
import { setCart } from '@/utils/manageCart';
export default {
    model: {
        prop: 'cartList',
        event: 'input'
    },
    components: {
        PhoneBtn
    },
    props: {
        dateLading: {
            type: Boolean,
            default: true
        },
        info: {
            type: Object,
            default: () => {
                return {
                    deliveryDistance: '',
                    latitude: 0,
                    lontitude: 0,
                    restaurantName: ''
                }
            }
        },
        cartList: {
            type: Array,
            default: () => []
        },
        dateOptions: {
            type: Array,
            default: () => []
        },
        tableBookMultiSelector: {
            type: Array,
            default: () => []
        },
        merchantId: {
            type: [Number,String],
            default: ''
        },
        trans: String,
    },
    watch: {
        'showMerchantModal': function(val) {
            this.$nextTick(()=>{
                console.log(val,'执行了更新图片', this.$refs.myMap)
            })
            this.nowTab = 'delivery';
            this.getTimeList();
        },
        'showCart': function(val) {
            if(this.isMobile) {
                this.$emit('handeShowMask', val)
            }
        },
        'trans': function(val) {
            this.transType = val;
            if(this.transType == 'dinein') {
                setTimeout(() => {
                    this.fillTableNumberModal = true;
                }, 8000);
            }
        }
    },
    computed: {
        totalPrice() {
            let arr = this.cartList.map(x=>x.price).length?this.cartList.map(x=>(x.price*x.qty).toFixed(2)):[0]
            let res = arr.reduce((a,b)=>a*1+b*1) * 1;
            return res.toFixed(2)
        },
        google: gmapApi,
        isMobile() {
            return window.innerWidth < 950
        },
        currencySign() {
            return this.info.currencySign || ''
        },
        bookTimeOption() {  // 订桌 ASAP 问题处理
            let list = [...this.tableBookMultiSelector];
            if(list.length && list[0].children && list[0].children.length) {
                if(list[0].children[0].value == 'asap') {
                    list[0].children[0].value = ''
                    list[0].children[0].label = ''
                } 
            }
            return list
        }
        // 判断是否有时间限制问题
        // canOrderByPreOrder() {
            
        // }
    }, 
    data() {
        return {
            transType: 'pickup',
            nowTab: 'pickup',
            isMapFirstInit: true,
            bookLoading: false,
            showCart: false,
            fillTableNumberModal: false,
            tableNumber: '',
            beingChooseTransType: false,
            timeMap: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
            ],
            timeList: {
                'delivery': [],
                'pickup': [],
                'dinein': []
            },
            addCartLoading: null,
            bookForm: {
				clientId: localStorage.getItem('clientId'),
                code: (this.$isUk?'+44':'+353'),
				bookingName: '',  //预定人名称
				mobile: '',
				email: '',
				bookingNotes: '', // 备注
				numberGuest: '', // 预定人数
				bookingTime: '', // 预定时间
				merchantName: '',
				merchantId: this.info.restaurantName,
			},
            form: {
                tableware: '',
				payWay: 0,
				tipCount: 0,
				bookTime: 'As soon as possible',
				remark: '',
				merchantDeliveryCharges: 0,  // 配送费
				serviceFee: 0, //服务费
				packagingFee: 0,  // 打包费
				pointsEarned: 0,  // 获取积分
				spendPoint: 0,   // 使用积分
				subTotal: 0,  //小计
				discount: 0,  // 折扣
				voucherAmount: 0,  // 优惠券优惠
				tip: 0,	// 小费
				total: 0,  // 总计
				deliveryAsap: 1,   // 是否立即送出
				deliveryDateStr: '',   // 配送时间
				voucherId: null,   // 下单使用的优惠券
				pointTop: 0,  // 最多能用多少积分
				isPoint: 0,  // 是否嫩使用积分
            },
            showMerchantModal: false,
            showBookModal: false,
            markers: [],
            tabsList: [
                { name: 'Delivery', key: 'delivery' },
                { name: 'Pick-up', key: 'pickup' },
                { name: 'Dine-in', key: 'dinein' },
            ],
            showOrderLoading: false,
            foodTypeDesc: '',
        }
    },
    mounted() {
        this.transType = this.trans;
        this.showOrderLoading = false;

        // setTimeout(() => {
        //     if(window.injectProperty && window.injectProperty.isSsg == 1) {
        //         this.fillTableNumberModal = false;
        //     }
            
        // }, 2000);
    },
    methods: {
        openWeb(key) {
            if(this.info[key])
                window.open(this.info[key])
        },
        toggleInfoWindow() {

        },
        judgeRequired() {
			for (let key in this.bookForm) {
				if(!this.bookForm[key] && key != 'clientId') {
					return false
				}
			}
            return true
		},
        getTimeList() {
            postGateway({
                url: '/merchant/merchantOperateTime/getList',
                method: 'GET',
                data: {
                    merchantId: this.merchantId
                }
            }).then(res=>{
                this.timeList = res.data;
            })
            .catch(err=>{
                console.log(err,'获取营业时间报错')
            })
        },
        // 修改预约时间
        handleChangeBookTime(val) {
            let [date, time] = val;
            let dateStr;
            if(date) {
                let [mon, day, year] = date.split('/');
                dateStr = `${year}/${mon}/${day}`
            }
            this.bookForm.bookingTime = dateStr + ' ' + time;
            console.log(this.bookForm.bookingTime, 'form')
        },
        // 确认预约
        confirmBook() {
            if(this.bookLoading) return
            this.bookForm.merchantName = this.info.restaurantName;
            this.bookForm.merchantId = this.info.merchantId;

            // 检验输入框
			if(!this.judgeRequired()) {
                this.$message.warning('Check your form please')
                console.log(this.bookForm, 'bookForn')
                return
            }

            if(this.merchantId == 1397 && !this.foodTypeDesc) {
                this.$message.warning('Please choose Korean BBQ or Chinese Food');
                return
            }

            let param = {
                ...this.bookForm,
                clientId: localStorage.getItem('clientId'),
                merchantName: this.info.restaurantName,
                merchantId: this.info.merchantId,
                mobile: this.bookForm.code + this.bookForm.mobile
            }

            if(this.foodTypeDesc) {
                param.bookingNotes = this.foodTypeDesc + '; ' + param.bookingNotes;
            }
            
            console.log(param,'param')

     
            postGateway({
                url: '/merchant/bookingTable/appAdd',
                data: param
            }).then(res=>{
                console.log(res,'res book')
                this.bookLoading = false;
                this.showBookModal = false;
                this.$message.success('Booking Success!')
            })
            .catch(err=>{
                this.bookLoading = false;
                console.log(err,'预定报错')
            })
            
            // console.log(this.bookForm, 'bookForm')
        },
        showMerchantAddress() {
            this.showMerchantModal = true;
        },
        showMerchantBook() {
            this.showBookModal = true;
        },
        handleRemoveCart() {
            this.$alert('Empty your basket?', 'Warning', {
                confirmButtonText: 'Confirm',
                center: true,
                callback: (action) => {
                    if(action == 'confirm') {
                        removeCart(this.merchantId)
                        this.$emit('input', [])
                        this.$emit('clearCart', []);
                    }
                }
            });
        },
        setBookTime(val) {
            console.log('还真的执行了这里')
            this.form.bookTime = val;
            if(!val) {
                this.form.deliveryAsap = 0
            }
        },
        handleChangeTime(val) {
            let [date, time] = val;
            let dateStr;
            if(date) {
                let [mon, day, year] = date.split('/');
                dateStr = `${year}/${mon}/${day}`
            }
            if(time == 'asap') {  // 立即送出
				this.form.deliveryAsap = 1;
				this.form.deliveryDateStr = '';
			} else {
				this.form.deliveryAsap = 0;
				this.form.deliveryDateStr = dateStr + ' ' + time;
			}
            if(this.form.deliveryAsap != 1 && !time) {
                this.form.bookTime = ''
                return
            }
			this.form.bookTime = this.form.deliveryAsap == 1? 'As soon as possible':this.form.deliveryDateStr.replace(/\//g,'-');  // 表单回显
            console.log(this.form, 'form')
        },
        changeTrans(type) {
            this.transType = type;
            this.beingChooseTransType = true;
            this.$emit('changeTransType',type)
        },
        // 校验是否能加入购物车
        checkToCart(param) {
            this.addCartLoading = param.itemId;
            return postGateway({
                url: '/customerApp/cart/checkAddAppCart',
                data: {
                    ...param,
                    merchantId: this.info.merchantId
                }
            })
        },
        increaseQty(food) {
            food.qty++;
            this.checkToCart(food).then(()=>{
                this.$emit('cartChangeQty', food);
                this.addCartLoading = null;
            })
            .catch(err=>{
                if(err.code != 50032) {
                    this.$message.error(err.msg)
                }
                this.addCartLoading = null;
                food.qty--;
            })
        },
        reduceQty(food) {
            food.qty>1?food.qty--:food.qty=0
            if(food.qty == 0) {
                this.deleteFood(food);
                return
            }
            this.$emit('cartChangeQty', food)
        },
        deleteFood(food) {
            food.qty = 0;
            let list = this.cartList.filter(x=>x.qty>0);
            this.$emit('input', list)
            this.$emit('cartChangeQty', food)
        },
        deving() {
            this.$message.info('Developing!')
        },
        removeCartPreOrder() {
            if(!localStorage.getItem('clientId')) {
                this.showOrderLoading = false;
                return Promise.resolve(true)
            }
            let param = {
                merchantId: this.merchantId * 1,
                clientId: localStorage.getItem('clientId')
            }
            return postGateway({
                url: '/customerApp/cart/cleanCart',
                data: param
            })
            .then(res => {
                this.showOrderLoading = false;
                console.log(res, '清除购物车成功')
            })
            .catch(err => {
                this.showOrderLoading = false;
                this.$message.warning('Net Error')
                console.log(err, '清除购物车失败')
            })
        },
        // 去下单
        async orderNow() {

            // 购物车没有内容
            if(!this.cartList.length) {
                return
            }
            if(!this.beingChooseTransType) {
                this.$emit('showTransTypeModal', true)
                this.beingChooseTransType = true;
                return
            }

            this.showOrderLoading = true;
            console.log(this.cartList ,'cartlist')

            await this.removeCartPreOrder();
            
            let { deliveryAsap, bookTime, deliveryDateStr } = this.form;
            let timestamp = new Date().valueOf();
            let param = {
                bookTime,
                deliveryAsap,
                transType: this.transType,
                deliveryDateStr: deliveryDateStr,
                merchantId: this.merchantId,
                tableNumber: this.transType == 'dinein'?this.tableNumber:'',
                timestamp
            }

            // 如果这个用户没有选择时间并且是 夜市的商家默认选择asap
            if(!deliveryDateStr && !deliveryAsap && this.transType == 'pickup' && this.merchantId == 2468) {
                param.deliveryAsap = 1;
                param.deliveryDateStr = 'As soon as possible'
            }

            this.showOrderLoading = false;

            setCart(this.merchantId, this.cartList)

            // 清空上一次的remark
            localStorage.removeItem(`${this.merchantId}-FoodRemark`)
            
            this.$nextTick(()=>{
                this.$router.push({
                    path: `/checkout?${qs.stringify(param)}`
                })
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .cart {
        width: 360px;
        font-family: "Source Sans Pro", sans-serif;

        .view-info, .book-btn {
            margin-top: 20px;
            height: 48px;
            border-radius: 24px;
            background-color: #09CA6A;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 20px;
            box-shadow: 0px 5px 8px #8F9698;
            transition: all ease-in-out .2s;
            cursor: pointer;
            &:hover {
                box-shadow: 0px 4px 6px #505454;
            }
        }
        .book-btn {
            background-color: #fff;
            color: #AA0BC0;
            font-size: 20px;
            img {
                width: 32px;
                margin-right: 20px;
            }
        }
        .cart-title {
            height: 48px;
            padding: 0 20px;
            font-size: 18px;
            color: #000;
            display: flex;
            align-items: center;
            font-weight: 700;
            box-shadow: 0px 4px 4px rgba($color: #000000, $alpha: 0.2);
            background-color: #fff;
            margin-top: 20px;
            cursor: default;
            border-radius: 2px;
            i {
                margin-right: 10px;
                font-size: 22px;
                font-weight: bold;
            }
            .el-icon-delete-solid {
                margin-right: 0;
                cursor: pointer;
                &:hover {
                    color: #8F9698;
                }
            }
        }
        .main-cart {
            min-height: 350px;
            margin-top: 15px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0px 4px 4px rgba($color: #000000, $alpha: 0.2);
            padding: 0  0 20px 20px;
            display: flex;
            flex-direction: column;

            .cart-content {
                margin-right: 20px;
                min-height: 200px;
                flex: 1;
                border-bottom: 1px solid black;
                max-height: 360px;
                overflow: auto;
                padding-top: 10px;

                .empty-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 100%;

                    img {
                        width: 70%;
                        margin-top: 10px;
                    }
                }

                ul {
                    padding: 0;
                    margin: 0;
                }

                .cart-item {
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .index-num {
                        font-size: 12px;
                        color: #09a054;
                        font-weight: bold;
                        margin-right: 10px;
                    };
                    .name {
                        font-weight: 600;
                        flex: 1;
                        color: #000;
                        white-space: pre-wrap;
                        word-break: normal;
                        .food-desc {
                            font-size: 10px;
                            font-weight: 400;
                            color: gray;
                        }
                    }
                    .price {
                        padding: 0 10px;
                        font-weight: 600;
                        text-align: left;
                        min-width: 80px;
                        display: inline-flex;
                        justify-content: flex-start;
                        color: #000;
                    }
                    .add-btn {
                        min-width: 70px;
                        height: 26px;
                        border: 2px solid #AA0BC0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-radius: 20px;
                        font-size: 15px;
                        color: #000;
                        font-weight: 600;
                        padding: 0 8px;
                        cursor: pointer;
                        transition: all ease-in .2s;

                        span {
                            padding: 0 5px;
                        }

                        i {
                            color: #AA0BC0;
                            font-weight: 700;
                            font-size: 12px;
                        }

                        &:hover {
                            background-color: #AA0BC0;
                            border: 2px solid #fff;
                            color: #fff;

                            i {
                                color: #fff;
                            }
                        }
                    }
                    .el-icon-delete  {
                        margin-left: 4px;
                        font-size: 20px;
                        cursor: pointer;
                    }
                }
            }
            .type-box {
                padding-right: 20px;
                height: 40px;
                display: flex;
                margin-top: 10px;

                .type-btn {
                    flex: 1;
                    width: 60px;
                    background-color: rgba($color: #000000, $alpha: 0.2);
                    border-radius: 4px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                    font-size: 17px;
                    font-weight: 600;
                    color: #000;
                    cursor: pointer;
                    margin-right: 12px;
                    transition: all ease-in-out .2s;
                    &:hover {
                        background-color: rgba($color: #000000, $alpha: 0.1);
                    }
                    &:nth-last-child(1) {
                        margin-right: 0;
                    }
                    i {
                        color: #000;
                        margin-right: 5px;
                        font-size: 20px;
                        display: none;
                    }
                }
                .type-active {
                    background-color: #09CA6A;
                    color: #fff;
                    i {
                        color: #fff;
                        display: inline-block;
                    }
                    &:hover {
                        background-color: #09CA6A;
                    }
                }
            }
            .time-btn {
                margin-top: 10px;
                height: 40px;
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #000;
                font-weight: 600;
                justify-content: space-between;
                padding: 0 20px;
                margin-right: 20px;
                background-color: #fff;
                border-radius: 4px;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                transition: all ease-in-out .2s;
                cursor: pointer;
                position: relative;

                &:hover {
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
                }

                i {
                    margin-left: 20px;
                }
                .cover-cascader {
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    
                }
            }
            .coupon-box {
                margin-right: 20px;
                margin-top: 10px;
                height: 40px;
                border-radius: 20px;
                background-color: #fff;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                font-weight: 500;
                font-size: 16px;
                display: flex;
                align-items: center;

                input {
                    flex: 1;
                    height: 40px;
                    color: #000;
                    padding-left: 20px;
                    overflow: hidden;
                    border-radius: 20px;
                }

                .apply-btn {
                    height: 40px;
                    border-radius: 20px;
                    padding: 0 20px;
                    font-size: 16px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #09CA6A;
                    white-space: nowrap;
                    font-weight: 600;
                    color: #fff;
                    transition: all ease-in-out 0.2s;
                    cursor: pointer;
                    &:hover {
                        background-color: #07b75f;
                    }
                }
            }
        }
        .order-btn {
            margin-top: 10px;
            height: 56px;
            display: flex;
            align-items: center;
            background-color: #07b75f;
            color: #fff;
            font-size: 20px;
            font-weight: 800;
            border-radius: 28px;
            padding-right: 30px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            cursor: pointer;
            transition: all ease-in-out .2s;
            // &:hover {
            //     background-color: #09a054;
            // }

            .price-box {
                height: 56px;
                border-radius: 28px;
                padding: 0 30px;
                min-width: 100px;
                background-color: #fff;
                color: #000;
                display: flex;
                align-items: center;
            }
        }

        .inactive-btn {
            background-color: #cccccc;
            color: #fff;
            &:hover {
                background-color: #cccccc;
            }
        }

        .limit-box {
            margin: 0 20px;
            background: #AA0BC0;
            color: #fff;
            display: flex;
            align-items: center;
            height: 36px;
            border-radius: 0 0 6px 6px;
            padding: 0 12px;

            .icon {
                font-size: 20px;
                font-weight: bold;
                margin-right: 20px;
            }
            span {
                font-weight: bold;
                font-size: 14px;
            }
        }
    }
    
    // 商家信息弹窗
    .merchant-info {
        color: #111;
        h3 {
            font-weight: 700;
            font-size: 24px;
            margin: 0 0 10px;
            text-align: center;
        }
        .map-box {
            border-radius: 16rpx;
            overflow: hidden;
            height: 260rpx;
            margin-top: -20px;
        }
        .map-desc {
            margin-top: 10px;
            font-size: 16px;
            color: #000;
            text-align: left;
            
            .el-icon-location {
                font-size: 20px;
                margin-right: 4px;
                color: #000
            }
        }
        .name-box {
            display: flex;
            align-items: center;
            margin-top: 10px;

            .icon-img {
                height: 35px;
                width: 35px;
                margin-right: 2px;
                position: relative;
                left: -5px;
            }
            .name {
                font-size: 16px;
                color: #000;
                margin-right: 12px;
            }
            .icon-box {
                flex: 1;
                height: 46px;
                display: inline-flex;
                align-items: center;

                .icon {
                    height: 30px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 20px;
                    box-shadow: 0px 4px 4px rgba($color: #000000, $alpha: 0.2);
                    cursor: pointer;
                    img {
                        height: 25px;
                        width: 25px;
                    }
                }
                .icon-facebook {
                    background-color: #1877F2;
                    img {
                        height: 20px;
                        width: 20px;
                    }
                }
                .icon-google {
                    background-color: #fff;
                }
                .icon-telegram {
                    i {
                        color: #fff;
                        font-size: 30px;
                    }
                }
                .share {
                    font-size: 30px;
                    font-weight: 700;
                    color: #000;
                    box-shadow: none;
                    margin-left: 25px;
                }

            }
        }
        .richform-box {
            margin-top: 10px;
            max-height: 200px;
            overflow: auto;
            word-break: break-word;
        }
        .merchant-title {
            font-weight: bold;
            color: #000;
            font-size: 18px;
            margin: 10px 0 20px;
        }
        .time-box {
            background: #e0e0e0;
            padding: 12px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            .tab-box {
                display: flex;
                .tab-item {
                    flex: 1;
                    height: 32px;
                    min-width: 60px;
                    padding: 0 10px;
                    background-color: rgba($color: #000000, $alpha: 0.2);
                    border-radius: 4px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                    font-size: 17px;
                    font-weight: 600;
                    color: #000;
                    cursor: pointer;
                    margin-right: 12px;
                    transition: all ease-in-out .2s;
                    &:hover {
                        background-color: rgba($color: #000000, $alpha: 0.1);
                    }
                    &:nth-last-child(1) {
                        margin-right: 0;
                    }
                    i {
                        color: #000;
                        margin-right: 5px;
                        font-size: 20px;
                    }
                }
                .item-active {
                    background-color: #09CA6A;
                    color: #fff;
                    i {
                        color: #fff;
                    }
                    &:hover {
                        background-color: #09CA6A;
                    }
                }
            }
            .opentime-box {
                margin-top: 10px;
                background: #fff;
                border-radius: 16px;
                padding: 12px;
                ul li {
                    height: 34px;
                    border-bottom: 1px #e0e0e0 dashed;
                    display: flex;
                    align-items: center;
                    color: #000;

                    .name {
                        flex: 1;
                    }
                    .time-line {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .book-title {
        color: #000;
        font-family: 'Source Sans Pro', sans-serif;
        h2 {
            font-weight: 700;
            font-size: 35px;
            margin: 0 0 5px;
        }
        .desc-1 {
            font-size: 18px;
        }
        .desc-2 {
            font-size: 22px;
        }
    }
    .book-form {
        min-height: 500px;
        border-radius: 12px;
        padding: 0 20px;
        .form-line {
            display: flex;
            margin-top: 14px;
            .input-box {
                flex: 1;
                position: relative;
                .cover-cascader {
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                }
                .name {
                    font-size: 16px;
                    color: #121212;
                    font-weight: 700;
                }
                .input-shadow {
                    box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
                    margin-top: 12px;
                }
                .input {
                    padding-left: 20px;
                    height: 100%;
                    width: 100%;
                    height: 52px;
                    border-radius: 12px;
                    font-size: 16px;
                }
                .input-textarea {
                    height: auto;
                }
                :deep(.el-textarea__inner) {
                    border: none;
                    font-size: 16px;
                    color: #000;
                }
                .phone-box {
                    display: flex;
                    align-items: center;
                    box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
                    height: 52px;
                    margin-top: 12px;
                    border-radius: 12px;

                    input {
                        flex: 1;
                        padding-left: 10px;
                        color: #000;
                        font-size: 16px;
                    }
                }
            }
        }
        .btn-box {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .btn {
                height: 52px;
                width: 100px;
                border-radius: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #09CA6A;
                color: #fff;
                font-weight: 600;
                cursor: pointer;
                transition: all ease-in .2s;
                &:hover {
                    background: #09a859;
                }
            }
            .btn-cancel {
                border: 1px solid #AA0BC0;
                background: #fff;
                color: #AA0BC0;
                &:hover {
                    background: #AA0BC0;
                    color: #fff;
                }
            }
        }
    }
    :deep(.el-dialog) {
        border-radius: 12px;
    }
    :deep(.el-dialog__header) {
        padding: 15px 10px 0px;
    }

    // 手机相关布局
    @media screen and (max-width: 950px) {
        .cart {
            width: 100vw;

            .view-info, .book-btn, .main-cart {
                display: none;
            }
            .order-btn {
                margin-top: 0;
                .price-box {
                    position: relative;
                    .red-point {
                        position: absolute;
                        height: 28px;
                        width: 28px;
                        border-radius: 14px;
                        font-weight: bold;
                        color: #fff;
                        top: -5px;
                        left: 12px;
                        font-size: 20px;
                        background: #eb4d4b;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .el-icon-shopping-bag-1 {
                        margin-right: 10px;
                        font-size: 30px;
                        font-weight: bold;
                    }
                }
            }
            .cart-title {
                display: none;
                box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
                // padding-top: 10px;
                border-bottom: 1px solid black;
                .el-icon-error {
                    position: relative;
                    // right: -0px;
                    font-size: 30px;
                    color: #AA0BC0;
                }
            }
            .main-cart {
                margin-top: 0;
                min-height: 250px;
                display: none;
                overflow: hidden;

                

            }
            .showCart {
                display: block;
                .time-btn {
                    display: none;
                }
            }
            .showCardTitle {
                display: flex;
            }
        }

        // 预定弹窗
        .book-title {
            h2 {
                font-size: 22px;
                margin-bottom: 5px;
            }
            .desc-1 {
            font-size: 13px;
            }
            .desc-2 {
                font-size: 16px;
            }
        }
        .book-form {
            margin-top: -20px;
            .form-line {
                .input-box {
                    .name {
                        font-size: 14px;
                    }
                    .input-shadow {
                        margin-top: 5px;
                        input {
                            height: 36px;
                        }
                    }
                }
            }
            .btn-box {
                margin-top: 10px;
                .btn  {
                    height: 46px;
                    width: 90px;
                }
            }
        }
    }

</style>
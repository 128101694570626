import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import VueRouter from "vue-router";
import { postGateway } from "./request/index";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import moment from "moment";
import Viewer from "v-viewer";
import JSEncrypt from "@/utils/jsencrypt.js";
import "viewerjs/dist/viewer.css";
import "@/assets/iconfont/iconfont.css";
import * as VueGoogleMaps from 'vue2-google-maps';
import { isUkIp } from "@/utils/judgeUseGoogle.js";
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import bus from "@/utils/bus";

// 设置语言
locale.use(lang)

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBX0UMaV_4_YvC-EAJ7Ctk3J0gw599Ma6o',
        libraries: '3.26'
    }
})

Vue.use(Viewer);
Viewer.setDefaults({
    Options: {
        inline: true,
        button: true,
        navbar: true,
        title: true,
        toolbar: true,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
        url: "data-source",
    },
});
// 登录配置内容  密码加密
Vue.prototype.$encryptByPublicKey = function(content) {
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(
        "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAMEfmsO1l44W6/RS9Hkx74Ey36aHExrCvjFYhRVP7/Q+8SG+vWFxMvGShQTA8EfQS2ECgDhVc+LFB14qhFl/JEcCAwEAAQ=="
    );
    return encrypt.encrypt(content);
};

import {
    Button,
    Select,
    Backtop,
    Breadcrumb,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    BreadcrumbItem,
    Dialog,
    Radio,
    RadioGroup,
    Rate,
    Skeleton,
    SkeletonItem,
    Popover,
    Message,
    Carousel,
    CarouselItem,
    CascaderPanel,
    Cascader,
    Loading,
    Alert,
    MessageBox,
    Input,
    Form,
    Col,
    Row,
    FormItem,
    Divider,
    Option,
    Steps,
    Step,
    Upload,
    RadioButton,
    InputNumber,
    Drawer,
    Popconfirm
} from "element-ui"; // 按需引入 element

Vue.use(Button);
Vue.use(Select);
Vue.use(Backtop);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Dialog);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Rate);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(Popover);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(CascaderPanel);
Vue.use(Cascader);
Vue.use(Input);
Vue.use(Loading);
Vue.use(Alert);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Upload);
Vue.use(Popconfirm);

Vue.use(Form);
Vue.use(Col);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Row);
Vue.use(Divider);
Vue.use(Option);
Vue.use(Drawer);


// 处理message
//定义一个新的Message方法，多传入一个offset参数
const $message = options => {
    return Message({
        offset: 200,
        ...options
    });
};

//重写方法,将offset写入options
['success', 'warning', 'info', 'error'].forEach(type => {
    $message[type] = options => {
        if (typeof options === 'string') {
            options = {
                message: options,
                offset: 200
            };
        }
        if (typeof options === 'object') {
            options.type = type;
        }
        return Message(options);
    };
})

Vue.use(VueAwesomeSwiper);
Vue.use(VueRouter);
Vue.config.productionTip = false;
Vue.prototype.$postGateway = postGateway;
Vue.prototype.$moment = moment;
Vue.prototype.$message = $message;
Vue.prototype.$bus = bus;
Vue.prototype.$message.closeAll = Message.closeAll;

Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$imgBaseUrl = 'http://nweb.alleatapp.com/food/img/'; // 上传图片前缀地址
Vue.prototype.$isUk = getDevelopSetting();

function getDevelopSetting() {
    if(localStorage.getItem('ipLocat') == 'UK') {
        Vue.prototype.$isUk = true;
    }
    if(localStorage.getItem('ipLocat') == 'Ireland') {
        Vue.prototype.$isUk = false;
    }
    return localStorage.getItem('ipLocat') == 'UK'
}


if(!localStorage.getItem('ipLocat')) {
    isUkIp().then(() => {
        Vue.prototype.$isUk = true;
        localStorage.setItem('ipLocat', 'UK')
        getDevelopSetting();
        
    }).catch(() => {
        Vue.prototype.$isUk = false;
        localStorage.setItem('ipLocat', 'Ireland')
        getDevelopSetting();
    })
} else {
    getDevelopSetting()
}

new Vue({
    el: "#app",
    render: (h) => h(App),
    router,
    mounted: () => {
        setTimeout(() => {
            document.dispatchEvent(new Event("render-event"));
        }, 5000);
    },
});
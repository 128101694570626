<template>
  <!-- cookie声明 -->
  <div class="cookieDiv" v-if="cookieDivOr">
    <span>By continuing to use our Site, you consent to our use of cookies and other relevant technologies as described in 
      this <a href="/Privacy-Policy" style="color: rgb(87, 87, 254);">Cookie Policy</a>.
    </span>
    <div class="aDiv">
      <a href="##" @click="closeDiv">Confirm</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cookieDivOr: false,
    };
  },
  mounted() {
    this.checkCookie();
  },
  computed: {
    isMobile() {
			return window.innerWidth < 950
		}
  },
  methods: {
    // cookie
    getCookie: function (cname) {
      var name = cname + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1);
        if (c.indexOf(name) != -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    setCookie: function (cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires;
    },
    clearCookie: function () {
      this.setCookie("beingSetCookie", "", -1000); //设置天数
    },
    checkCookie: function () {
      var user = this.getCookie("beingSetCookie");
      if (user != "") {
        this.cookieDivOr = false;
      } else if (!this.isMobile){
        this.cookieDivOr = true;
      }
    },
    closeDiv() {
      this.setCookie("beingSetCookie", "1", 1000); //设置天数
      this.cookieDivOr = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cookieDiv {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 88px;
  margin: 0 auto;
  font-weight: bold;
  background: #000;
  font-family: "Source Sans Pro", sans-serif;
  word-spacing: 1px;
}
.cookieDiv span {
  margin-left: 20px;
  color: #f0f3fb;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.aDiv {
  margin-left: 30px;
}
.cookieDiv .aDiv a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 48px;
  color: white;
  font-size: 18px;
  text-decoration: none;
  background: #d35400;
  border-radius: 30px;
  transition: all .2s ease-in-out;

  &:hover {
    background: red;
  }
}
@media screen and (max-width: 950px) {
  .cookieDiv {
    min-height: 88px;
    padding: 10px;
    span {
      text-align: left;
      font-weight: normal;
      height: auto;
      font-size: 14px;
    }
  }
}
</style>

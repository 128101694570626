<template>
    <div class="download-app-modal">
        <el-dialog
            title="" :width="isMobile?'80%':'580px'"
            :visible="showModal" :show-close="false" custom-class="download-modal"
            :before-close="handleClose">
            <div>
                <img :style="{'width':(isMobile?'100%':'580px')}" src="@/assets/images/download_modal_bg.png" alt="bg">
                <div class="close-btn" @click="handleClose" ></div>
                <div class="download-box" >
                    <span class="btn">
                        <a href="https://play.google.com/store/apps/details?id=io.dcloud.uniAllEat" target="_blank"></a>
                    </span>
                    <span class="btn">
                        <a href="http://itunes.apple.com/cn/app/id1603420846?mt=8" target="_blank"></a>
                    </span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "DownloadAppModal",
        prop: {
            model: 'value',
            event: 'input',
        },
        props: {
            value: {
                type: Boolean,
                default: false
            },
            seconds: {
                type: Number,
                default: 3000
            }
        },
        data() {
            return {
                showModal : false
            }
        },
        computed: {
            isMobile() {
                return window.innerWidth < 950
            }
        },
        mounted() {
            setTimeout(() => {
                this.$emit('input', true);
            }, this.seconds);
        },
        methods: {
            handleClose() {
                this.$emit('input', false);
            }
        }
    }
</script>

<style scoped lang="scss" >
    .download-app-modal {
        :deep(.download-modal) {
            background-color: transparent;
            position: relative;
            .el-dialog__header {
                display: none;
            }
            .el-dialog__body {
                padding: 0;
            }
            img {
                width: 580px;
            }
            .close-btn {
                position: absolute;
                z-index: 20;
                // background-color: #fff;
                height: 40px;
                width: 40px;
                cursor: pointer;
                right: 0px;
                top: 0px;
            }
            .download-box {
                position: absolute;
                z-index: 20;
                height: 100px;
                width: 100%;
                bottom: 5px;
                display: flex;
                .btn {
                    flex: 1;
                    cursor: pointer;
                    a {
                        display: block;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
</style>


<template>
  <div class="address-box">
    <div class="address-box-l">
      <div class="form-box">
        <h2>
          Enter your Delivery Details
        </h2>
        <template v-if="this.$isUk">
          <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !addressSearchForm.formattedAddress}]" >
            Addres
          </h3>
          <div class="edit-form-line">
            <input type="text" name="" v-model="addressSearchForm.formattedAddress" placeholder="Apartment, London Road ">
          </div>
          <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !addressSearchForm.doorNumber && !saveAddressForm.dontDoorNum }]" >Door Number</h3>
          <div class="edit-form-line">
            <input type="text" name=""  v-model="saveAddressForm.doorNumber" :disabled="saveAddressForm.dontDoorNum" placeholder="Flat/Door/House Number">
          </div>
          <div class="normal-form-line" v-if="!isMobile">
            <el-checkbox style="margin-left:5px;" size="medium" v-model="saveAddressForm.dontDoorNum"></el-checkbox>
            <span class="check-tips" style="cursor:pointer;" @click="saveAddressForm.dontDoorNum=!saveAddressForm.dontDoorNum">I Don’t Have A Door Number</span>
            <span style="flex: 1;" ></span>
            <span :class="['check-tips',{'empty-emptyTipsShaing-animate': showEmpty && !addressSearchForm.zipcode}]" style="margin-right: 12px;">Postcode</span>
            <span class="shadow-box" >
              <input style="min-width:130px;" type="text" name=""  v-model="addressSearchForm.zipcode" placeholder="Postcode" />
            </span>
          </div>
          <div class="normal-form-line" v-if="isMobile">
            <el-checkbox style="margin-left:5px;" size="medium" v-model="saveAddressForm.dontDoorNum"></el-checkbox>
            <span class="check-tips" style="cursor:pointer;" @click="saveAddressForm.dontDoorNum=!saveAddressForm.dontDoorNum">I Don’t Have A Door Number</span>
          </div>
          <div class="normal-form-line" v-if="isMobile">
            <span class="check-tips" style="margin-right: 12px;margin-left: 0;">Postcode</span>
            <span class="shadow-box" >
              <input type="text" name=""  v-model="addressSearchForm.zipcode" placeholder="Postcode" />
            </span>
          </div>
          
          <div class="normal-form-line" style="height: 65px;margin: 0px auto 20px;" >
            <div style="flex:1;min-width: 42%;">
              <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !addressSearchForm.city }]" style="width: 100%;">City</h3>
              <div class="edit-form-line" style="width: 100%;">
                <input type="text" name=""  v-model="addressSearchForm.city" placeholder="Name">
              </div>
            </div>
            <span style="flex:1;" ></span>
            <div style="flex:1;min-width: 42%;">
              <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty  && !addressSearchForm.country }]" >Country</h3>
              <div class="edit-form-line">
                <el-select v-model="addressSearchForm.country" placeholder="Country" style="width: 100%;">
                  <el-option
                    v-for="item in countryList"
                    :key="item.code"
                    :label="item.text"
                    :value="item.code">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !form.nickName }]" >Full Name</h3>
          <div class="edit-form-line">
            <input type="text" name=""  v-model="form.nickName" placeholder="Name">
          </div>
          <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !form.email }]" >Email</h3>
          <div class="edit-form-line">
            <input type="text" name=""  v-model="form.email" placeholder="Email">
          </div>
          <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty  && !form.phone }]" >Phone Number</h3>
          <div class="normal-form-line">
            <div class="area-box">
              <PhoneBtn v-model="form.code"></PhoneBtn>
            </div>
            <div class="shadow-box" >
              <input type="text" name=""  style="flex:1;" @keyup="form.phone=form.phone.replace(/\D/g,'')" v-model="form.phone" placeholder="Phone number" />
            </div>
          </div>
        </template>
        <!-- 非英国地区 -->
        <template v-else>
          <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !saveAddressForm.doorNumber }]" >
            House Number
          </h3>
          <div class="edit-form-line">
            <input type="text" name="" v-model="saveAddressForm.doorNumber" placeholder="House Number">
          </div>
          <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !addressSearchForm.locationName }]" >
            Street / Road Building Name
          </h3>
          <div class="edit-form-line">
            <input type="text" name=""  v-model="addressSearchForm.locationName" placeholder="Street / Road">
          </div>
          <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !addressSearchForm.formattedAddress}]" >
            Address
          </h3>
          <div class="edit-form-line">
            <input type="text" name=""  v-model="addressSearchForm.formattedAddress" placeholder="Apartment, Road ">
          </div>
          
          <div class="normal-form-line" style="height: 65px;margin: 0px auto 20px;" >
            <div style="flex:1;min-width: 42%;">
              <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty  && !addressSearchForm.country }]" >Preferred Name</h3>
              <div class="edit-form-line">
                <input type="text" name="" v-model="form.nickName" placeholder="Name">
              </div>
            </div>
            <span style="flex:1;" ></span>
            <div style="flex:1;min-width: 58%;">
              <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !form.email }]" style="width: 100%;">Email</h3>
              <div class="edit-form-line" style="width: 100%;">
                <input style="font-size:14px" type="text" name="" v-model="form.email" placeholder="Email">
              </div>
            </div>
          </div>


          <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty && !form.email }]" >Email</h3>
          <div class="edit-form-line">
            <input type="text" name=""  v-model="form.email" placeholder="Email">
          </div>
          <h3 :class="['edit-form-title',{'empty-emptyTipsShaing-animate': showEmpty  && !form.phone }]" >Phone Number</h3>
          <div class="normal-form-line">
            <div class="area-box">
              <PhoneBtn v-model="form.code"></PhoneBtn>
            </div>
            <div class="shadow-box" >
              <input type="text" name=""  style="flex:1;" @keyup="form.phone=form.phone.replace(/\D/g,'')" v-model="form.phone" placeholder="Phone number" />
            </div>
          </div>

          <h3 :class="['edit-form-title']" >
            Note For Driver
          </h3>
          <div class="edit-form-line">
            <input type="text" name=""  v-model="addressSearchForm.deliveryInstrustion" placeholder="building name is cecil house">
          </div>

        </template>

        <div class="btn-box">
          <button class="btn" @click="showSearchCover=true">Back</button>
          <span style="flex:1;"></span>
          <button class="save-btn" @click="saveAddress">Save</button>
        </div>
      </div>
      <!-- 搜索浮层 -->
      <div class="search-cover" v-if="showSearchCover">
        <h2 v-if="$isUk">
          Searching <br />
          Post code
        </h2>
        <h2 v-else>
          Searching <br />
          Irecode
        </h2>
        <div class="form-address-line">
          <div class="input-address-box" >
            <i class="el-icon-location "></i>
            <input type="text"  v-model="keywords" :placeholder="`Enter Your ${$isUk?'Post code':'Irecode'}`"
              @input="inputNow"
              @keyup="inputNow"
              @focus="focusNow = true"
              @blur="focusNow = false"
            >
          </div>
          <div class="search-btn" >
            <img src="@/assets/images/search.png" class="el-icon-search" />
            Find Address
          </div>
          <!-- 搜索结果内容 -->
          <div :class="['expand-box', { 'expand-box-active': (keywords && focusNow || resultList.length) }]">
            <div
              class="search-item"
              v-for="(item, ind) in resultList"
              :key="ind"
              @click="normalSearch(item)"
            >
              <span style="font-size: 16px;">{{ item.content }}</span>
              <i class="el-icon-loading" v-if="itemLoading == item.place_id && item.type=='location'"></i>
              <img v-else src="@/assets/images/right.png" alt="right-arrow" />
            </div>
            <div class="load-box" v-if="loading">
              <span class="loader"></span>
            </div>
            <div class="load-box" style="height: 50%;" v-else-if="!loading && !resultList.length">
              No data.
              <div class="empty-btn" > Can't find your location? <br /> Try to type street name </div>
            </div>
            <!-- <div class="empty-btn" @click="cantFindAd" > Cant't find your address? </div> -->
          </div>
        </div>
      </div>
      <!-- loading 蒙层 -->
      <div class="fetch-load-box" v-if="fetchLoading">
        <i class="el-icon-loading"></i>
      </div>
    </div>
    <div class="address-box-r">
      <h2>Edit Address</h2>
      <div class="card" v-for="item in list" :key="item.id" >
        <div class="left" >
          <div class="name" >{{ item.fullName }}</div>
          <span>{{ item.doorNumber }} {{ item.locationName }} </span>
          <span>{{ item.city }} {{ item.zipcode }} {{ item.country }}</span> <br />
          <span class="phone" >{{ item.contactPhone }}</span>
        </div>
        <div class="right" >
          <div class="btn delete-btn" @click="deleteAddress(item)">
            <i class="el-icon-delete" style="font-weight:bold;margin-right:5px;"></i>
            Delete
          </div>
          <div class="btn" @click="editAddress(item)">
            <i class="el-icon-edit-outline" style="font-weight:bold;margin-right:5px;"></i>
            Edit
          </div>
        </div>
      </div>
      <div class="loading-box" v-if="loading">
          <i class="el-icon-loading"></i>
      </div>
      <div class="empty-block" v-if="!loading && !list.length">
          <img src="@/assets/menu/empty_cart3.png" alt="empty">
      </div>
      <div class="add-address-btn" @click="goAddAddress" v-if="isMobile">
        <a>Add New Address</a>
      </div>
    </div>
  </div>
</template>

<script>
import { postGateway } from '@/request';
import PhoneBtn from "@/components/PhoneBtn.vue";
import debounce from '@/utils/debounce';
export default {
  components: {
    PhoneBtn
  },
  data() {
      return {
        loading: false,
        list: [],
        addressSearchForm: {
          city: '',
          country: '',
          state: '',
          street: '',
          zipcode: '',
          formattedAddress: '',
          locationName: '',
          lat: '',
          lng: '',
          deliveryInstrustion: ''
        },
        form: {
          phone: "",
          code: (this.$isUk?'+44':'+353'),
          verCode: "", // 手机验证码
          nickName: '',
          email: ''
        },
        saveAddressForm: {
          id: null,
          fullName: '',
          clientId: null,
          contactPhone: '',
          dontDoorNum: false, // 是否 没有门牌号
          street: '',
          city: '',
          state: '', // 目前不知道是啥
          country: '',
          countryCode: '',
          ipAddress: "", // ip地址
          latitude: "", // 经度
          longitude: "", // 纬度
          doorNumber: '', // 门牌号
          locationName: "", //楼层信息
          asDefault: true, // 是否设为默认
          formattedAddress: '',  //格式化之后的地址
          zipcode: '',  //邮编
        },
        fetchLoading: false,
        countryList: [],
        userInfo: {},
        keywords: '',
        resultList: [],
        showSearchCover: true,
        itemLoading: false,
        showEmpty: false,
        nowAddress: {
          id: null
        }
      }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 950
    }
  },
  watch: {
    'showEmpty': function(val) {
      this.scrollToTarget();
      if(val) {
        setTimeout(() => {
          this.showEmpty = false;
        }, 4000);
      }
    }
  },
  mounted() {
    if(localStorage.getItem('token')) {
      this.getUserInfo();
      this.getCountryList();
    }
    this.getAddressList();
  },
  methods: {
    // 手动填写的情况
    cantFindAd() {
      let obj = {
        formattedAddress: '',
        locationName: '',
        zipcode: '',
        city: '',
        latitude: '',
        longitude: '',
        lat: '',
        lng: '',
        street: '',
        country: this.$isUk?'United Kingdom':'Ireland',
        countryCode: this.$isUk?'GB':'IRE',
        deliveryInstrustion:'',
      }
      this.addressSearchForm = obj;
      this.showSearchCover = false;
    },
    scrollToTarget() {
      let dom = document.querySelector('.form-box');
      let target = dom.offsetTop-120;
      if(window.innerWidth < 950) {
        target = dom.offsetTop - 100;
      }
      document.body.scrollTop = document.documentElement.scrollTop  = target;
    },
    goAddAddress() {
      if(this.isMobile) {
        this.$router.push({
          path: '/editAddress'
        })
      }
    },
    getUserInfo() {
      return postGateway({
        url: '/customerApp/center/getDetail',
        method: 'GET',
        data: {
          clientId: localStorage.getItem('clientId')
        }
      }).then(res=>{
        this.userInfo = res.data;
        this.form.nickName = res.data.firstName || res.data.nickName;
        this.form.email = res.data.emailAddress;

        // 补全手机号
        if(res.data.contactPhone.indexOf('+44') == 0) {
					this.form.phone = res.data.contactPhone.replace('+44','')
					this.form.code = '+44';
				}
        if(res.data.contactPhone.indexOf('+353') == 0) {
					this.form.phone = res.data.contactPhone.replace('+353','')
					this.form.code = '+353';
				}
				if(res.data.contactPhone.indexOf('+86') == 0) {
					this.form.phone = res.data.contactPhone.replace('+86','');
					this.form.code = '+86';
				}
        if(res.data.contactPhone.indexOf('44') == 0) {
					this.form.phone = res.data.contactPhone.replace('44','')
					this.form.code = '+44';
				}
        if(res.data.contactPhone.indexOf('353') == 0) {
					this.form.phone = res.data.contactPhone.replace('353','')
					this.form.code = '+353';
				}
				if(res.data.contactPhone.indexOf('86') == 0) {
					this.form.phone = res.data.contactPhone.replace('86','');
					this.form.code = '+86';
				}

        console.log(res.data, '获取用户信息', this.form)
      })
      .catch()
    },
    getCountryList() {
      postGateway({
        url: '/districtCode/getDistrictSelect',
        method: 'GET',
        data: {
          level: 1,
        }
      }).then(res=>{
        this.countryList = res.data
      })
      .catch()
    },
    // 搜索地址相关
    inputNow() {
      this.resultList = [];
      this.loading = true;
      this.focusNow = true;
      debounce(() => {
        this.googlePreSearch();
      }, 500);
    },
    googlePreSearch() {  // 自动补全地址
      if(this.$isUk) {
				postGateway({
					url: `/customerApp/addressioSearch/autocomplete`,
					method: 'GET',
					data: {
						keyword: this.keywords
					}
				}).then(res=>{
					console.log(res.data,'搜索地址会餐')
					this.loading = false;
					this.resultList = res.data.suggestions.map(x=>({
            ...x,
            content: x.description || x.address,
            place_id: x.id,
            type: "location",
          }));
				})
				.catch(err=>{
					this.loading = false;
					console.log(err,'搜索地址报错')
				})
				return
			}
      postGateway({
        method: "GET",
        url: "/customerWeb/googleSearch/autocomplete",
        data: { keyword: this.keywords },
      })
      .then((res) => {
        this.resultList = this.resultList.concat(
          res.data.predictions.map((x) => {
            return {
              ...x,
              content: x.description,
              type: "location",
            };
          })
        );
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
    },
    // 根据结果跳转下一步
    normalSearch(item) {
      this.googleSearch(item);
    },
    googleSearch(item) {
      this.itemLoading = item.place_id;
      this.fetchLoading = true;
      if(this.$isUk) {
				postGateway({
					url: `/customerApp/addressioSearch/get`,
					method: 'GET',
					data: {
						id: item.id
					}
				}).then(res=>{
					this.itemLoading = '';
          if (res.code == 200) {
            let { formatted_address, postcode, longitude, latitude,town_or_city, country, line_1,deliveryInstrustion  } = res.data;
            let obj = {
              formattedAddress: formatted_address.join(','),
              locationName: '',
              zipcode: postcode,
              city: town_or_city,
              latitude: latitude,
              longitude: longitude,
              lat: latitude,
              lng: longitude,
              street: line_1,
              country: country,
              countryCode: 'GB',
              deliveryInstrustion: deliveryInstrustion?deliveryInstrustion:'',
            }
            this.addressSearchForm = obj;
            console.log(this.addressSearchForm, '用户选择地址')
            this.showSearchCover = false;
          } else {
            this.$message.error("Error happen...");
          }
					this.fetchLoading = false;
				})
				.catch(err=>{
          this.fetchLoading = false;
					console.log(err,'搜索地址报错')
				})
				return
			}
      postGateway({
        method: "GET",
        url: "/customerApp/googleSearch/placeGeoCode",
        data: {
          keyword: "",
          placeId: item.place_id,
        },
      }).then((res) => {
        this.itemLoading = '';
        this.fetchLoading = false;
        if (res.code == 200) {
          this.addressSearchForm = {
            ...res.data,
            locationName: '',
            deliveryInstrustion: ''
          };
          console.log(this.addressSearchForm, '用户选择地址')
          this.showSearchCover = false;
        } else {
          this.$message.error("Error happen...");
        }
      }).catch(err=>{
        this.itemLoading = '';
        this.fetchLoading = false;
        console.log(err,'err')
      })
    },
    // 保存用户地址表格
    saveAddress() {
      
      let { code, phone, nickName } = this.form;
      let { city, country, state, street, zipcode ,formattedAddress, locationName, lat, lng, deliveryInstrustion } = this.addressSearchForm;
      let countryCode = ""
      if(country == 'England' || country == 'Scotland') {
        countryCode = 'GB'
      }
      // let countryName = this.countryList.filter(x=>x.code===country)[0].text
      if((!this.saveAddressForm.doorNumber && !this.saveAddressForm.dontDoorNum)) {
				this.$message.info('Please fill the Door Number');
        this.showEmpty = true;
				return
			}

      if(!this.addressSearchForm.locationName && !this.$isUk) {
        this.showEmpty = true;
				return
			}

      let param = {
        city, state, street, zipcode ,
        formattedAddress,
        locationName,
        lat, 
        lng,
        latitude: lat,
        longitude: lng,
        contactPhone: code + phone,
        fullName: nickName,
        clientId: localStorage.getItem('clientId'),
        country: country,
        countryCode: countryCode  || 'GB',
        isDoorNumber: this.saveAddressForm.dontDoorNum ? 0 : 1,
        asDefault: this.saveAddressForm.asDefault ? 1 : 0,
        doorNumber: this.saveAddressForm.doorNumber,
        deliveryInstrustion
      }

      // 英国地区的地址同步一下 locationName
      if(this.$isUk && !param.locationName && param.street) {
        param.locationName = param.street
      }

      if(this.saveAddressForm.id) {
        param.id = this.saveAddressForm.id
      }

      if(!param.fullName) {
				this.$message.info('Please fill the Full Name')
        this.showEmpty = true;
				return
			}
			
			if(!phone) {
				this.$message.info('Please fill the Phone Number')
        this.showEmpty = true;
				return
			}

      let phoneCode = this.form.code.replace('+','');
      if(this.form.phone.indexOf(phoneCode) === 0) {
        this.$message({
          message: "Please check phone number width '" + phoneCode + "'",
          type: "warning",
        });
        return;
      }
			
			// if(!param.street) {
			// 	this.$message.info('Please fill the Addres')
      //   this.showEmpty = true;
			// 	return
			// }
			
			if(!param.city && this.$isUk) {
				this.$message.info('Please fill the City')
        this.showEmpty = true;
				return
			}
			if(!param.countryCode && this.$isUk) {
				this.$message.info('Please fill the Country')
        this.showEmpty = true;
				return
			}
			if(!param.zipcode && this.$isUk) {
				this.$message.info('Please fill the Postcode')
        this.showEmpty = true;
				return
			}

      console.log(param, '添加地址传参')
      this.fetchLoading = true;
      let url = '/customer/address/add';
      if(param.id) {
        url = '/customer/address/update';
      }
      postGateway({
        url,
        method: 'POST',
        data: param
      }).then(res=>{
        this.fetchLoading = false;
        this.getAddressList();
        this.$emit('loginDone',true)
        console.log(res,'添加地址传参')
      })
      .catch(()=>{
        this.fetchLoading = false;
      })
    },
    editAddress(item) {
      if(this.isMobile) {
        this.$router.push({
          path: '/editAddress',
          query: {
            id: item.id
          }
        })
        return
      }

      // 获取地址详情
      this.nowAddress = item;
      this.showSearchCover = false;
      for (const key in this.saveAddressForm) {
        this.saveAddressForm[key] = item[key]
      }
      this.saveAddressForm.dontDoorNum = item.isDoorNumber != 1;
      console.log(this.saveAddressForm.dontDoorNum ,'this.saveAddressForm.dontDoorNum ', item.isDoorNumber)

      for (const key in this.addressSearchForm) {
        this.addressSearchForm[key] = item[key]
      }
      
    },
    deleteAddress(item) {
      this.$alert('Whether delete the address?', 'Warning', {
          confirmButtonText: 'Confirm',
          center: true,
          callback: (action) => {
              if(action == 'confirm') {
                postGateway({
                  url: '/customer/address/delete',
                  method: 'POST',
                  data: [item.id]
                }).then(res=>{
                  console.log(res,'删除回参')
                  this.$message.success('Success')
                  this.getAddressList();
                })
                .catch(()=>{
                  this.$message.error('Error!')
                })
              }
          }
      });
    },
    getAddressList() { // 获取用户保存的地址
      this.clientId = localStorage.getItem('clientId');
      this.loading = true;
      this.list = [];
      postGateway({
        method: 'GET',
        url: '/customer/address/queryList',
        data: {
          clientId: this.clientId
        }
      }).then(res=>{
        this.list = res.data;
        this.loading = false;
      }).catch(err=>{
        console.log(err, '地址获取报错')
        this.loading = false;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #26d07c;
$yellowColor: #ffa132;
.address-box {
  margin-top: 30px;
  min-height: 600px;
  display: flex;
  // border-radius: 8px;
  border-radius: 30px;
  background: #fff;
  // box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
  .address-box-l, .address-box-r {
    border-radius: 30px;
    padding: 40px 30px;
    flex: 1;
    position: relative;
    .search-cover {
      height: 100%;
      width: 100%;
      position: absolute;
      padding-top: 40px;
      top: 0;
      left: 0;
      background: #fff;
      z-index: 20;
      border-radius: 30px;
    }
  }
  .address-box-r {
    border-radius: 0px 30px 30px 0px;
    padding: 10px 50px 40px;
    // background: #FAFAFA;
    background: #f0efefef;
    h2 {
      color: #000;
      font-weight: bold;
      text-align: center;
      font-size: 25px;
    }
    .card {
      min-height: 150px;
      // border-radius: 15px;
      border-radius: 30px;
      box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
      background: #fff;
      display: flex;
      padding: 20px;
      margin-bottom: 20px;

      .left {
        flex: 1;
        font-size: 16px;
        .name {
          font-weight: bold;
          padding-bottom: 10px;
        }
        .phone {
          display: block;
          margin-top: 10px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
          height: 40px;
          width: 125px;
          // border-radius: 15px;
          border-radius: 30px;
          background: #AA0BC0;
          font-size: 20px;
          color: #fff;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all .2s ease-in-out;
          cursor: pointer;
          &:hover {
            background: #8b0d9b;
          }
        }
        .delete-btn {
          background-color: rgb(189, 20, 20);
          margin-right: 10px;
        }
      }
    }
  }
  
  .form-box, .search-cover {
    min-height: 580px;
    background: #fff;
    border-radius: 2px;
    padding: 0 0px 20px;
    input {
      font-weight: 600;

      &::placeholder {
        font-weight: 400;
      }
    }

    h2 {
      font-weight: 800;
      color: #000;
      font-size: 30px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 8px;
    }
    p {
      font-size: 18px;
      text-align: center;
      margin: 0;
      .under-line {
        text-decoration: underline;
        margin: 0;
        cursor: pointer;
        color: #2885e2;
      }
    }

    .input-box {
      margin-top: 48px;
      display: flex;
      align-items: center;

      .area-box {
        height: 56px;
        width: 114px;
        margin-right: 10px;
        border-radius: 4px;
        box-shadow: 0px 2px 5px #8f9698;
      }
      .input {
        height: 56px;
        flex: 1;
        box-shadow: 0px 2px 5px #8f9698;
        border-radius: 4px;
        padding-left: 20px;
        font-size: 20px;
        font-weight: 600;

        &::placeholder {
          font-weight: 500;
        }
      }
    }

    .check-box {
      margin-top: 20px;
      display: flex;
      font-size: 18px;
      padding-left: 126px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      :deep(.el-checkbox__inner) {
        background-color: #dcdfe6;
      }
      :deep(
          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner
        ) {
        background-color: #09ca6a;
        border-color: #09ca6a;
      }
      :deep(.el-checkbox__inner) {
        transform: scale(1.4);
      }
      span {
        margin-left: 15px;
        cursor: pointer;
      }
      p {
        margin-top: 10px;
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #474747;
        text-align: left;
        .under-line {
          text-decoration: underline;
          margin: 0;
          cursor: pointer;
          color: #2885e2;
        }
      }
    }
    .sign-btn {
      margin-left: 128px;
      margin-top: 20px;
      height: 58px;
      background-color: #09ca6a;
      color: #fff;
      font-size: 22px;
      letter-spacing: 1px;
      font-weight: 600;
      border-radius: 6px;
      box-shadow: 0px 3px 6px #8f9698;
      transition: all 0.2s ease-in;
      cursor: pointer;
      &:hover {
        background-color: #0fda74;
        box-shadow: 0px 5px 10px #6f7577;
      }
    }
    .disable-btn {
      background-color: #999;
      &:hover {
        background-color: #999;
        box-shadow: 0px 5px 10px #6f7577;
      }
    }
    .ver-code {
      margin: 40px auto;
      display: flex;
      position: relative;
      width: 400px;
      overflow: visible;
      box-shadow: 0px 3px 6px #8F9698;

      input {
        height: 58px;
        z-index: 10;
        font-size: 30px;
        width: 400px;
        background-color: transparent;
        box-sizing: border-box;
        border-radius: 4px;
        letter-spacing: 40px;
        text-align: center;

        &::placeholder {
          letter-spacing: normal;
        }
      }
      .mask-box {
        display: flex;
        justify-content: center;
        width: 400px;
        .font-box {
          height: 65px;
          min-width: 68px;
          border-radius: 4px;
          margin-right: 30px;
          box-shadow: 0px 3px 6px #8f9698;
          background-color: #fff;
          font-size: 30px;
          color: #000;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .ol-line {
            height: 40px;
            width: 2px;
            border-radius: 1px;
            background-color: #000;
            animation: breath 1.2s ease-in-out infinite alternate forwards;
            position: absolute;
            left: 22%;
            top: 50%;
            transform: translateY(-50%);
            z-index: 20;
          }
        }
      }
    }

    .name-tips {
      margin: 24px 0 40px;
    }

    .form-line {
      margin: 0 auto 20px;
      display: flex;
      width: 60%;
      box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.15);
      height: 48px;
      font-size: 18px;
      color: #000;
      border-radius: 4px;
      overflow: hidden;

      input {
        height: 100%;
        width: 100%;
        padding-left: 20px;
        color: #000;
        font-size: 20px;
      }
    }

    .form-address-line {
      margin: 40px auto 20px;
      display: flex;
      height: 48px;
      font-size: 18px;
      width: 90%;
      position: relative;

      .input-address-box {
        flex: 1;
        display: inline-flex;
        align-items: center;
        height: 100%;
        box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.15);
        // border-radius: 4px;
        border-radius:30px;
        margin-top: 0;
        padding-left: 20px;
        .el-icon-location {
          color: #000;
          margin-right: 12px;
          font-size: 20px;
        }
        input {
          flex: 1;
          height: 100%;
          font-size: 18px;
          font-weight: 600;
          color: #000;
          border-radius:0px 30px 30px 0px;
        }
      }
      
      .search-btn {
        background: #09CA6A;
        box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.15);
        color: #fff;
        display: flex;
        align-items: center;
        margin-left: 10px;
        height: 100%;
        padding: 0 20px;
        white-space: nowrap;
        font-size: 15px;
        font-weight: 600;
        // border-radius: 4px;
        cursor: pointer;
        border-radius:30px;
        .el-icon-search {
          height: 20px;
          width: 20px;
          margin-right: 8px;
        }
      }

      .expand-box {
        position: absolute;
        width: 90%;
        height: 0px;
        top: 50px;
        left: 12px;
        background: rgba(255, 255, 255, 0.95);
        padding: 20px 0;
        height: 0px;
        opacity: 0;
        transition: all 0.8s ease-in-out 0s;
        box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.15);

        .search-item {
          height: 50px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e9e9e9;
          padding: 0 20px;
          cursor: pointer;

          .el-icon-loading {
            font-size: 18px;
            color: #aa0bc0;
            font-weight: bold;
          }

          &:hover {
            background-color: rgba(236, 230, 230, 0.95);
          }

          span {
            flex: 1;
          }

          img {
            height: 18px;
            width: 25px;
          }
        }
      }

      .load-box {
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .loader {
          width: 48px;
          height: 48px;
          border: 5px solid #fff;
          border-bottom-color: #ff3d00;
          border-radius: 50%;
          display: inline-block;
          box-sizing: border-box;
          animation: rotation 1s linear infinite;
        }

        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }

      .empty-btn {
        margin: 10px auto;
        height: 60px;
        background-color: #02c757;
        // border-radius: 4px;
        border-radius: 30px;
        width: 300px;
        font-size: 18px;
        color: #fff;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .expand-box-active {
        height: 280px;
        opacity: 1;
        overflow-y: scroll;
      }

    }

    .edit-form-line {
      margin: 0 auto;
      width: 95%;
      height: 40px;
      display: flex;
      border-radius: 4px;
      overflow: hidden;
      align-items: center;
      box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.15);
      border-radius: 25px;
      input {
        height: 100%;
        width: 100%;
        padding-left: 20px;
        font-size: 16px;
        background:#fafafa;
      }
      :deep(.el-select .el-input .el-input__inner) {
        font-weight: 600!important;
        border: none!important;
        color: #000;
      }
    }

    .normal-form-line {
      display: flex;
      align-items: center;
      width: 95%;
      height: 40px;
      font-weight: 600;
      margin: 12px auto 0;

      .area-box {
        box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.15);
        margin-right: 20px;
        // border-radius: 6px;
        border-radius: 25px;
      }

      .check-tips {
        margin-left: 10px;
        white-space: nowrap;
        font-size: 15px;
      }

      input {
        height: 100%;
        padding-left: 20px;
        font-size: 16px;
      }
      .shadow-box {
        flex: 1;
        box-shadow: 0px 4px 4px rgba($color: #000000, $alpha: 0.2);
        height: 100%;
        border-radius: 25px;
        input {
          width: 100%;
          flex: unset;
          background: #fafafa;
        }
      }

      :deep(.el-checkbox__inner) {
        background-color: #dcdfe6;
      }
      :deep(
          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner
        ) {
        background-color: #09ca6a;
        border-color: #09ca6a;
      }
      :deep(.el-checkbox__inner) {
        transform: scale(1.4);
      }
    }

    .edit-form-title {
      width: 95%;
      font-weight: 600;
      margin: 16px auto 8px;
    }

    .btn-box {
      width: 98%;
      display: flex;
      margin-top: 40px;
      .btn, .save-btn {
        height: 48px;
        padding: 0 40px;
        border-radius: 24px;
        background: #09CA6A;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.15);
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:hover {
          background: #08b35e;
        }
      }
      .btn {
        background: rgba(194, 191, 191, 0.95);
        &:hover {
          background: rgba(206, 203, 203, 0.95);
        }
      }
    }

    @keyframes breath {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .top-box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 20px;

      i {
        font-size: 26px;

        color: #000;
        cursor: pointer;
        transition: all ease-in 0.2s;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          0px 4px 4px rgba(0, 0, 0, 0.25);
        &:hover {
          color: $mainColor;
        }
      }
      .back {
        position: relative;
        left: -30px;
        cursor: pointer;
      }
      .close {
        position: relative;
        right: -40px;
      }
    }

    .slide-box {
      position: relative;
      .loading-box {
        position: absolute;
        top: 0;
        left: 0;
        height: 500px;
        width: 100%;
        z-index: 50;
      }
    }

  }

  .loading-box {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    i {
      font-size: 30px;
      color: #FF8000;
      font-weight: 600;
    }
  }
  .empty-block {
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
      width: 80%;
      border-radius: 12px;
    }
  }
  .add-address-btn {
    width: 70%;
    margin: 10px auto 0;
    cursor: pointer;
    height: 45px;
    border-radius: 23px;
    background-color: $greenColor;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.3);
    transition: all .2s ease-in-out;
    &:hover {
      background-color: #21bd6f;
    }
  }
  .fetch-load-box {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 51;
    background: rgba(255, 255, 255, 0.8);

    .el-icon-loading {
      font-size: 35px;
    }
  }
}
@media screen and (max-width: 950px) {
  .address-box {
    margin: 15px;
    box-shadow: unset;
    background: unset;
    .address-box-l {
      display: none;
    }
    .address-box-r {
      background: unset;
      padding: 0;
      .card {
        height: unset;
        padding: 15px;
        .left {
          flex: 1;
          font-size: 14px;
          padding-right: 10px;
          .name {
            font-weight: bold;
            font-size: 15px;
            padding-bottom: 10px;
            color: #000;
          }
          .phone {
            display: block;
            margin-top: 10px;
          }
        }
        .right {
          flex-direction: column;
          .btn {
            height: 40px;
            width: 80px;
            font-size: 14px;
            border-radius: 20px;
            // border-radius: 30px;
          }
          .delete-btn {
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
      h2 {
        display: none;
      }
    }
  }
}
</style>
<template>
    <div class="develop-box">
        <h2>当前 {{ ipLocat }}</h2>

        <el-radio @change="changeLocat" v-model="ipLocat" label="UK">UK</el-radio>
        <el-radio @change="changeLocat" v-model="ipLocat" label="Ireland">Ireland</el-radio>

        <br />
        <el-button type="primary" style="margin-top:20px" @click="clearLocat" >清空地区设置</el-button>
        
    </div>
</template>

<script>
    export default {
        data() {
            return {
                ipLocat: ''
            }
        },
        created() {
            this.ipLocat = localStorage.getItem('ipLocat')
            if(!this.ipLocat) {
                this.ipLocat = this.$isUk ? 'UK' : 'Ireland'
            }
        },
        methods: {
            changeLocat(val) {
                console.log(val,'地区')
                localStorage.setItem('ipLocat', val)
            },
            clearLocat() {
                localStorage.removeItem('ipLocat');
                this.$message.success('清空成功')
                this.ipLocat = '';
                this.ipLocat = this.$isUk ? 'UK' : 'Ireland'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .develop-box {
        padding: 20px;
    }
</style>
<template>
    <el-popover
        placement="bottom"
        width="100"
        v-model="visible">
        
        <ul class="choose-box">
            <li class="phone-btn" v-for="(item,ind) in countryList" :key="ind+item.code" @click="chooseCode(item)">
                <img :src="getImg(item.img)" alt="">
                <span class="code">{{ item.code }}</span>
            </li>
        </ul>

        <div class="phone-btn" slot="reference">
            <img :src="getImg(countryMap[value].img)" alt="">
            <span class="code">{{ value }}</span>
        </div>
    </el-popover>
</template>
<script>
import { isUkIp } from "@/utils/judgeUseGoogle.js";
export default {
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: {
            type: String,
            default: '+44'
        }
    },
    computed: {
        countryMap() {
            let obj = {}
            this.countryList.forEach(ele=>{
                obj[ele.code] = ele
            })
            return obj
        }
    },
    mounted() {
        isUkIp().then(()=>{
            this.$isUk = true;
            localStorage.setItem('ipLocat', 'UK')
            this.$emit('input', '+44')
        })
        .catch(()=>{
            this.$isUk = false;
            this.$emit('input', '+353')
            localStorage.setItem('ipLocat', 'Ireland')
        })
    },
    data() {
        return {
            visible: false,
            countryList: [
                {
                    name: 'United Kingdom',
                    img: 'assets/flag/uk.png',
                    code: '+44',
                },
                {
                    name: 'America',
                    img: 'assets/flag/america.png',
                    code: '+1',
                },
                {
                    name: 'Greece',
                    img: 'assets/flag/a-Greekxilaguoqi.png',
                    code: '+30',
                },
                {
                    name: 'Holland',
                    img: 'assets/flag/a-Holandhelanguoqi.png',
                    code: '+31',
                },
                {
                    name: 'Belgium',
                    img: 'assets/flag/a-Belgiumbilishiguoqi.png',
                    code: '+32',
                },
                {
                    name: 'France',
                    img: 'assets/flag/france.png',
                    code: '+33',
                },
                {
                    name: 'Germany',
                    img: 'assets/flag/xibanyaguoqi.png',
                    code: '+34',
                },
                {
                    name: 'Hungary',
                    img: 'assets/flag/hungary.png',
                    code: '+36',
                },
                {
                    name: 'Italy',
                    img: 'assets/flag/a-Italyyidaliguoqi.png',
                    code: '+39',
                },
                {
                    name: 'Romania',
                    img: 'assets/flag/Romania.png',
                    code: '+40',
                },
                {
                    name: 'Switzerland',
                    img: 'assets/flag/ruishi.png',
                    code: '+41',
                },
                {
                    name: 'Austria',
                    img: 'assets/flag/a-Austriaaodiliguoqi.png',
                    code: '+43',
                },
                {
                    name: 'Denmark',
                    img: 'assets/flag/a-Danmarkdanmaiguoqi.png',
                    code: '+45',
                },
                {
                    name: 'Sweden',
                    img: 'assets/flag/Sweden.png',
                    code: '+46',
                },
                {
                    name: 'Norway',
                    img: 'assets/flag/a-Norwaynuoweiguoqi.png',
                    code: '+47',
                },
                {
                    name: 'Polend',
                    img: 'assets/flag/poland.png',
                    code: '+48',
                },
                {
                    name: 'Spain',
                    img: 'assets/flag/deguoguoqi.png',
                    code: '+49',
                },
                {
                    name: 'Australia',
                    img: 'assets/flag/aodaliya.png',
                    code: '+61',
                },
                {
                    name: 'Canada',
                    img: 'assets/flag/canada.png',
                    code: '+1',
                },
                {
                    name: 'China',
                    img: 'assets/flag/china.png',
                    code: '+86',
                },
                {
                    name: 'Slovenia',
                    img: 'assets/flag/siluowenniya.png',
                    code: '+386',
                },
                {
                    name: 'Slovakia',
                    img: 'assets/flag/siluofake.png',
                    code: '+421',
                },
                {
                    name: 'Portugal',
                    img: 'assets/flag/Flag_of_Portugal.png',
                    code: '+351',
                },
                {
                    name: 'Ireland',
                    img: 'assets/flag/a-Irelandaierlanguoqi.png',
                    code: '+353',
                },
                {
                    name: 'Iceland',
                    img: 'assets/flag/bingdao.png',
                    code: '+354',
                },
                {
                    name: 'Malta',
                    img: 'assets/flag/maerta.png',
                    code: '+356',
                },
                {
                    name: 'Cyprus',
                    img: 'assets/flag/saipulusi.png',
                    code: '+357',
                },
                {
                    name: 'Finland',
                    img: 'assets/flag/a-Finlandfenlanguoqi.png',
                    code: '+358',
                },
            ]
        }
    },
    methods: {
        getImg(path) {
            return path?require('@/' + path):''
        },
        chooseCode(item) {
            this.visible = false;
            this.$emit('input', item.code)
        }
    },
}
</script>

<style lang="scss" scoped>
    .phone-btn {
        min-height: 48px;
        min-width: 105px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
            width: 40px;
        }
        .code {
            margin-left: 8px;
            color: #000;
            font-size: 18px;
            min-width: 30px;
        }
    }
    .choose-box {
        height: 40vh;
        padding: 0;
        margin: 0;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 8px;
            height: 1px;
        }

        li {
            transition: all ease-in .2s;
            height: 48px;
            width: 105px;
        }

        li:hover {
            background-color: rgba($color: #000000, $alpha: 0.1);
        }
    }
    @media screen and (max-width: 950px) {
        .phone-btn {
            min-height: 32px;
            min-width: 85px;
            img {
                width: 30px;
            }
            .code {
                font-size: 14px;
            }
        }
    }
</style>
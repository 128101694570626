export default {
    Indian: [{
            pageTitle: "Order takeaway from Indian restaurants near you ",
            popTitle: 'Fancy Indian food? Find hundreds of Indian Restaurants & Takeaways open near you on All Eat.',
            popImg: require("@/assets/cuisine/Indian/img1.png"),
            popContent1: "Get delicious Indian food delivered to you in no time",
            popContent2: "Smash your hunger with the most loved curries and rice dishes. Indian food is as rich as its culture and as diverse as India itself. Featuring vegetarian and non-vegetarian dishes that stand out using a vast array of herbs, spices, and seasonings, some of the most popular dishes you’ll love in Indian cuisine are biryani, curry, tandoori chicken, samosas, and naan bread. No doubt Indian food is widely regarded as one of the most flavourful and aromatic cuisines in the world. You'll be spoilt for choice when it comes to Indian food. ",
        },
        {
            popTitle: "Butter Chicken ",
            popImg: require("@/assets/cuisine/Indian/img2.png"),
            popContent1: "We are not chicken to try spicy food. But no worries if you’re. Coz this rich and creamy butter chicken is cooked with tender chicken pieces, flavoured with a mix of spices and finished with a dollop of butter or ‘makkhan’ will give you the taste you’ll never forget. Food which will melt in your mouth leaving you all smiles. This chicken is surely a charm of North-Indian cuisine.",
            popContent2: "",
        },
        {
            popTitle: "Saag Paneer",
            popImg: require("@/assets/cuisine/Indian/img3.png"),
            popContent1: "Don’t like your greens? We bet you’ll love this one even if you’re a chicken fan. Saag paneer is a popular Indian food which attracts food lovers with its aromatic and textured green gravy with cottage cheese or ‘paneer’ pieces in it. The perfect companion for Indian naan, this green, creamy and mildly spiced gravy will pull you like a magnet. We bet you can’t have enough of this Indian vegetarian delight!!",
            popContent2: "",
        },
    ],
    Chinese: [{
            pageTitle: "Order Chinese food for takeaway delivery near you",
            popTitle: 'Fancy Chinese food? Find hundreds of Chinese Restaurants & Takeaways open near you on All Eat.',
            popImg: require("@/assets/cuisine/Chinese/Chinese1.png"),
            popContent1: "Get delicious Chinese food delivered to you in no time",
            popContent2: "Chinese food is one of the most popular and diverse cuisines. It’s known for its bold flavours, fresh ingredients, and emphasis on balance and harmony in taste, texture, and colour. Whether you're a fan of fiery Sichuan cuisine or delicate Cantonese dim sums, there is something for everyone in the rich and diverse world of Chinese food."
        },
        {
            popTitle: "Kung Pao Chicken",
            popImg: require("@/assets/cuisine/Chinese/Chinese2.png"),
            popContent1: "Our beloved chicken gets a Chinese twist and you’ll love this fiery Sichuan dish which features tender diced chicken stir-fried with peanuts, vegetables, and dried red chilli peppers in a savoury and slightly sweet sauce. The perfect combination!!",
            popContent2: "Vegetarians can order veg Chow mein, Ma po tofu, veg fried rice or vegetable dumplings. The choice is yours. What do you fancy in your Chinese meal? ",
        },
        {
            popTitle: "Crispy aromatic duck",
            popImg: require("@/assets/cuisine/Chinese/Chinese3.png"),
            popContent1: "Perfectly roasted marinated duck until it’s crispy and served with pancakes, hoisin sauce, and scallions, this Chinese classic main is adored for its crispy skin, tender meat, and aromatic flavour. Or try delicious beef noodles, chicken chow mein or wonton soup. You'll be spoilt for choice when it comes to Chinese food.",
            popContent2: "",
        },
    ],
    Asian: [{
            pageTitle: "Order Asian Food Online",
            popTitle: 'Find takeaways near you delivering Asian cuisine',
            popImg: require("@/assets/cuisine/Asian/Asian1.png"),
            popContent1: "Get Asian food delivered to you in no time",
            popContent2:"Food is more of a tradition in Asian culture. Dim Sum, Sushi, Kimchi or Satay - Asian food has captivated the world in its essence and yes, it’s more than just noodles and rice. Awaken your culinary senses with Japanese sushi or try bold and fiery Thai Red curry. Explore the depths of Chinese Dim Sums and Sichuan noodles, or be blown away with the amazing Korean Bibimbap and sizzling Barbeques. So why resist the temptation? Order them right away on All Eat. ",
        },
        {
            popTitle: "Dumplings",
            popImg: require("@/assets/cuisine/Asian/Asian2.png"),
            popContent1: "Steamed, fried or boiled, Dumplings are the first thing which comes to mind when thinking about Asian food. And why not? Tender, thin dough encasing a savoury filling (juicy pork, fragrant chicken, or succulent beef) which bursts with flavour upon every bite. Called Jiaozi in Chinese, Gyoza in Japanese and Mandu in Korea, these dumplings taste heavenly in every form. Of course, their vegetarian and vegan versions are amazing too! When are you trying them all?",
            popContent2: "",
        },
        {
            popTitle: "Noodles",
            popImg: require("@/assets/cuisine/Asian/Asian3.png"),
            popContent1: "Chinese Egg Noodles, Japanese Ramen, Thai Rice Noodles, or Vietnamese Pho, there’s noodles for every taste and mood. And the best part, you can customise each one of them as per your preference. That’s not all!! There are even more varieties of noodles than you can think of. Like Chow Mein or Lo Mein. Like Ramen. Like Pad Thai and Pho Noodle Soup. Now we are even confused which one to recommend. Why not try them all on your All Eat app.",
            popContent2: "",
        },
    ],
    // Bubble: [{
    //         pageTitle: "Order Bubble Tea Online",
    //         popTitle: 'Find takeaways near you delivering bubble tea',
    //         popImg: require("@/assets/cuisine/Chinese/img1.png"),
    //         popContent1: "Get bubble tea delivered to you in no time",
    //         popContent2:"Initially prepared in Taiwan in the 80s, boba tea, bubble tea or pearl tea, this velvety smooth beverage has taken the world by a storm. Read our blog to find out more about Bubble tea.",
    //         popContent3:"Take a sip of happiness with tiny chewy bubbles floating in your tea of luscious flavours that range from fruity and floral to creamy and exotic. Whether you prefer the refreshing kiss of a tropical fruit medley or the comforting embrace of rich, velvety milk tea, bubble tea creations are meticulously crafted to take your taste buds on a captivating journey. Open your All Eat app to order and experience the perfect harmony of taste, texture, and sheer bliss in every sip you take.",
    //     },
    //     {
    //         popTitle: "",
    //         popImg: "",
    //         popContent1: "",
    //         popContent2: "",
    //     },
    //     {
    //         popTitle: "",
    //         popImg: "",
    //         popContent1: "",
    //         popContent2: "",
    //     },
    // ],
    Burgers: [{
            pageTitle: "Order burgers online",
            popTitle: 'Find takeaways near you delivering burgers',
            popImg: require("@/assets/cuisine/Burgers/burgers1.png"),
            popContent1: "Get delicious burgers delivered to you in no time",
            popContent2:"Loved by kids and grown-ups alike, burgers are the ultimate comfort food. They’re so versatile, you can create any version of your own! ",
            popContent3:"Meat or meatless, it’s hard to say no to a burger! Enjoy with your favourite meat patty (or veggie) along with crisp lettuce, ripe tomato slices, onions (raw or caramelised), gherkins, and melted cheese. Served with a side of crispy French fries, onion rings, or a refreshing salad, creating a well-rounded meal that satisfies both hunger and cravings. Can’t wait? Order yours now on All Eat!",
        },
        {
            popTitle: "Classic Hamburger",
            popImg: require("@/assets/cuisine/Burgers/burgers2.png"),
            popContent1: "The quintessential burger consists of a perfectly grilled beef patty carefully placed between a bed of lettuce, tomato, onions, pickles inviting you to relish the grand taste which will blow your mind. A slight variation to try is Cheeseburger with the addition of a slice or melted cheese on top of the patty, providing a deliciously gooey and savoury element. Fancy burger in brekkie? Enter Bacon Burger topped with crispy bacon strips, adding a smoky and savoury flavour that complements the beef patty, or be bold and try BBQ burger for that extra smoky flavour and sweet taste.",
            popContent2: "",
        },
        {
            popTitle: "Veggie Patty Burger",
            popImg: require("@/assets/cuisine/Burgers/burgers3.png"),
            popContent1: "Imagine a burger catering to vegetarians and vegans. Black bean burger including patty infused with black beans, chopped onions, garlic, bell peppers, and a combination of herbs and spices such as cumin, paprika and cilantro. Truly a great nosh to nibble if you’re feeling peckish. Love mushrooms? How about a mushroom swiss burger to add that zing to your appetite?",
            popContent2: "",
        },
    ],
    // Cakes: [{
    //         pageTitle: "Order Cakes Online",
    //         popTitle: 'Find Takeaways near you delivering Cakes',
    //         popImg: require("@/assets/cuisine/Chinese/img1.png"),
    //         popContent1: "Get Irresistible Cakes delivered to you in no time",
    //         popContent2:"Velvety, moist and spongy, who doesn’t love cakes? Imagine sinking your fork into a luscious slice of velvety chocolate cake, a vibrant fruit cake or the sinfully creamy cheesecake. Cake is a celebration in itself! Got married? Celebrate with a 3-tier cake. Got promoted? Throw a cake party. Have a birthday? Cut a cake with your loved ones. Do we really need to say more? Ordering just for yourself? No worries! Why not treat yourself to a slice of heaven- yes, a pastry which is a precious piece of the cake itself. Don’t wait! Order blissful sweetness now on All Eat app.",
    //         popContent3:"",
    //     },
    //     {
    //         popTitle: "Tiramisu",
    //         popImg: "",
    //         popContent1: "An Italian dessert featuring delicate layers of ladyfingers soaked in coffee and liqueur, layered with a creamy mixture of mascarpone cheese, eggs, and sugar and dusted with cocoa powder or grated chocolate on top. While it may not have the conventional cake structure, tiramisu is widely considered a cake since it is layered and assembled like a cake.Cheesecake is sinfully indulgent and yummy ensuring the world around you fades away when you take a bite.",
    //         popContent2: "",
    //     },
    //     {
    //         popTitle: "Chocolate Fudge Cake",
    //         popImg: "",
    //         popContent1: "A masterpiece of culinary temptation, a dessert that knows no boundaries when it comes to pleasure! Whether it's a special celebration, an intimate gathering, or simply a moment of self-indulgence, this cake is an invitation to indulge in the purest form of chocolate ecstasy. Pair it with a scoop of velvety vanilla ice cream, or accompany it with a dollop of freshly whipped cream, the options are limitless to savour this exotic dessert. Order now on All Eat.",
    //         popContent2: "",
    //     },
    // ],
    // Desserts: [{
    //         pageTitle: "Order Delicious Desserts Online",
    //         popTitle: 'Find Takeaways near you delivering Desserts',
    //         popImg: require("@/assets/cuisine/Chinese/img1.png"),
    //         popContent1: "Get desserts delivered to you in no time",
    //         popContent2:"Gelato, Tiramisu, Milkshake, Cheesecake, Banoffee Pie, Kulfi – surrender to the temptation of heavenly desserts and experience pure bliss. Sometimes, all you need is a fork sinking into your beloved dessert and let all your worries go away. All these sweet delectable treats are available just a tap away – on your All Eat app.",
    //         popContent3:"Whether you’re a classic British Sticky Toffee Pudding lover or a fan of the American Red Velvet Cake; admire Italian classic Tiramisu or Middle Eastern sweet Baklava, we have the stage ready for you. You’ve got cravings, we’ve got the food, so, what’re you waiting for? ",
    //     },
    //     {
    //         popTitle: "Oreo Cheesecake",
    //         popImg: "",
    //         popContent1: "The ultimate dessert fusion – velvety cheesecake infused with crushed Oreo cookies – the rich cocoa notes of the Oreos intertwine with the tangy cream cheese, leaving you craving for more. This perfect match is enough to captivate your sweetest desires. Gelato Ice Cream, Tiramisu, Carrot Cake, Chocolate Fudge Cake, Indian Kulfi, British Pies – you name it, we have it.",
    //         popContent2: "",
    //     },
    //     {
    //         popTitle: "Milkshakes",
    //         popImg: "",
    //         popContent1: "There might be an age to drink milk but Milkshakes, they’re for all ages. The creamy dreamy blend of  milk, ice cream, and flavours makes this drink absolutely irresistible. Timeless classic vanilla milkshake. Indulgent Chocolate Milkshake. Refreshing Strawberry Milkshake. And for those feeling adventurous, there's  peanut butter and banana, salted caramel, or cookies and cream. So, grab a straw, take a sip and experience comfort, nostalgia, and pure indulgence, all at the same time.",
    //         popContent2: "",
    //     },
    // ],
    Japanese: [{
            pageTitle: "Order Japanese food online",
            popTitle: 'Find Japanese Restaurants & Takeaways open near you on All Eat.',
            popImg: require("@/assets/cuisine/Japanese/Japanese1.png"),
            popContent1: "Get delicious Japanese food delivered to you in no time",
            popContent2:"Mingling perfectly with the fusion of traditional and contemporary, Japanese food is an exquisite collection of taste. From the delicate artistry of sushi, where the freshest fish meets perfectly seasoned rice, to the soul-soothing richness of a steaming bowl of ramen, each dish unveils a symphony of flavours. Experience the sizzle of teppanyaki as tender meats dance on the grill, and surrender to the crispy, golden perfection of tempura. There is a dish for every mood.",
            popContent3:"",
        },
        {
            popTitle: "Katsu Curry",
            popImg: require("@/assets/cuisine/Japanese/Japanese2.png"),
            popContent1: "Get ready for the ultimate comfort food. A golden-brown cutlet, tender and succulent, rests upon a bed of fluffy rice with rich velvety gravy prepared in aromatic spices. With each bite, the crunch gives way to a burst of savoury flavours, creating a delightful medley of textures and tastes. Order chicken katsu curry on All Eat and get your chopsticks ready!!",
            popContent2: "",
        },
        {
            popTitle: "Ramen noodles",
            popImg: require("@/assets/cuisine/Japanese/Japanese3.png"),
            popContent1: "Picture a steaming bowl filled with delicate noodles, submerged in a flavourful broth that dances on your palate with every slurp. Carefully simmered for hours, the broth comes in an array of flavours, from rich and savoury tonkotsu to tangy and aromatic shoyu or miso. Whether you're seeking comfort on a rainy day or craving an unforgettable gastronomic experience, ramen noodles are a pure culinary bliss anytime. Order on All Eat. ",
            popContent2: "",
        },
    ],
    // Sushi: [{
    //         pageTitle: "Get Sushi delivered to you in no time.",
    //         popTitle: '',
    //         popImg: require("@/assets/cuisine/Chinese/img1.png"),
    //         popContent1: "Get Sushi delivered to you in no time.",
    //         popContent2:"Sashimi, Maki, Temaki, Nigiri, Bento or sushi sets, Japanese sushi has captivated the taste buds of people worldwide.",
    //     },
    //     {
    //         popTitle: "Sashimi",
    //         popImg: "",
    //         popContent1: "From buttery and rich tuna (maguro) to delicate and mild salmon (sake), the choices are vast, each offering a unique Taste. Other popular options include yellowtail (hamachi), mackerel (saba), and snapper (tai), among others. Served with soy sauce, wasabi, and pickled ginger, these classic accompaniments enhance the flavours without overpowering the natural taste of the seafood.",
    //         popContent2: "",
    //     },
    //     {
    //         popTitle: "Maki",
    //         popImg: "",
    //         popContent1: "Maki means rolled in Japanese,which implies Maki sushi rolls are made by wrapping a combination of fresh ingredients in a layer of nori (seaweed) and vinegared rice. The possibilities for maki rolls are endless, with a wide array of fillings to suit every taste. From classic combinations like tuna and avocado to innovative fusions of ingredients (including vegetarian and vegan fillings), each maki roll offers a unique culinary experience. ",
    //         popContent2: "",
    //     },
    // ],
    Kebabs: [{
            pageTitle: "Order Kebabs Online",
            popTitle: 'Love kebabs? Find takeaways near you delivering succulent kebabs',
            popImg: require("@/assets/cuisine/Kebabs/Kebabs1.png"),
            popContent1: "Get delicious kebabs delivered to you in no time",
            popContent2:"Chicken Shish Kebab, Lamb Doner Kebab, Kofte Kebab, Mixed Kebab, Special Kebab – what would you like to savour today? Originating in the Middle East, kebabs consist of skewered pieces of meat chicken, lamb, beef, or even fish, typically marinated to enhance their taste and tenderness and traditionally grilled over an open flame, allowing the meat to develop a delicious smoky char. As a versatile dish, kebabs can be served wrapped in warm flatbread, accompanied by a side of rice or salad, or enjoyed on their own as delectable bite-sized treats. The blend of succulent meat, aromatic spices, and vibrant vegetables makes kebabs a beloved takeaway dish in the UK."
        },
        {
            popTitle: "Doner Kebab",
            popImg: require("@/assets/cuisine/Kebabs/Kebabs1.png"),
            popContent1: "A popular Turkish dish made from layers of seasoned meat, usually lamb or chicken, stacked on a vertical spit and slowly roasted. The meat is thinly sliced and served in a variety of ways, such as wrapped in a flatbread with a selection of sauces and toppings such as crisp lettuce, juicy tomatoes, tangy pickles, and crunchy cucumbers. Spicy or tangy, we’ll deliver it just the way you like. Need variety? Why not order shish kebabs, Kofta or seekh kebabs?",
            popContent2: "",
        },
        {
            popTitle: "Falafel wraps",
            popImg: require("@/assets/cuisine/Kebabs/Kebabs2.png"),
            popContent1: "Don’t eat meat? No problem! We deliver a range of vegetarian and vegan-friendly delicious kebabs just for you. Vegan Falafel wraps are no less than a treasure trove of great taste for non-meat eaters. The falafel patties are nestled in a warm, soft flatbread, such as pita or lavash. The wrap is then generously filled with crisp lettuce, juicy tomatoes, tangy pickles, and crunchy cucumbers. Add creamy and tangy tahini sauce, made from sesame paste, lemon juice, and garlic, for that extra zing!!",
            popContent2: "",
        },
    ],
    // Turkish: [{
    //         pageTitle: "Order Turkish food online",
    //         popTitle: '',//这里是不是少了内容
    //         popImg: require("@/assets/cuisine/Chinese/img1.png"),
    //         popContent1: "",//这里是不是少了标题
    //         popContent2:"Chicken Shish Kebab, Lamb Doner Kebab, Kofte Kebab, Mixed Kebab, Special Kebab – what would you like to savour today? Originating in the Middle East, kebabs consist of skewered pieces of meat chicken, lamb, beef, or even fish, typically marinated to enhance their taste and tenderness and traditionally grilled over an open flame, allowing the meat to develop a delicious smoky char. As a versatile dish, kebabs can be served wrapped in warm flatbread, accompanied by a side of rice or salad, or enjoyed on their own as delectable bite-sized treats. The blend of succulent meat, aromatic spices, and vibrant vegetables makes kebabs a beloved takeaway dish in the UK."
    //     },
    //     {
    //         popTitle: "Turkish Pide",
    //         popImg: "",
    //         popContent1: "Prepared in an unusual boat-shaped dough, Pide is often prepared with succulent minced meat, generously sprinkled with fragrant spices such as cumin and paprika, mingling with the sweetness of caramelised onions.  For those seeking a vegetarian delight, pide can be baked with an assortment of roasted vegetables, creamy feta cheese and kalamata olives. This Turkish masterpiece is a must try. ",
    //         popContent2: "",
    //     },
    //     {
    //         popTitle: "Baklawa",
    //         popImg: "",
    //         popContent1: "A traditional dessert of the Middle East, this Turkish delight is an irresistible treat. Once baked to golden perfection, the pastry is dipped in a fragrant syrup made from a harmonious combination of honey, rose water, and aromatic spices such as cinnamon or cardamom to give it a heavenly taste. Another popular Turkish delicacy is Lokum which will fill your mouth and heart with sweetness.",
    //         popContent2: "",
    //     },
    // ],
    Korean: [{
            pageTitle: "Order Korean Takeaway Online",
            popTitle: 'Find Korean Restaurants and Takeaways open near you on All Eat',
            popImg: require("@/assets/cuisine/Korean/Korean1.png"),
            popContent1: "Get Korean food delivered to you in no time",
            popContent2:"Incorporating bold and flavorful ingredients like soy sauce, sesame oil, garlic, and chilli paste, and with an emphasis on fresh ingredients, Korean food has become popular as a takeaway cuisine in the UK. Whether it's Kimchi or Bulgogi, Tteokbokki or Korean hotpot, each dish offers its own iconic and flavorful taste. And let's not forget Bibimbap, a colourful bowl of rice topped with assorted vegetables, meat, and a fried egg, which is a beloved Korean dish renowned for its harmonious blend of flavours. Give it a try, and you'll fall in love with its taste.",
        },
        {
            popTitle: "Bibimbap",
            popImg: require("@/assets/cuisine/Korean/Korean2.png"),
            popContent1: 'A true feast for the senses, Bibimbap has become a staple in Korean cuisine and gained popularity worldwide. The name "bibimbap" translates to "mixed rice," perfectly encapsulating the essence of this dish. It features a bowl of steamed rice, topped with an array of seasoned and sautéed vegetables, marinated meat (usually beef), and a fried or raw egg. It is often accompanied by a spicy gochujang sauce, a fermented red chilli paste that adds a kick of heat and depth to the dish. A must try in our takeaway list!!',
            popContent2: "",
        },
        {
            popTitle: "Tteokbokki",
            popImg: require("@/assets/cuisine/Korean/Korean3.png"),
            popContent1: "Tteokbokki consists of chewy rice cakes called tteok, which are cooked in a rich and spicy sauce made primarily from gochujang (fermented chilli paste). The dish offers a delightful combination of flavours, with a balance of spiciness, sweetness, and umami. Common additions include fish cakes, boiled eggs, cabbage, scallions, and sometimes ramen noodles. The spicy sauce of tteokbokki is what gives the dish its signature kick. Truly a Korean delicacy!!",
            popContent2: "",
        },
    ],
    Pizza: [{
            pageTitle: "Order Pizza takeaway delivery near you",
            popTitle: 'Got pizza cravings? Find hundreds of pizza restaurants and pizzerias open near you on All Eat',
            popImg: require("@/assets/cuisine/Pizza/Pizza1.png"),
            popContent1: "Get hot and fresh pizza delivered to you in no time",
            popContent2:"An iconic dish loved by millions around the world!! When It Comes to Pizza, We Deliver Beyond Expectations. Whether you’re a fan of Napoleon delight or Chicago-style deep dish, pizza’s versatility is unmatched. A piping hot pizza is a go-to choice for quick meals, casual gatherings, and late-night cravings.",
            popContent3:"Thick crust or thin, margarita or meat lover, cheese or vegan, pepperoni or veggies, we have the perfect pizza for you whether you like it woodfired, baked or grilled. Just open your All Eat app and let us do the hard work for you. "
        },
        {
            popTitle: "Neapolitan Pizza",
            popImg: require("@/assets/cuisine/Pizza/Pizza2.png"),
            popContent1: "Why not revisit the place where pizza was first invented? The crust, airy and crisp, yields effortlessly to your eager bite. The flavours melt together in perfect harmony, as the creamy mozzarella and tangy sauce dance on your palate. The subtle smokiness from the wood-fired oven imparts a hint of nostalgia, transporting you to the piazzas of Naples with every savoured morsel.",
            popContent2: "",
        },
        {
            popTitle: "Pepperoni Pizza",
            popImg: require("@/assets/cuisine/Pizza/Pizza3.png"),
            popContent1: "The most ordered pizza topping in the world, pepperoni, sways you away with its cheesy yet bold flavour. The combination of pizza with meat is the best you can think of. The tanginess of the tomato sauce and the creaminess of the cheese harmonise, while the pepperoni delivers a bold, meaty punch. Each bite offers a perfect balance of cheese, meat and the perfectly baked base.",
            popContent2: "",
        },
    ],
    Thai: [{
            pageTitle: "Order Takeaway From Thai Restaurants Near You",
            popTitle: 'Fancy Thai food? Find Thai Restaurants & Takeaways open near you on All Eat.',
            popImg: require("@/assets/cuisine/Thai/Thai1.png"),
            popContent1: "Get delicious Thai food delivered to you in no time",
            popContent2:"Care to indulge in exotic flavours from the streets of Thailand? We bet you've only heard of Thai Green curry and Pad Thai, but there's so much more. From the tantalising Tom Yum soup and Tom Kha Gai to the fiery Gaeng Phet curry and mouth watering street food, Thai cuisine offers a palate full of culinary adventures.",
            popContent3:"Whether you're a fan of vibrant spices, tangy citrus, creamy coconut, or fiery chilies, allow the All Eat app to deliver the best of Thai cuisine right to your doorstep.",
        },
        {
            popTitle: "Thai Green Curry",
            popImg: require("@/assets/cuisine/Thai/Thai2.png"),
            popContent1: "Prepared with aromatic herbs like basil and coriander and creamy coconut milk, Thai curry is a verdant potion that exudes an irresistible aroma. Tender pieces of chicken, shrimp or beef soak up the flavours, forcing you to say wow on every bite you take. Enjoy it with fish cakes, Thai fried rice or just by itself, it’s a taste you wouldn't wanna miss.",
            popContent2: "",
        },
        {
            popTitle: "Pad Thai",
            popImg: require("@/assets/cuisine/Thai/Thai3.png"),
            popContent1: "A stir-fried noodle masterpiece combined with soft rice noodles with succulent shrimp or tender chicken. Its complex flavours are woven together with tamarind, fish sauce, roasted peanuts, and a squeeze of lime, creating a symphony of sweet, savoury, and nutty notes that leave you craving for more. You can also relish Massaman curry which is an Indian-Thai fusion dish prepared with tender pieces of meat and coconut milk as the base. Spicy or not, every Thai dish speaks a culinary story of its own.",
            popContent2: "",
        },
    ],
    // Vegetarian: [{
    //         pageTitle: "Order Vegetarian Food Online",
    //         popTitle: 'Find Takeaways Near You delivering Vegetarian Food',
    //         popImg: require("@/assets/cuisine/Chinese/img1.png"),
    //         popContent1: "Get Vegetarian Dishes delivered to you in no time",
    //         popContent2:"Vegetarians are never out of choice when it comes to delicious food. And they never will be!! There’s no dish on earth which can’t be turned vegetarian. But wait! There is already a stunning array of veg food to eat which is even loved by hardcore meat-eaters. Vegetarian burgers, Margarita Pizza, Veggie Pizza, Falafel & Halloumi Wraps, Tofu Fried Rice, Buddha Bowl, Veggie Burrito, Paneer Burrito, Szechuan Tofu, Veggie Ramen, Veggie Chow Mein, Veggie Dumplings, Saag Paneer, Veggie Hot Pockets, Dosa, Idly, Biryani, the list goes on and on and on.",
    //         popContent3:"Each cuisine has their own version of gourmet vegetarian food showcasing distinct flavours and aromas, all at your service in your All Eat app. "},
    //     {
    //         popTitle: "Masala Dosa",
    //         popImg: "",
    //         popContent1: "Crispy, golden crepe expertly crafted from fermented rice and lentil batter will give you an explosion of flavours. A staple in South Indian diet, dosa is a beloved dish for foodies around the world. Masala Dosa is generously filled with a spiced potato mixture, known as masala, which is infused with fragrant spices like cumin, turmeric, and mustard seeds. Then there is Idly, Medu Vada, Rasam  and Idly. Hearty and healthy food, we must say. You know where to order from - your All Eat app!",
    //         popContent2: "",
    //     },
    //     {
    //         popTitle: "Saag Paneer",
    //         popImg: "",
    //         popContent1: "Cottage Cheese pieces (Paneer cubes) dipped and simmered in a rich, velvety spinach gravy infused with fenugreek, cumin, and garlic – a dish which is both comforting and incredibly satisfying. Enjoy with Naan Bread or Pilau Rice. Bombay Aloo, Butter Paneer, Paneer Shashlik, Veg Korma, Bhindi Bhaji, Chana Masala, Veg Biryani. Don’t let us get started on how incredible they taste. Just order on All Eat app and live the great taste yourself.",
    //         popContent2: "",
    //     },
    // ],
    // Vegan: [{
    //         pageTitle: "Order Vegan Food Online",
    //         popTitle: 'Find Vegan-friendly takeaways near you',
    //         popImg: "",
    //         popContent1: "Get Vegan food delivered to you in no time",
    //         popContent2:"Forget Veganuary, every month is vegan nowadays. Veganism is much more of a celebration than an imposition. It’s not a restricted diet anymore. Not at All Eat app at least. Vegan Pizza, Vegan Stir fries, Vegan Sushi, Vegan Pad thai, Vegan Burgers, Vegan Buddha Bowl, Vegan Wraps, Vegan Curries, Vegan Hot dogs, Vegan Sausage, vegan Pasta. It’s hard to mention a dish that’s not plant-based or not on our platform. Almost all restaurants have their own vegan-friendly menu. You just have to open the All Eat app you know. ",
    //         popContent3:"But Vegan food is not just for vegans. Whether curious or adventurous, just open your All Eat app and order healthy vegan meals across different cuisines and enjoy."
    //     },
    //     {
    //         popTitle: "",
    //         popImg: "",
    //         popContent1: "",
    //          popContent2: "",
    //     },
    //     {
    //         popTitle: "",
    //         popImg: "",
    //         popContent1: "",
    //         popContent2: "",
    //     },
    // ],
    // Wraps: [{
    //         pageTitle: "Order Wraps online",
    //         popTitle: 'Find takeaways near you delivering delicious wraps',
    //         popImg: "",
    //         popContent1: "Get wraps delivered to you in no time",
    //         popContent2:"Don’t have time to eat or fancy all your favourite meat & toppings wrapped up together? We have the perfect dish (dishes) for you- Kofte Wrap, Lamb Doner Wrap, Falafel Wrap, Chicken shish wrap, halloumi wrap and the list goes on! A convenient solution for a delicious, handheld meal with crunchy, fresh vegetables, tender proteins, and creamy dressings or spreads, all wrapped together in the flatbread of your choice. Order your usual or play with the flavours, the choice is yours.",
    //         popContent3:"",
    //     },
    //     {
    //         popTitle: "Vegetarian wraps",
    //         popImg: require("@/assets/cuisine/Chinese/img1.png"),
    //         popContent1: "Our top picks for vegetarian and vegan wraps are Falafel wrap, Halloumi wrap, Veggie patty wrap and the ultimate vegetarian wrap. All these have one thing in common–they are unbelievably delicious. Don’t believe us? Try it yourself and you won’t be disappointed.",
    //         popContent2: "",
    //     },
    //     {
    //         popTitle: "Kebab wraps",
    //         popImg: require("@/assets/cuisine/Chinese/img1.png"),
    //         popContent1: "The options are too many, which one would you select? We know! Order one for each day and taste them all. Why not when we offer such low prices for takeaways so you can afford to eat out everyday? Open your All Eat app and check the prices for yourself. We will be waiting for you to order our takeaway wraps.",
    //         popContent2: "",
    //     },
    // ],
};
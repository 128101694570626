<template>
  <div>
    <div class="box3">
      <div class="left">
        <div class="title">Our mission</div>
        <div class="content">Our mission is to disrupt the food industry</div>
        <div class="footer">
          All Eat is passionate about food and technology. We embrace
          cutting-edge technology to ensure that our ordering process is
          seamless and hassle-free. Restaurants get critical customer insights
          from All Eat. Our people work closely with restaurants to ensure that
          the food is delivered to you on time and in perfect condition. Being a
          sustainable business which brings communities together is what we care
          all about.
        </div>
      </div>
      <div class="right">
        <div class="food">
          <img
            style="height: 100%; width: 100%;border-radius:30px;"
            src="@/assets/about/food2.jpeg"
            alt="people"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box3 {
  display: flex;
  padding: 60px 120px;
  justify-content: center;
  .left {
    .title {
      font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      /* or 111% */

      display: flex;
      align-items: center;
      letter-spacing: 1.08px;
      text-transform: uppercase;

      color: #AA0BC0;
    }
    .content {
      margin-top: 10px;
      width: 500px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 44px;
      line-height: 54px;
      /* or 123% */

      display: flex;
      align-items: center;

      color: #121212;
    }
    .footer {
      margin-top: 20px;
      width: 460px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      /* or 167% */

      display: flex;
      align-items: center;

      color: #656565;
    }
  }
  .right {
    margin-left: 60px;
    .food {
      width: 600px;
      height: 720px;
    }
  }
}
@media screen and (min-width:901px)and (max-width:1350px) {
      .box3{
        padding: 3% 5%;
        display:flex;
        justify-content:center;
        align-items:center;
        .left{
          margin:auto;
          width: 100%;
          height: 100%;
        }
        .right{
          width:100%;
          height: 100%;
          .food{
            width:100%;
            height:100%;
          }
        }
        }
      
      
    }
@media screen and (max-width:900px) {
      .box3{
        width:100%;
        height:100%;
        padding: 5% 7%;
        flex-direction: column;
        display:flex;
        justify-content:center;
        align-items:center;
        .left{
          flex-direction: column;
          margin-left: 0%;
          width: 100%;
          height: 100%;
          align-items:center;
          margin-top: 5%;
          .content{
            width: 100%;
            font-size:2.1rem;
            line-height: 40px;
            margin-top: 20px;
          }
          .footer
          {
            width: 100%;
          }
        }
        .right{
          .food{
            width:70%;
            height:70%;
          }
        }
      }
      
      
    }
</style>

<template>
    <div class="quick-page" >

        <div class="book-title" >
            <h2>Welcome To Order</h2>
        </div>
        <div class="book-form" :style="{'width': isMobile?'96%':'440px'}" >
            <div class="form-line " >
                <div class="input-box">
                    <span class="name">First Name</span>
                    <div class="input-shadow">
                        <input class="input" type="text" v-model="bookForm.firstName" placeholder="Name">
                    </div>
                </div>
                <div class="input-box" style="width: 160px;flex:none;margin-left:20px;">
                    <span class="name">Last Name</span>
                    <div class="input-shadow">
                        <input class="input" type="text" v-model="bookForm.lastName" placeholder="Name">
                    </div>
                </div>
            </div>
            <div class="form-line " >
                <div class="input-box">
                    <span class="name">Email</span>
                    <div class="input-shadow">
                        <input class="input" type="text" v-model="bookForm.emailAddress" placeholder="Email">
                    </div> 
                </div>
            </div>
            <div class="form-line " >
                <div class="input-box">
                    <span class="name">Phone number</span>
                    <div class="phone-box" >
                        <PhoneBtn v-model="bookForm.code"></PhoneBtn>
                        <input type="text" v-model="bookForm.mobile" placeholder="Phone number">
                    </div>
                </div>
            </div>

            <!-- <div style="display: flex;justify-content: center;margin-top: 24px;" >
                <CloudflareVerify style="padding-top: 5px;" ref="cloudflare" @verifyDone="verifyDone"></CloudflareVerify>
            </div> -->

            <div class="btn-box" >
                <div class="btn btn-cancel" @click="pageLoading=true;quickLogin();">Skip To Menu</div>
                <div class="btn" @click="quickLogin">
                    <i class="el-icon-loading" v-if="bookLoading"></i>
                    <span v-else>Order Now</span>
                </div>
            </div>
            
        </div>


        <PageLoading v-model="pageLoading"></PageLoading>
    </div>
</template>

<script>
    import { postGateway } from '@/request';
    import PageLoading from '@/components/PageLoading.vue';
    // import CloudflareVerify from '@/components/CloudFlareVerify.vue';
    import PhoneBtn from "@/components/PhoneBtn.vue";
    import bus from '@/utils/bus.js';
    export default {
        name: "QuickPage",
        components: {
            PageLoading,
            // CloudflareVerify,
            PhoneBtn
        },
        data() {
            return {
                pageLoading: false,
                slug: '',
                captchaVerification: '',
                loginFailed: false,
                bookLoading: false,
                bookForm: {
                    code: (this.$isUk?'+44':'+353'),
                    mobile: '',
                    emailAddress: '',
                    firstName: '',
                    lastName: '',
                    contactPhone: '', // code + mobile
                }
            }
        },
        computed: {
            isMobile() {
                return window.innerWidth < 950
            },
        },
        created() {

        },
        mounted() {
            let slug = this.$route.params.pathMatch;
            this.slug = slug;

            if(localStorage.getItem('token')) {
                this.$router.replace(`/${this.slug}`)
            }
        },
        methods: {
            verifyDone() {
                // if(!localStorage.getItem('token')) {
                //     this.quickLogin();
                // }
            },
            resetCode() {
                // this.$refs.cloudflare.reSetTurnSite();
            },
            quickLogin() {
                
                this.bookLoading = true;
                postGateway({
                    url: "/customer/simple/clientLogin",
                    method: "POST",
                    data: {
                        deviceId: localStorage.getItem('DeviceId'),
                        captchaVerification: '',
                        slug: this.slug,
                        emailAddress: this.bookForm.emailAddress,
                        firstName: this.bookForm.firstName,
                        lastName: this.bookForm.lastName,
                        contactPhone: this.bookForm.mobile?(this.bookForm.code + this.bookForm.mobile):'',
                        tag: this.$encryptByPublicKey(new Date().valueOf())
                    }
                }).then(res=>{
                    console.log(res,'登录完成的参数')
                    if(res.data) {
                        let { token, refreshToken, clientId } = res.data;
                        localStorage.setItem('token',token);
                        localStorage.setItem('refreshToken',refreshToken);
                        localStorage.setItem('clientId', clientId);
                        localStorage.setItem("aeClient", clientId);
                        bus.$emit('login'); // 通知header登陆了

                        this.pageLoading = false;
                        this.bookLoading = false;
                        this.$router.replace(`/${this.slug}`);

                    }
                })
                .catch(err=>{
                    this.bookLoading = false;
                    this.pageLoading = false;
                    this.loginFailed = true;
                    this.$message.warning({
                        message: err.msg || "Error",
                        type: "warning",
                    });
                    // this.resetCode();
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .quick-page {
        min-height: 95vh;
        padding-top: 48px;
        .book-title {
            color: #000;
            font-family: 'Source Sans Pro', sans-serif;
            text-align: center;
            h2 {
                font-weight: 700;
                font-size: 35px;
                margin: 0 0 5px;
            }
            .desc-1 {
                font-size: 18px;
            }
            .desc-2 {
                font-size: 22px;
            }
        }
        .book-form {
            min-height: 500px;
            border-radius: 12px;
            padding: 20px;
            margin: 40px auto 0;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            .cloud-box {
                padding-left: 0px;
            }
            .form-line {
                display: flex;
                margin-top: 14px;
                .input-box {
                    flex: 1;
                    position: relative;
                    .cover-cascader {
                        opacity: 0;
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                    }
                    .name {
                        font-size: 16px;
                        color: #121212;
                        font-weight: 700;
                    }
                    .input-shadow {
                        box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
                        margin-top: 12px;
                    }
                    .input {
                        padding-left: 20px;
                        height: 100%;
                        width: 100%;
                        height: 52px;
                        border-radius: 12px;
                        font-size: 16px;
                    }
                    .input-textarea {
                        height: auto;
                    }
                    :deep(.el-textarea__inner) {
                        border: none;
                        font-size: 16px;
                        color: #000;
                    }
                    .phone-box {
                        display: flex;
                        align-items: center;
                        box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
                        height: 52px;
                        margin-top: 12px;
                        border-radius: 12px;

                        input {
                            flex: 1;
                            padding-left: 10px;
                            color: #000;
                            font-size: 16px;
                        }
                    }
                }
            }
            .btn-box {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                .btn {
                    height: 48px;
                    width: 140px;
                    border-radius: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #09CA6A;
                    color: #fff;
                    font-weight: 600;
                    cursor: pointer;
                    transition: all ease-in .2s;
                    &:hover {
                        background: #09a859;
                    }
                }
                .btn-cancel {
                    border: 1px solid #AA0BC0;
                    background: #fff;
                    color: #AA0BC0;
                    &:hover {
                        background: #AA0BC0;
                        color: #fff;
                    }
                }
            }
        }
    }
</style>